import { Typography } from '@mui/material';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { SpaceLayoutView } from './ListSpaceLayouts';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';
import FloorPlanPopup from './FloorPlanPopup';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';

class CreateLinkedSpaceLayoutPanel extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            linkedSpaceName: '',
            defaultLayoutName: '',
            defaultLayoutCapacity: 1,
            defaultLayoutSetup: 0,
            defaultLayoutBreakdown: 0,
            showFloorPlan: false,
            savingChanges: false
        };
    };

    private cancel(): void
    {
        this.props.clearSelectedSpaces();
    }

    private async save(): Promise<void>
    {
        this.setState({ savingChanges: true });
        try
        {
            let i = 0;
            while (i < this.props.selectedSpaces.length)
            {
                const space = this.props.selectedSpaces[i]
                const spaceLayout = space.spaceLayouts == "" ? [] : JSON.parse(space.spaceLayouts).Layouts ?? [];

                const updatedSpaceLayout = {
                    Layouts: spaceLayout.concat([
                        {
                            Name: this.state.linkedSpaceName,
                            Space_Id: this.props.selectedSpaces.map(x => x.id).join(';'),
                            SeatingArrangements: [
                                {
                                    Style: this.state.defaultLayoutName,
                                    Capacity: this.state.defaultLayoutCapacity,
                                    Setup: this.state.defaultLayoutSetup,
                                    Breakdown: this.state.defaultLayoutBreakdown
                                }
                            ]
                        }])
                }

                await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.bySpaceId.patch({
                    nodeId: this.props.floorId,
                    spaceId: space.id,
                    body: { Space_Layout: JSON.stringify(updatedSpaceLayout), ConcurrencyStamp: space.concurrencyStamp }
                })
                i = i + 1;
            }

            this.setState({ savingChanges: false });
            this.props.clearSelectedSpaces();
            this.props.onChangesSaved();
        } catch (error)
        {
            this.setState({ savingChanges: false });
        }
    }

    public render(): JSX.Element
    {
        return (
            <div className='m-3'>
                {
                    this.state.savingChanges &&
                    <LoadingOverlay />
                }
                <Typography variant='h5' className='mb-1'>{this.labels.funcCreateNewLinkedSpace_S}</Typography>
                <IbssButton onClick={() => this.setState({ showFloorPlan: true })}>{this.labels.funcSpaceLayoutViewMap_S}</IbssButton>
                {
                    this.props.linkedSpaceAlreadyExists ?
                        <div className='mt-1 mb-1'>{this.labels.funcLinkedSpaceAlreadyExistsPt1_Message} {this.props.selectedSpaces.map(x => x.id).join(';')} {this.labels.funcLinkedSpaceAlreadyExistsPt2_Message}</div>
                        :
                        <>
                            <IbssTextField
                                error={this.state.linkedSpaceName == ''}
                                className='mt-1'
                                variant='standard'
                                fullWidth
                                label={this.labels.HubLabelName}
                                value={this.state.linkedSpaceName}
                                onChange={e => this.setState({ linkedSpaceName: e.target.value })}
                            />
                            <div>
                                <div className='mt-2 mb-1'>{this.labels.funcDefaultLayout_S}</div>
                                <IbssTextField
                                    error={this.state.defaultLayoutName == ''}
                                    variant='standard'
                                    fullWidth
                                    label={this.labels.funcSpaceLayoutName_S}
                                    value={this.state.defaultLayoutName}
                                    onChange={e => this.setState({ defaultLayoutName: e.target.value })}
                                />
                                <IbssTextField
                                    error={this.state.defaultLayoutCapacity < 1}
                                    helperText={this.state.defaultLayoutCapacity < 1 ? this.labels.funcSpaceLayoutMinimumCapacity_Message : ''}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    className='mt-2'
                                    variant='standard'
                                    fullWidth
                                    label={this.labels.HubLabelCapacity}
                                    value={this.state.defaultLayoutCapacity}
                                    type='number'
                                    onChange={e => this.setState({ defaultLayoutCapacity: parseInt(e.target.value) })}
                                />
                                <IbssTextField
                                    className='mt-2'
                                    variant='standard'
                                    fullWidth
                                    label={this.labels.funcSpaceLayoutSetUpTime_S}
                                    value={this.state.defaultLayoutSetup}
                                    type='number'
                                    onChange={e => this.setState({ defaultLayoutSetup: parseInt(e.target.value) })}
                                />
                                <IbssTextField
                                    className='mt-2'
                                    variant='standard'
                                    fullWidth
                                    label={this.labels.funcSpaceLayoutBreakdownTime_S}
                                    value={this.state.defaultLayoutBreakdown}
                                    type='number'
                                    onChange={e => this.setState({ defaultLayoutBreakdown: parseInt(e.target.value) })}
                                />
                            </div>

                            <div className='d-flex justify-content-between mt-2'>
                                <IbssButton sx={{ width: '48%' }} color='secondary' variant='contained' onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</IbssButton>
                                <IbssButton sx={{ width: '48%' }} color='primary' variant='contained' onClick={() => this.save()}>{this.labels.HubButtonSave}</IbssButton>
                            </div>
                        </>
                }
                <FloorPlanPopup show={this.state.showFloorPlan} floorId={this.props.floorId} close={() => this.setState({ showFloorPlan: false })} spacesToHighlight={this.props.selectedSpaces.map(x => x.id)} />
            </div>
        )
    }
}

export default CreateLinkedSpaceLayoutPanel;

export interface IProps
{
    floorId: number;
    selectedSpaces: SpaceLayoutView[];
    linkedSpaceAlreadyExists: boolean;
    clearSelectedSpaces: () => void;
    onChangesSaved: () => void;
}

export interface IState
{
    linkedSpaceName: string,
    defaultLayoutName: string,
    defaultLayoutCapacity: number,
    defaultLayoutSetup: number,
    defaultLayoutBreakdown: number,
    showFloorPlan: boolean;
    savingChanges: boolean;
}
