import { Component, SyntheticEvent } from "react";
import "../../../styles/css/searchspace.scss";
import "../../../App.css";
import { appContext } from "../../../AppContext";
import { Icons } from "../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../Icons/SvgIcon/IbssSvgIcon";
import { Avatar, AvatarGroup, Box, TextField } from "@mui/material";
import IbssAutocomplete from "../../Inputs/AutoComplete/IbssAutocomplete";
import IbssDialog from "../../Dialogs/BaseDialog/IbssDialog";
import IbssTextField from "../../Inputs/TextField/IbssTextField";
import { ICreateV2Booking_BookingParty } from "../../../Providers.Api/Bookings/CreateV2BookingEndpoint";
import IbssButton from "../../Buttons/Button/IbssButton";
import { Button } from '@mui/material';
import { isEqual } from "lodash";
import UserPicker, * as UserPickerModule from "../../Inputs/UserPicker/UserPicker";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import IbssAvatar from "../../Miscellaneous/Avatar/IbssAvatar";
import { Height } from "@mui/icons-material";

export default class BookingPartiesPicker extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    
    constructor(props: IProps)
    {
        super(props);

        this.state = {
            allAttendees: [],
            showModal: false,
            isVisitor: false,
            nonVisitorAttendeesData: {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: false,
                type: 1
            },
            visitorAttendeesData: {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: true,
                type: 2
            },
            isDisabled: false,
            errors: '',
            alreadyPresentError: '',
            selectedAttendee: '',
            booking_Parties_array: [],
            booking_Parties_Visitors_array: [],
            clearTriggered: false,
            visitorFirstName: '',
            visitorLastName: ''
        }
    }
    
    public componentDidMount(): void 
    {
        const { bookingParties, bookingPartyVisitors } = this.props;
    
        this.setState({
            allAttendees: [
                ...(bookingParties ?? []),
                ...(bookingPartyVisitors ?? []),
            ],
            booking_Parties_array: bookingParties ?? [],
            booking_Parties_Visitors_array: bookingPartyVisitors ?? [],
        });
    }    

    public componentDidUpdate(prevProps: IProps): void
    {
        if (this.props.bookingParties && this.props.bookingPartyVisitors && (!isEqual(prevProps?.bookingParties, this.props?.bookingParties) || !isEqual(prevProps?.bookingPartyVisitors, this.props?.bookingPartyVisitors)))
            {
            this.setState({
                allAttendees: [...this.props.bookingParties, ...this.props.bookingPartyVisitors],
                booking_Parties_array: this.props.bookingParties,
                booking_Parties_Visitors_array: this.props.bookingPartyVisitors,
            });
        }
    }

    private handleShowHideAttendeeModal(): void
    {
        this.setState({
            showModal: !this.state.showModal,
            isVisitor: false,
            nonVisitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: false,
                type: 1
            },
            visitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: true,
                type: 2
            },
            visitorFirstName: "",
            visitorLastName: ""
        });
    }

    private visitorChanged(): void
    {
        this.setState({
            isVisitor: !this.state.isVisitor,
            nonVisitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: false,
                type: 1
            },
            visitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: true,
                type: 2
            },
            isDisabled: true,
            errors: '',
            alreadyPresentError: '',
            visitorFirstName: '',
            visitorLastName: ''
        })
    }

    private async nonVisitorAttendeeTextChanged(text: string): Promise<void>
    {
        this.setState({ selectedAttendee: text });
        if (text.length <= 3)
        {
            this.setState({ alreadyPresentError: '' });
        }
    }

    private async nonVisitorAttendeeChanged(attendee: UserPickerModule.IUser | null): Promise<void>
    {
        if (attendee)
        {
            const hubLabelalreadyPresent = this.state.booking_Parties_array.find((item: IAttendee) => item.email === attendee.email);
            if (hubLabelalreadyPresent != null)
            {
                this.setState({
                    alreadyPresentError: this.labels.HubLabelalreadyPresent,
                    isDisabled: true,
                    selectedAttendee: '',
                });
            }
            else
            {
                this.setState({
                    nonVisitorAttendeesData: {
                        email: attendee.email,
                        name: attendee.firstName + ' ' + attendee.lastName,
                        organisation: attendee.company,
                        resourceId: null,
                        visitor: false,
                        type: 1

                    },
                    isDisabled: false,
                })
            }
        }
    }

    private visitorAttendeesChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {


        const { type, value } = e.target;
        let errors: string = '';
        if (type === 'email')
        {
            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(value))
            {
                errors = this.labels.HubLabelInvalidEmailAddress;
            }

            if (errors === "")
            {
                this.setState({ isDisabled: false });
            }
            else
            {
                this.setState({ isDisabled: true });
            }
            this.setState({ errors: errors });
        }

        if (e.target.name == 'firstname')
        {
            this.setState({
                visitorFirstName: e.target.value,
            })
        }
        else if (e.target.name == 'lastname')
        {
            this.setState({
                visitorLastName: e.target.value,
            })
        }
        else
        {
            this.setState({
                visitorAttendeesData: {
                    ...this.state.visitorAttendeesData,
                    [e.target.name]: e.target.value
                },
            })
        }
    }

    private async addAttendeeClicked(): Promise <void>
    {
        if (!this.state.isVisitor)
        {
            const newBooking_Parties_array: IAttendee[] = [...this.state.booking_Parties_array];
            newBooking_Parties_array.push(this.state.nonVisitorAttendeesData);
            this.setState({
                booking_Parties_array: newBooking_Parties_array,
                selectedAttendee: '',
                nonVisitorAttendeesData:
                {
                    email: "",
                    name: "",
                    organisation: "",
                    resourceId: null,
                    visitor: false,
                    type: 1
                },
                isDisabled: true,
            });
        }
        else
        {
             await this.setStateAsync({
                visitorAttendeesData: {
                    ...this.state.visitorAttendeesData,
                    name: `${this.state.visitorFirstName} ${this.state.visitorLastName}`,
                },
            });

            const newBooking_Parties_Visitors_array: IAttendee[] = [...this.state.booking_Parties_Visitors_array];
            newBooking_Parties_Visitors_array.push(this.state.visitorAttendeesData);
            this.setState({
                booking_Parties_Visitors_array: newBooking_Parties_Visitors_array,
                visitorAttendeesData:
                {
                    email: "",
                    name: "",
                    organisation: "",
                    resourceId: null,
                    visitor: true,
                    type: 2
                },
                visitorFirstName: "",
                visitorLastName: "",
                isDisabled: true,
            });
        }
    }

    private removeAttendeesClicked = (item: IAttendee) =>
    {
        const newBooking_Parties_array: IAttendee[] = this.state.booking_Parties_array.filter((element: IAttendee) => element.email !== item.email);
        const newBooking_Parties_Visitors_array: IAttendee[] = this.state.booking_Parties_Visitors_array.filter((element: IAttendee) => element.email !== item.email);
        this.setState(
            {
                booking_Parties_array: newBooking_Parties_array,
                booking_Parties_Visitors_array: newBooking_Parties_Visitors_array,
                clearTriggered: true,
            })
    }

    private clearAllAttendeesClicked(): void
    {
        if (this.state.booking_Parties_array.length == 0 && this.state.booking_Parties_Visitors_array.length == 0)
        {
            return;
        }
        this.setState({
            booking_Parties_array: [],
            booking_Parties_Visitors_array: [],
            isDisabled: true,
            clearTriggered: true,
        })
    }

    private totalAddAttendeeClicked(): void
    {
        this.setState({ allAttendees: [...this.state.booking_Parties_array, ...this.state.booking_Parties_Visitors_array] });

        if (this.state.clearTriggered === true && this.state.booking_Parties_array.length === 0 && this.state.booking_Parties_Visitors_array.length === 0)
        {
            this.setState({
                allAttendees: [],
                clearTriggered: false,
            });
        }
        this.handleShowHideAttendeeModal();

        this.props.onSubmit(this.state.booking_Parties_array, this.state.booking_Parties_Visitors_array)
    }

    private getInitials(firstName: string, lastName: string): string
    {
        let firstNameInitial = firstName?.charAt(0).toUpperCase();
        let lastNameInitial = lastName?.charAt(0).toUpperCase();
        let firstLetters = firstNameInitial + lastNameInitial;
        return firstLetters;
    }

    public render(): JSX.Element
    {
        const isButtonDisabled = (this.state.booking_Parties_array.length === 0 && this.state.booking_Parties_Visitors_array.length === 0) && this.state.clearTriggered === false;

        return (
            <>
                <Button fullWidth disabled={this.props.disabled}sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} className="row attendes cp" onClick={() => this.handleShowHideAttendeeModal()}>
                    <div className="icon-text-inline pl-0">
                        {(this.state.booking_Parties_array.length === 0 && this.state.booking_Parties_Visitors_array.length === 0) && (
                            <>
                                <div className="d-flex space-between mr-2">
                                    <span className="space-icon-item">
                                        <div style={{ fontSize: '26px', height: '27px' }}>
                                            <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                {Icons.AddAttendeeIcon}
                                            </IbssSvgIcon>
                                        </div>
                                        <span className="space-text-item col-text" style={{ marginLeft: '-12px' }}>{this.state.allAttendees.length === 0 && this.labels.HubLabelAddAttendeeLabel}</span>
                                    </span>
                                </div>
                                <hr style={{marginBottom:'0.5rem'}} />
                            </>
                        )}
                        <span className="ml-1 d-flex">
                            <AvatarGroup className="BokingoptionsAvtar" total={this.state.allAttendees.length}>
                                {this.state.allAttendees.slice(0, 5).map((item: IAttendee, index: number) => (
                                        <Avatar sx={{backgroundColor:'var(--ui-secondary)',border: '1px solid var(--ui-text-alternate)',color:'var(--ui-light-text)',height:30,width:30,fontSize:14}}>{this.getInitials(item.name?.split(' ')[0], item.name?.split(' ')[1])}</Avatar>
                                ))}
                            </AvatarGroup>
                        </span>
                    </div>
                    <div className="d-flex">
                        <span className="space-text-item">{this.state.allAttendees.length !== 0 && `${this.state.allAttendees.length} ${this.labels.HubLabelAttendees}`}</span>
                        <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                            {Icons.RightArrowIcon}
                        </IbssSvgIcon>
                    </div>
                </Button>
                <hr  style={{marginTop:'0.5rem'}} />
                {
                    this.state.showModal &&
                    <IbssDialog
                        aria-modal="true"
                        aria-label="attendee selection modal"
                        open={this.state.showModal}
                        fullWidth
                        onClose={() => this.handleShowHideAttendeeModal()}
                        header=
                        {
                            <>
                                <label className="modal-heading">{this.labels.HubLabelAttendeeSelection}</label>
                            </>
                        }
                        dialogContent=
                        {
                            <div>

                                <div className="d-flex justify-content-between">
                                    <label className="lable-modal">{this.labels.HubButtonVisitorAccess}</label>
                                    <input name="isPrivate" type="checkbox" className="input-check-box-label border checkbox-sm" checked={this.state.isVisitor} onChange={() => this.visitorChanged()} />
                                </div>

                                <hr className="m-0 mx-2" />

                                {!this.state.isVisitor &&
                                    <div className="mt-2">
                                        <div className="form-group mt-3 mx-10">
                                            <label className="py-1">{this.labels.HubLabelnameEmailAddress}</label>
                                            <UserPicker
                                                searchText={this.state.selectedAttendee}
                                                onChange={async text => this.nonVisitorAttendeeTextChanged(text)}
                                                onUserChange={user => this.nonVisitorAttendeeChanged(user)}
                                            />
                                            <div className="text-danger">{this.state.alreadyPresentError}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.isVisitor &&
                                    <>
                                        <div className="form-group mt-3 mx-10">
                                            <label className="py-1">{this.labels.HubLabelEmailAddress + ' *'}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="email"
                                                    name="email"
                                                    value={this.state.visitorAttendeesData.email}
                                                    onChange={(e) => { this.visitorAttendeesChanged(e) }}
                                                    placeholder={this.labels.HubLabelemailPlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                            <div className="text-danger">
                                                {this.state.errors}
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelFirstName + ' *'}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="firstname"
                                                    value={this.state.visitorFirstName}
                                                    onChange={(e) => { this.visitorAttendeesChanged(e) }}
                                                    placeholder={this.labels.HubLabelFirstNamePlaceholderLabel}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelLastName + ' *'}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="lastname"
                                                    value={this.state.visitorLastName}
                                                    onChange={(e) => { this.visitorAttendeesChanged(e) }}
                                                    placeholder={this.labels.HubLabelLastNamePlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelCompany}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="organisation"
                                                    value={this.state.visitorAttendeesData.organisation}
                                                    onChange={(e) => { this.visitorAttendeesChanged(e) }}
                                                    placeholder={this.labels.HubLabelCompanyNamePlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="form-group mx-10">
                                    <IbssButton
                                        variant='contained'
                                        onClick={() => this.addAttendeeClicked()}
                                        disabled={this.state.isDisabled ||(this.state.isVisitor && (this.state.visitorFirstName.trim() === ""  || this.state.visitorLastName.trim() === ""))}
                                    >
                                        {this.labels.HubButtonAddattendeebutton}
                                    </IbssButton>
                                </div>
                            </div>
                        }
                        footer=
                        {
                            <div className="w-100">
                                {this.state.booking_Parties_array.length > 0 && (
                                    <>
                                        <div className="px-58 form-group ml-10">
                                            <label className="py-1 lable-modal-bold">{this.labels.HubLabelAttendees}</label>
                                            <div className="d-flex flex-wrap">
                                                {this.state.booking_Parties_array.map((item: IAttendee) => (
                                                    <>
                                                        <p className="attendee-name mr-3">{item.name} <i className="fa fa-times-circle-o" aria-hidden="true" onClick={() => this.removeAttendeesClicked(item)}></i></p>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                        <hr className="attendee-hr" />
                                    </>
                                )}
                                {this.state.booking_Parties_Visitors_array.length > 0 && (
                                    <>
                                        <div className="px-58 form-group ml-10 mt-1">
                                            <label className="py-1 lable-modal-bold">{this.labels.HubLabelVisitorAttendee}</label>
                                            <div className="d-flex flex-wrap">
                                                {this.state.booking_Parties_Visitors_array.map((item: IAttendee) => (
                                                    <p className="attendee-name mr-3 mb-0">{item.name} <i className="fa fa-times-circle-o" aria-hidden="true" onClick={() => this.removeAttendeesClicked(item)}></i></p>
                                                ))}
                                            </div>
                                        </div>
                                        <hr className="attendee-hr" />
                                    </>
                                )}
                                <div className="px-58 mt-1 pw-100 d-flex justify-content-end">
                                    <a href="#" className="clear-attendees" onClick={() => this.clearAllAttendeesClicked()} >{this.labels.HubButtonClearAttendees}</a>
                                    <IbssButton
                                        className='ml-2'
                                        variant='contained'
                                        disabled={isButtonDisabled}
                                        onClick={() => this.totalAddAttendeeClicked()}
                                    >
                                        {this.labels.HubButtonSave}
                                    </IbssButton>
                                </div>
                            </div>
                        }
                    />
                }
            </>
        );
    }
}

export interface IState
{
    showModal: boolean;
    allAttendees: IAttendee[];
    isVisitor: boolean;
    nonVisitorAttendeesData: IAttendee;
    visitorAttendeesData: IAttendee;
    isDisabled: boolean;
    errors: string;
    alreadyPresentError: string;
    selectedAttendee: string;
    booking_Parties_array: IAttendee[];
    booking_Parties_Visitors_array: IAttendee[];
    clearTriggered: boolean;
    visitorFirstName: string;
    visitorLastName: string;
}

interface ISubmitFunction 
{
    (parties: IAttendee[], visitors: IAttendee[]): void;
}

export interface IProps
{
    onSubmit: ISubmitFunction;
    bookingParties?: IAttendee[];
    bookingPartyVisitors?: IAttendee[];
    disabled?: boolean;
}

export class IAttendee
{
    email: string;
    name: string;
    organisation: string;
    resourceId: string | null;
    visitor: boolean;
    type: number;
    backgroundColor?: string;

    constructor(data: ICreateV2Booking_BookingParty)
    {
        this.email = data.Booking_Participant_Email;
        this.name = data.Booking_Participant_Name;
        this.organisation = data.Booking_Participant_Organisation;
        this.resourceId = data.Booking_Resource_Id;
        this.visitor = data.Booking_Visitor;
        this.type = data.Booking_Participant_Type;
    }
}
