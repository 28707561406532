import { Component } from "react"

export abstract class IbssComponent<TProps, TState> extends Component<TProps, TState>
{
    public setStateAsync<K extends keyof TState>(state: ((prevState: Readonly<TState>, props: Readonly<TProps>) => (Pick<TState, K> | TState | null)) | (Pick<TState, K> | TState | null)): Promise<void>
    {
        return new Promise(resolve =>
        {
            this.setState(state, () => resolve());
        });
    }
}
