import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { Component } from "react";
import { appContext } from "../../../../AppContext";
import { DateHelper } from "../../../../Common/DateHelper";
import Helper from "../../../../Common/Helper";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { ICateringMenu } from "../../../../Providers.Api/CateringMenus/GetManyEndpoint";
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { RouteComponentProps } from "react-router";
import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import IbssFilter from "../../../../Components/Forms/Filter/IbssFilter";
import { PagedResponse } from "../../../../Providers.Api/Models";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import IbssInputDropDown, { optionObject } from "../../../../Components/Inputs/SelectList/IbssInputDropDown";
import IbssChip from "../../../../Components/Navigation/Chip/IbssChip";
import IbssActionButton, { IActionButton } from "../../../../Components/Buttons/ActionButton/IbssActionButton";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { CateringMenuStatus, Filter } from "../../../../Providers.Api/CateringMenus/CateringMenuRepository";
import AddIcon from "../../../../Components/Icons/AddIcon";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import PreparingExportDialog from "../../../../Components/Dialogs/PreparingExportDialog/PreparingExportDialog";

export default class ListCateringMenus extends IbssPage<IProps, IState>
{
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get alert() { return appContext().alert; }
    private get localStorage() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);

        const hasCreateRight = this.localStorage.hasRight("DATAMODEL.CateringMenus.Create");

        this.state =
        {
            loading: false,
            buildingId: this.appState.buildingId,
            searchTerm: "",
            selectedStatus: "Any",
            tempStatus: "Any",
            isFilterDialogOpen: false,
            isUpdateRight: false,
            isDeleteRight: false,
            actionButtons: [
                {
                    label: this.labels.HubButtonDelete,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.deleteClicked(),
                    disabled: true,
                },
                {
                    label: this.labels.HubLabelDisable,
                    icon: (
                        <IbssSvgIcon>
                            <CancelOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "warning",
                    onClick: () => this.disableClicked(),
                    disabled: true,
                },
                {
                    label: this.labels.HubLabelEnable,
                    icon: (
                        <IbssSvgIcon>
                            <CheckCircleOutlineTwoToneIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.enableClicked(),
                    disabled: true,
                },
                {
                    label: this.labels.HubButtonAdd,
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    onClick: () => this.addMenuClicked(),
                    disabled: !hasCreateRight,
                }
            ],
            columns: [
                {
                    headerName: this.labels.HubLabelName,
                    field: Helper.nameOf<MenuView>("name"),
                    minWidth: 150,
                    flex: 2,
                },
                {
                    headerName: this.labels.HubLabelDescription,
                    field: Helper.nameOf<MenuView>("description"),
                    minWidth: 150,
                    flex: 3,
                },
                {
                    headerName: this.labels.HubLabelAvailableFrom,
                    field: Helper.nameOf<MenuView>("availableFrom"),
                    minWidth: 150,
                    flex: 2,
                    valueGetter: params => params.row.availableFrom && params.row.availableFrom.toLocaleDateTimeString()
                },
                {
                    headerName: this.labels.HubLabelAvailableTo,
                    field: Helper.nameOf<MenuView>("availableUntil"),
                    minWidth: 150,
                    flex: 2,
                    valueGetter: params => params.row.availableUntil && params.row.availableUntil.toLocaleDateTimeString()
                },
                {
                    headerName: "Actions", // todo: label
                    field: "",
                    minWidth: 150,
                    flex: 1,
                    filterable: false,
                    sortable: false,
                    renderCell: params => this.renderMenuActions(params.row),
                },
                {
                    headerName: this.labels.HubLabelStatus,
                    field: Helper.nameOf<MenuView>("status"),
                    minWidth: 150,
                    flex: 2,
                    renderCell: params => this.renderStatus(params.row),
                },
            ],
            menus: new Array<MenuView>(),
            showPreparingExportPopup: false
        }
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        await this.buildingChanged();
        this.setRights();
    }

    private setRights(): void 
    {
        this.setState({
            isUpdateRight: this.localStorage.hasRight("DATAMODEL.CateringMenus.Update"),
            isDeleteRight: this.localStorage.hasRight("DATAMODEL.CateringMenus.Delete")
        });
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            this.props.history.push(`/buildings/${this.state.buildingId}/catering-menus/`);
            await this.buildingChanged();
        }
    }

    private async buildingChanged(): Promise<void>
    {
        this.setState({ loading: true });
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        await this.loadMenus();
        this.setState({ loading: false });
    }

    private async loadMenus(): Promise<void>
    {
        try
        {
            const menus = await this.apiClient.cateringMenus.getMany(this.state.buildingId, false, false, 2000, new Filter({ }));
            const menusView = menus.value.map(i => MenuView.fromResponse(i));
            const selectedIds = this.selectedMenus.map(i => i.id);
            await this.setStateAsync({ menus: menusView });
            this.selectMenus(selectedIds);
        }
        catch
        {
        }
    }

    private selectMenus(selectedMenuIds: string[]): void
    {
        this.state.menus.forEach(i => i.selected = selectedMenuIds.contains(i.id));
        this.deleteButton.disabled = !(this.selectedMenus.length > 0 && this.state.isDeleteRight)
        this.disableButton.disabled = !(this.selectedMenus.length > 0 && this.selectedMenus.every(i => i.status.toLowerCase() === CateringMenuStatus.active.toLowerCase()) && this.state.isUpdateRight)
        this.enableButton.disabled = !(this.selectedMenus.length > 0 && this.selectedMenus.every(i => i.status.toLowerCase() === CateringMenuStatus.inactive.toLowerCase()) && this.state.isUpdateRight)
        
        this.setState({
            menus: this.state.menus,
            actionButtons: this.state.actionButtons,
        });
    }

    private searchTermChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        this.setState({ searchTerm: event.target.value });
    }

    private get filteredMenus(): MenuView[]
    {
        const filteredMenus = this.state.menus
            .filter(i =>
                Helper.countTerms(`${i.name} ${i.description}`, this.state.searchTerm) != 0 &&
                (this.state.selectedStatus == "Any" || i.status.indexOf(this.state.selectedStatus) != -1)
            );

        return filteredMenus;
    }

    private get selectedMenus(): MenuView[]
    {
        const selectedMenus = this.filteredMenus.filter(i => i.selected);
        return selectedMenus;
    }

    private statuses(): optionObject[]
    {
        const statuses = this.state.menus.groupBy(i => i.status).map(i => ({ value: i.key, label: i.key }));
        return [{ value: "Any", label: "Any" }, ...statuses];
    }

    private filterButtonClicked(): void
    {
        this.setState({ isFilterDialogOpen: true, tempStatus: this.state.selectedStatus });
    }

    private filterDialogClosed(): void
    {
        this.setState({ isFilterDialogOpen: false });
    }

    private filterDialogSubmitted(): void
    {
        this.setState({ isFilterDialogOpen: false, selectedStatus: this.state.tempStatus });
    }

    private get deleteButton(): IActionButton
    {
        return this.state.actionButtons.find(i => i.label == this.labels.HubButtonDelete) as IActionButton;
    }

    private deleteClicked(): Promise<void>
    {
        return this.actionButtonClicked((menuId, concurrencyStamp, suppressError) => this.apiClient.cateringMenus.delete(this.state.buildingId, menuId, suppressError));
    }

    private get disableButton(): IActionButton
    {
        return this.state.actionButtons.find(i => i.label == this.labels.HubLabelDisable) as IActionButton;
    }

    private disableClicked(): Promise<void>
    {
        return this.actionButtonClicked((menuId, concurrencyStamp, suppressError) => this.apiClient.cateringMenus.disable(this.state.buildingId, menuId, concurrencyStamp, suppressError));
    }

    private get enableButton(): IActionButton
    {
        return this.state.actionButtons.find(i => i.label == this.labels.HubLabelEnable) as IActionButton;
    }

    private enableClicked(): Promise<void>
    {
        return this.actionButtonClicked((menuId, concurrencyStamp, suppressError) => this.apiClient.cateringMenus.enable(this.state.buildingId, menuId, concurrencyStamp, suppressError));
    }

    private async actionButtonClicked(invokeEndpoint: (menuId: string, concurrencyStamp: string, suppressError: boolean) => Promise<void>): Promise<void>
    {
        this.setState({ loading: true });
        let suppressError = false;

        const manyEndpoints = this.selectedMenus.map(async i =>
        {
            try
            {
                await invokeEndpoint(i.id, i.concurrencyStamp, suppressError);
            }
            catch
            {
                suppressError = true;
            }
        });

        await Promise.all(manyEndpoints);
        await this.loadMenus();
        this.setState({ loading: false });
    }

    private menusSelected(selectedMenuIds: GridRowSelectionModel): void
    {
        this.selectMenus(selectedMenuIds as string[]);
    }

    private addMenuClicked(): void
    {
        this.props.history.push(`/buildings/${this.state.buildingId}/catering-menus/new`);
    }

    private editMenuClicked(menu: MenuView): void
    {
        this.props.history.push(`/buildings/${this.state.buildingId}/catering-menus/${menu.id}/edit`);
    }

    private renderMenuActions(menu: MenuView): JSX.Element
    {
  
        if(this.state.isUpdateRight)
        {
            return (
                <IbssSvgIcon className="pointer" onClick={() => this.editMenuClicked(menu)}>
                    <CreateIcon />
                </IbssSvgIcon>
            )
        } 
        else 
        {
            return <p>-</p>
        }
    }

    private renderStatus(menu: MenuView): JSX.Element
    {
        let backgroundColor: string;
        switch (menu.status.toLowerCase())
        {
            case CateringMenuStatus.active.toLowerCase():
                backgroundColor = '--ui-success-pastel';
                break;
            case CateringMenuStatus.inactive.toLowerCase():
                backgroundColor = '--ui-error-pastel';
                break;
            default:
                backgroundColor = '--ui-mid-tone';
                break;
        }

        return <IbssChip label={menu.status === "StatusActive" ? this.labels.HubLabelActive : this.labels.HubLabelInActive} 
        sx= {{
                backgroundColor: `var(${backgroundColor})`,
                color: "var(ui-text)",
            }} />;
    }

    private exportClicked(): void
    {
        this.setState({ showPreparingExportPopup: true });

        let filterValues = [];

        if (this.state.selectedStatus != "Any")
        {
            filterValues.push(`Status eq '${this.state.selectedStatus}'`)
        }

        appContext().ibssApiClientV2.v2.byNodeid.cateringMenu.download.post({
            nodeId: this.state.buildingId,
            body: {
                Node_Id: this.state.buildingId,
                Filter: filterValues.length == 0 ? "" : filterValues.join(' and '),
            }
        });
    }

    public render(): JSX.Element
    {
        return (
            <>
                <div className="page-container">
                    <PreparingExportDialog open={this.state.showPreparingExportPopup} onClose={() => this.setState({ showPreparingExportPopup: false })}/>
                    <IbssDialog
                        open={this.state.isFilterDialogOpen}
                        onClose={() => this.filterDialogClosed()}
                        fullWidth
                        header={this.labels.HubLabelFilter}
                        dialogContent=
                        {
                            <>
                                <Typography className="mb-3">{this.labels.HubCateringFilterSubTitle}</Typography>
                                <div className="row my-3">
                                    <IbssInputDropDown
                                        id="statusSelection"
                                        fullWidth
                                        inputLabel={this.labels.HubLabelStatus}
                                        options={this.statuses()}
                                        value={this.state.tempStatus}
                                        onChange={(e: SelectChangeEvent<string>) => this.setState({ tempStatus: e.target.value })}
                                    />
                                </div>
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButton
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => this.filterDialogClosed()}
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButton>
                                <IbssButton
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.filterDialogSubmitted()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButton>
                            </>
                        }
                    />
                    <div className="rightPanel">
                        {this.state.loading && <Spinner />}
                        <div className="rightPanel-main-content">
                            <div className="table-panel">
                                <Grid container rowSpacing={0} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0,pt:1 }}>
                                    <Grid item md={12} >
                                        <Box className="table-panel-header" sx={{ ml: 0 }}>{this.labels.HubMenuCateringMenus}</Box>
                                    </Grid>
                                    <Grid item md={12} mt={2}>
                                        <IbssFilter
                                            searchTerm={this.state.searchTerm}
                                            searchTermChanged={e => this.searchTermChanged(e)}
                                            filterButtonClicked={() => this.filterButtonClicked()}
                                            showExport
                                            exportButtonClicked={() => this.exportClicked()}
                                        />
                                    </Grid>
                                    <Grid item md={12} >
                                        <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                            <IbssActionButton
                                                buttons={this.state.actionButtons}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box>
                                    <IbssDataGrid
                                        checkboxSelection
                                        disableRowSelectionOnClick
                                        columns={this.state.columns}
                                        rows={this.filteredMenus}
                                        onRowSelectionModelChange={e => this.menusSelected(e)}
                                        initialState={{
                                            pagination: { paginationModel: {pageSize: 25} },
                                        }}
                                        pageSizeOptions={[25,50,100]}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

interface IProps extends RouteComponentProps
{
}

interface IState
{
    loading: boolean;
    buildingId: number;
    searchTerm: string;
    selectedStatus: string;
    tempStatus: string;
    isFilterDialogOpen: boolean;
    actionButtons: IActionButton[];
    columns: GridColDef<MenuView>[];
    menus: MenuView[];
    isUpdateRight: boolean;
    isDeleteRight: boolean;
    showPreparingExportPopup: boolean;
}

class MenuView
{
    public selected = false;
    public id = "";
    public name = "";
    public description = "";
    public availableFrom: DateTime | null = null;
    public availableUntil: DateTime | null = null;
    public status = "";
    public concurrencyStamp = "";

    constructor(data: Partial<MenuView>)
    {
        Object.assign(this, data);
    }

    public static fromResponse(menu: ICateringMenu)
    {
        const menuView = new MenuView(
            {
                id: menu.Menu_Id,
                name: menu.Name,
                description: menu.Description,
                availableFrom: menu.Available_From?.offsetTimeByNode(menu.Node_Id),
                availableUntil: menu.Available_Until?.offsetTimeByNode(menu.Node_Id),
                status: menu.Status,
                concurrencyStamp: menu.ConcurrencyStamp,
            });
        return menuView;
    }
}
