import { appContext } from '../../../../AppContext';
import { IFloor } from '../../../../Providers.Api/Models';
import { Grid, SelectChangeEvent } from '@mui/material';
import Helper from '../../../../Common/Helper';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssDatePicker from '../../../../Components/Inputs/DatePicker/IbssDatePicker';
import { DateHelper } from '../../../../Common/DateHelper';
import { DateTime } from 'luxon';
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';

class SearchSpaceFilter extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingOptions: [],
            floorOptions: [],
            zoneOptions: [],
            workSpaceTypeOptions: [],
            spaceTypeOptions: [],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.populateBuildings();
        this.populateFloors();
        this.populateZones();
        this.populateWorkSpaces();
        this.populateSpaceTypes();
    }

    public async componentDidUpdate(prevProps: IProps): Promise<void>
    {
        const props = this.props;
        if (props.selectedBuilding != prevProps.selectedBuilding)
        {
            this.populateFloors();
            this.populateWorkSpaces();
            this.populateSpaceTypes();
        }
        if (props.selectedFloor != prevProps.selectedFloor)
        {
            this.populateZones();
        }
    }

    private populateBuildings(): void
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options });
    }

    private populateFloors(): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(this.props.selectedBuilding);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id.toString() }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));

        options.unshift({ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny });
        this.setState({ floorOptions: options });
    }

    private async populateZones(): Promise<void>
    {
        if (this.props.selectedFloor === "Any")
        {
            this.setState({ zoneOptions: [{ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny }] });
            return;
        }

        const zonesResponse = await this.apiClient.spaceZones.getMultiple(parseInt(this.props.selectedFloor), true);
        const options = zonesResponse.map(i => ({ label: i.Meta_Loc_Zone, value: i.Space_Zone_Id.toString() }));
        const zone = this.props.selectedZone === "Any" ? this.props.selectedZone : options.find(options => options.value === this.props.selectedZone)?.value ?? '';

        options.unshift({ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny });
        this.setState({ zoneOptions: options });
    }

    private populateWorkSpaces(): void
    {
        const workSpaces = Helper.getWorkSpaceTypesByNodeId(this.props.selectedBuilding);

        const options = workSpaces
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));

        options.unshift({ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny });
        this.setState({ workSpaceTypeOptions: options });

    }

    private populateSpaceTypes(): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(this.props.selectedBuilding);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => a.label.localeCompare(b.label));

        options.unshift({ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny });
        this.setState({ spaceTypeOptions: options });
    }

    public render(): JSX.Element
    {
        return (
            <Grid container spacing={3}>
                <Grid item xs={1.5}>
                    <IbssFormControl fullWidth={true} onClick={this.props.onDateTimeClick}>
                        <IbssTimePicker
                            label={this.labels.HubLabelTime}
                            value={this.props.startDateTime}
                            minutesStep={1}
                            ampm={false}
                            readOnly={true}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.75}>
                    <IbssFormControl fullWidth={true} onClick={this.props.onDateTimeClick}>
                        <IbssDatePicker
                            label={this.labels.HubLabelDate}
                            value={this.props.startDateTime}
                            minDate={DateHelper.now()}
                            readOnly={true}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.75}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="buildingSelection"
                            inputLabel={this.labels.HubLabelBuilding}
                            fullWidth={true}
                            options={this.state.buildingOptions}
                            value={this.props.selectedBuilding}
                            onChange={(e: SelectChangeEvent<number>) => this.props.onBuildingChange(e.target.value as number)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.75}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="floorSelection"
                            inputLabel={this.labels.HubLabelFloor}
                            fullWidth={true}
                            options={this.state.floorOptions}
                            value={this.props.selectedFloor}
                            onChange={(e: SelectChangeEvent) => this.props.onFloorChange(e.target.value)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.5}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="zoneSelection"
                            inputLabel={this.labels.HubLabelZone}
                            fullWidth={true}
                            options={this.state.zoneOptions}
                            value={this.props.selectedZone}
                            onChange={(e: SelectChangeEvent) => this.props.onZoneChange(e.target.value)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.75}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="workTypeSelection"
                            inputLabel={this.labels.HubLabelworkType}
                            fullWidth={true}
                            options={this.state.workSpaceTypeOptions}
                            value={this.props.selectedWorkspaceType}
                            onChange={(e: SelectChangeEvent) => this.props.onWorkSpaceTypeChange(e.target.value)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.5}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="spaceTypeSelection"
                            inputLabel={this.labels.HubLabelSpaceType}
                            fullWidth={true}
                            options={this.state.spaceTypeOptions}
                            value={this.props.selectedSpaceType}
                            onChange={(e: SelectChangeEvent) => this.props.onSpaceTypeChange(e.target.value)}
                        />
                    </IbssFormControl>
                </Grid>
            </Grid>
        )
    }
}
export default SearchSpaceFilter;

export interface IProps
{
    startDateTime: DateTime
    selectedBuilding: number;
    selectedFloor: string;
    selectedZone: string;
    selectedWorkspaceType: string;
    selectedSpaceType: string;
    onDateTimeClick: () => void
    onBuildingChange: (buildingId: number) => void,
    onFloorChange: (floor: string) => void,
    onZoneChange: (zone: string) => void,
    onSpaceTypeChange: (spaceType: string) => void,
    onWorkSpaceTypeChange: (spaceWorkType: string) => void,
}

export interface IState
{
    buildingOptions: Array<IListOption<number>>,
    floorOptions: Array<IListOption<string>>,
    zoneOptions: Array<IListOption<string>>,
    workSpaceTypeOptions: Array<IListOption<string>>,
    spaceTypeOptions: Array<IListOption<string>>,
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}
