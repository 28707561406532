import { DateTime } from "luxon";
import { StringHelper } from "../../../Common/StringHelper";
import { appContext } from "../../../AppContext";
import { DateHelper } from "../../../Common/DateHelper";

export class DateRangeHelper
{
    private get labels() { return appContext().labels; }
    private string = new StringHelper();
    private dateRanges: IDateRange[] = [];
    private today: DateTime;

    constructor(buildingId: number)
    {
        this.today = DateHelper.today(buildingId);
    }

    public addToday(period?: string, start?: DateTime, end?: DateTime): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelToday, this.today, this.today);
    }

    public addTomorrow(period?: string, start?: DateTime, end?: DateTime): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelTomorrow, this.today.plus({ days: 1 }), this.today.plus({ days: 1 }));
    }

    public addThisWeek(period?: string, start?: DateTime, end?: DateTime): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelThisWeek, this.today.startOf('week'), this.today.startOf('week').plus({ days: 6 }));
    }

    public addNextWeek(period?: string, start?: DateTime, end?: DateTime): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelNextWeek, this.today.startOf('week').plus({ days: 7 }), this.today.startOf('week').plus({ days: 13 }));
    }

    public addThisMonth(period?: string, start?: DateTime, end?: DateTime): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelThisMonth, this.today.startOf('month'), this.today.startOf('month').plus({ months: 1, days: -1 }));
    }

    public addNextMonth(period?: string, start?: DateTime, end?: DateTime): DateRangeHelper
    {
        return this.addDateRange(this.labels.HubLabelNextMonth, this.today.startOf('month').plus({ months: 1 }), this.today.startOf('month').plus({ months: 2, days: -1 }));
    }

    public addDateRange(period: string, start: DateTime, end: DateTime): DateRangeHelper
    {
        this.dateRanges.push({ period: period, start: start, end: end });
        return this;
    }

    public getDateRange(period?: string, defaultPeriod?: string, start?: DateTime, end?: DateTime): IDateRange
    {
        const string = this.string;
        const labels = this.labels;
        const today = this.today;
        const periodOrDefault = (!period && !start && !end ? defaultPeriod : period) ?? labels.HubLabelCustom;
        const dateRange = this.dateRanges.find(i => string.toConcatenatedLowercase(i.period) == string.toConcatenatedLowercase(periodOrDefault));

        const result = {
            period: dateRange?.period ?? labels.HubLabelCustom,
            start: dateRange?.start ?? start ?? today,
            end: dateRange?.end ?? end ?? today,
        };
        return result;
    }
}

export interface IDateRange
{
    period: string;
    start: DateTime;
    end: DateTime;
}
