import { DateTime } from "luxon";

export class InMemoryCache
{
    private items: IItem<unknown>[] = [];
    private quickExpiryInSeconds = 60;
    private garbageCollectionIntervalInSeconds = 300;

    public lazyGetWithQuickExpiry<TValue>(key: string, refresh: () => Promise<TValue>): Promise<TValue>
    {
        return this.lazyGet(key, DateTime.now().plus({ seconds: this.quickExpiryInSeconds }), refresh);
    }

    public async lazyGet<TValue>(key: string, expires: DateTime, refresh: () => Promise<TValue>): Promise<TValue>
    {
        let value = this.get<TValue>(key);
        if (value == null)
        {
            value = await refresh();
            this.set(key, value, expires);
        }
        return value;
    }

    public get<TValue>(key: string): TValue | null
    {
        const item = (this.items.find(i => i.key == key) ?? null) as IItem<TValue> | null;
        if (item == null || DateTime.now() >= item.expires)
        {
            return null;
        }
        return item.value;
    }

    public async setWithQuickExpiry<TValue>(key: string, value: TValue): Promise<void>
    {
        return this.set(key, value, DateTime.now().plus({ seconds: this.quickExpiryInSeconds }));
    }

    public set<TValue>(key: string, value: TValue, expires: DateTime): void
    {
        this.clear(key);
        const newItem: IItem<TValue> = { key: key, value: value, expires: expires };
        this.items.push(newItem);
    }

    public clear(key: string): void
    {
        this.items = this.items.filter(i => i.key != key);
    }


    public async clearExpired(): Promise<void>
    {
        for (const item of this.items)
        {
            if (DateTime.now() >= item.expires)
            {
                this.clear(item.key);
            }
        }
    }

    public startGarbageCollection(): void
    {
        setInterval(() => this.clearExpired(), 1000 * this.garbageCollectionIntervalInSeconds);
    }
}

interface IItem<TValue>
{
    key: string;
    value: TValue;
    expires: DateTime;
}
