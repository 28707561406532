import { Card, Grid, Typography } from '@mui/material';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import { appContext } from '../../../../AppContext';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import Helper, { getFloorNameUsingFloorAndBuildingId } from '../../../../Common/Helper';
import { IFloor, PagedResponse } from '../../../../Providers.Api/Models';
import { BuildingChangeReason } from '../../../../Components/Core/BasePage/Types';
import { RouteComponentProps } from 'react-router';
import { SpaceLayouts } from './DataModels';
import React from 'react';
import ServerDataGrid, { DataGridQueryResult, IDataQuery, IPage, IPages } from '../../../../Components/Data/ServerDataGrid/ServerDataGrid';
import LayoutAndLinkedSpacesInstructions from './LayoutAndLinkedSpacesInstructions';
import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid';
import EditSpaceLayoutPanel from './EditSpaceLayoutPanel';
import CreateLinkedSpaceLayoutPanel from './CreateLinkedSpacePanel';
import SpaceMustBeOnSameFloorModal from './SpaceMustBeOnSameFloorModal';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';

class ListSpaceLayouts extends IbssPage<IProps, IState>
{
    private dataGridRef = React.createRef<ServerDataGrid<SpaceLayoutView>>();
    private get dataGrid() { return this.dataGridRef.current; }
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            floorOptions: [],
            spaceTypeOptions: [],
            zoneOptions: [],
            selectedFloor: 0,
            selectedSpaceType: 'any',
            selectedSpacesIds: [],
            selectedSpacesData: [],
            unsavedLayoutEditChanges: false,
            showUnsavedChangesModal: false,
            showSameFloorWarning: false,
            selectedZone: 'any',
            disableRefreshCache: false,
            pages: {
                pages: [],
                pageIndex: 0,
                skipToken: '',
            }
        };
    };

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcManageSpaceLayouts_S;
        this.populateFloors(parseInt(this.props.match.params.buildingId));
        this.populateSpaceTypeOptions();
        this.onBuildingIdChanged<IMatchParams>(params => params.buildingId, (buildingId, reason) => this.buildingIdChanged(buildingId, reason));
    }

    public populateFloors(selectedBuildingId: number): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);
        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));
        this.populateZones(options[0].value, 'any');
        this.setState({ floorOptions: [{ label: this.labels.HubLabelAny, value: 0 }, ...options] });
    }

    public populateSpaceTypeOptions(): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(parseInt(this.props.match.params.buildingId));
        this.setState({ spaceTypeOptions: [{ label: this.labels.HubLabelAny, value: 'any' }, ...spaceTypes.result.map(x => ({ label: x.Label, value: x.Name }))] });
    }

    public async buildingIdChanged(buildingId: number, reason: BuildingChangeReason): Promise<void>
    {
        if (reason == "BuildingSelectorChanged")
        {
            this.props.history.push(`/oneLens/${buildingId}/space-layouts`);
        }
        await this.setStateAsync({ selectedFloor: 0, selectedSpaceType: 'any', selectedZone: 'any' });
        this.populateFloors(buildingId);
        this.populateSpaceTypeOptions();
        this.dataGrid?.refresh()
    }

    public async floorChanged(floorId: number): Promise<void>
    {
        await this.setStateAsync({ selectedFloor: floorId, selectedZone: 'any', zoneOptions: [{ label: this.labels.HubLabelAny, value: 'any' }] });
        if (floorId != 0)
        {
            await this.populateZones(floorId, 'any');
        }
        await this.dataGrid?.refresh();
    }

    public async populateZones(selectedFloor: number, selectedZone: string): Promise<void>
    {
        const zonesResponse = await this.apiClient.spaceZones.getMultiple(selectedFloor, true);
        const zones = zonesResponse;
        const options = zones.map(i => ({ label: i.Meta_Loc_Zone, value: i.Meta_Loc_Zone }));
        this.setState({ zoneOptions: [{ label: this.labels.HubLabelAny, value: 'any' }, ...options], selectedZone: selectedZone });
    }

    public async spaceTypeChanged(spaceType: string): Promise<void>
    {
        await this.setStateAsync({ selectedSpaceType: spaceType });
        await this.dataGrid?.refresh();
    }

    public async zoneChanged(zone: string): Promise<void>
    {
        await this.setStateAsync({ selectedZone: zone });
        await this.dataGrid?.refresh();
    }

    private async dataQueryChanged(gridQuery: IDataQuery): Promise<DataGridQueryResult<SpaceLayoutView>>
    {
        const spaceTypeFilter = this.state.selectedSpaceType == 'any' ? '' : `Space_Type eq '${this.state.selectedSpaceType}'`;
        const zoneFilter = this.state.selectedZone == 'any' ? '' : `Meta_Loc_Zone eq '${this.state.selectedZone}'`;
        const spaces = await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.get<PagedResponse<SpaceLayouts[]>>({
            nodeId: this.state.selectedFloor != 0 ? this.state.selectedFloor : parseInt(this.props.match.params.buildingId),
            filter: spaceTypeFilter + (spaceTypeFilter.length > 0 && zoneFilter.length > 0 ? ' and ' : '') + zoneFilter,
            recursive: true,
            top: 100,
            select: SpaceLayouts,
            skipToken: gridQuery.skipToken
        });

        let linkedSpaces: SpaceLayoutView[] = []
        spaces.value.forEach(space =>
        {
            if (space.Space_Layout != "")
            {
                try
                {
                    this.getLinkedSpaces(space.Space_Id, space.Space_Layout).map(linkedSpace => !linkedSpaces.some(existingLinkedSpace => existingLinkedSpace.id == linkedSpace.Space_Id) ?
                        linkedSpaces.push({
                            id: linkedSpace.Space_Id,
                            name: linkedSpace.Name,
                            floorId: space.Node_Id,
                            floorName: getFloorNameUsingFloorAndBuildingId(this.props.match.params.buildingId, space.Node_Id),
                            zone: space.Meta_Loc_Zone,
                            spaceLayouts: space.Space_Layout,
                            concurrencyStamp: space.ConcurrencyStamp,
                            isLinkedSpace: true
                        }) : {});
                } catch (error)
                {
                }
            }
        });


        return new DataGridQueryResult(
            [
                ...linkedSpaces,
                ...spaces.value.map(x => ({
                    id: x.Space_Id,
                    name: x.Space_Name,
                    floorId: x.Node_Id,
                    floorName: getFloorNameUsingFloorAndBuildingId(this.props.match.params.buildingId, x.Node_Id),
                    zone: x.Meta_Loc_Zone,
                    spaceLayouts: x.Space_Layout,
                    concurrencyStamp: x.ConcurrencyStamp,
                    isLinkedSpace: false
                }))
            ].sort((a, b) => a.id > b.id ? 1 : -1),
            spaces.skipToken);
    }


    private layoutEdited(): void
    {
        this.setState({ unsavedLayoutEditChanges: true })
    }

    private selectionChanged(selection: GridRowSelectionModel): void
    {
        const unselectingSpace = selection.length < this.state.selectedSpacesIds.length;
        if (!unselectingSpace)
        {
            const selectedSpace = selection.filter(x => !this.state.selectedSpacesIds.includes(x))[0];
            const alreadySelectedSpacesFloorId = this.state.selectedSpacesData.filter(x => x.id == this.state.selectedSpacesIds[0])[0]?.floorId;
            const selectedFloor = this.dataGridRef.current?.rows.filter(x => x.id == selectedSpace)[0];
            const newlySelectedSpaceFloorId = selectedFloor?.floorId;
            const selectedSpaceIsLinkedSpace = selectedFloor?.isLinkedSpace;

            if (selection.length > 1 && (alreadySelectedSpacesFloorId != newlySelectedSpaceFloorId || selectedSpaceIsLinkedSpace))
            {
                this.setState({ showSameFloorWarning: true });
                return; // dont allow space selection if its on a different floor to currently selected spaces
            }
        }
        if (this.state.unsavedLayoutEditChanges)
        {
            this.setState({ showUnsavedChangesModal: true });
            return; //dont allow space selection if a user has unsaved layout changes
        }
        selection.forEach(id =>
        {
            if (!this.state.selectedSpacesData.some(x => x.id == id))
            {
                const selectedSpaceData = this.dataGridRef.current?.rows.filter(x => x.id == id)[0]
                if (selectedSpaceData)
                {
                    this.setState({
                        selectedSpacesData: [
                            ...this.state.selectedSpacesData,
                            { floorName: selectedSpaceData.floorName, id: selectedSpaceData.id, floorId: selectedSpaceData.floorId, name: selectedSpaceData.name, zone: selectedSpaceData.zone, spaceLayouts: selectedSpaceData.spaceLayouts, concurrencyStamp: selectedSpaceData.concurrencyStamp, isLinkedSpace: selectedSpaceData.isLinkedSpace }
                        ]
                    });
                }
            }
        })
        this.setState({ selectedSpacesIds: selection });
    }

    private getLinkedSpaces(spaceId: string, spaceLayoutJson: string): ISpaceLayout[]
    {
        const spaceLayout = JSON.parse(spaceLayoutJson)
        return spaceLayout.Layouts ? spaceLayout.Layouts.filter((x: ISpaceLayout) => x.Space_Id != spaceId) : []
    }

    private getSelectedSpaceData(): SpaceLayoutView[]
    {
        return this.state.selectedSpacesData.filter(x => this.state.selectedSpacesIds.includes(x.id))
    }

    private async layoutChangesSaved(): Promise<void>
    {
        await this.setStateAsync({ unsavedLayoutEditChanges: false, showUnsavedChangesModal: false, pages: { ...this.state.pages, skipToken: '' }, selectedSpacesData: [] });
        await this.dataGrid?.refresh();
    }

    private async linkedSpaceChangesSaved(): Promise<void>
    {
        await this.setStateAsync({ selectedSpacesData: [] });
        await this.dataGrid?.refresh();
    }

    private async refreshCache(): Promise<void>
    {
        this.setState({ disableRefreshCache: true });
        setTimeout(() => this.setState({ disableRefreshCache: false }), 30000);
        await appContext().ibssApiClientV1.v1.service.refreshCache.get({
            type: 'Spaces'
        });
    }


    public render(): JSX.Element
    {
        const columns = [
            {
                field: Helper.nameOf<SpaceLayoutView>("name"),
                headerName: this.labels.HubLabelName,
                flex: 1
            },
            {
                field: Helper.nameOf<SpaceLayoutView>("id"),
                headerName: this.labels.HubLabelId,
                flex: 1
            },
            {
                field: Helper.nameOf<SpaceLayoutView>("floorName"),
                headerName: this.labels.HubLabelFloor,
                flex: 1
            },
            {
                field: Helper.nameOf<SpaceLayoutView>("zone"),
                headerName: this.labels.HubLabelZone,
                flex: 1
            },
        ];

        const selectedSpaces = this.getSelectedSpaceData();

        return (
            <div className="rightPanel-main-content">
                <Grid container spacing={4}>
                    <Grid xs={8} item>
                        <Card>
                            <div className='m-3'>
                                <Typography variant='h5' gutterBottom>{this.labels.funcSelectASpace_S}</Typography>
                                <Typography variant="subtitle1" gutterBottom>{this.labels.funcSelectASpaceSubheading_Message}</Typography>
                                <div className='d-flex'>
                                    <div className='mb-1 mt-1 mr-1' style={{ width: '250px' }}>
                                        <IbssInputDropDown inputLabel={this.labels.HubLabelFloor} id='floorDropdown' value={this.state.selectedFloor} options={this.state.floorOptions} onChange={(e: { target: { value: number; }; }) => this.floorChanged(e.target.value)} />
                                    </div>
                                    <div className='mb-1 mt-1 mr-1' style={{ width: '200px' }}>
                                        <IbssInputDropDown inputLabel={this.labels.HubLabelSpaceType} id='spaceTypesDropdown' value={this.state.selectedSpaceType} options={this.state.spaceTypeOptions} onChange={(e: { target: { value: string; }; }) => this.spaceTypeChanged(e.target.value)} />
                                    </div>
                                    <div className='mb-1 mt-1' style={{ width: '200px' }}>
                                        <IbssInputDropDown inputLabel={this.labels.HubLabelZone} id='zoneDropdown' disabled={this.state.selectedFloor == 0} value={this.state.selectedZone} options={this.state.zoneOptions} onChange={(e: { target: { value: string; }; }) => this.zoneChanged(e.target.value)} />
                                    </div>
                                </div>
                                <Grid container>
                                    <Grid item sm={12}>
                                        <div className='mb-1' style={{ float: 'right' }}>
                                            <IbssButton variant='contained' color='primary' onClick={() => this.refreshCache()} disabled={this.state.disableRefreshCache}>{this.labels.funcSpaceRefreshCache_S}</IbssButton>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div style={{ width: '100%' }}>
                                    <ServerDataGrid
                                        ref={this.dataGridRef}
                                        checkboxSelection
                                        onDataQueryChange={i => this.dataQueryChanged(i)}
                                        columns={columns}
                                        onRowSelectionModelChange={(e) => this.selectionChanged(e)}
                                        disableRowSelectionOnClick
                                        pageSizeOptions={[100]}
                                        rowSelectionModel={this.state.selectedSpacesIds}
                                        isRowSelectable={(params: GridRowParams) =>
                                            // linked spaces cant be selected alongside other spaces
                                            !(params.row.isLinkedSpace && this.state.selectedSpacesIds.length > 0 && params.row.id != this.state.selectedSpacesIds[0]) &&
                                            !(this.state.selectedSpacesIds.length > 0 && this.state.selectedSpacesData.filter(x => x.id == this.state.selectedSpacesIds[0])[0].isLinkedSpace && params.row.id != this.state.selectedSpacesIds[0])
                                        }
                                        pages={this.state.pages}
                                        onPagesChange={pages => this.setStateAsync({ pages: pages })}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Grid>
                    <Grid xs={4} item>
                        <Card>
                            {
                                this.state.selectedSpacesIds.length == 0 &&
                                <LayoutAndLinkedSpacesInstructions />
                            }
                            {
                                this.state.selectedSpacesIds.length == 1 &&
                                <EditSpaceLayoutPanel
                                    buildingId={parseInt(this.props.match.params.buildingId)}
                                    floorId={selectedSpaces[0].floorId}
                                    floorName={selectedSpaces[0].floorName}
                                    spaceName={selectedSpaces[0].name}
                                    spaceId={selectedSpaces[0].id}
                                    zone={selectedSpaces[0].zone}
                                    spaceLayouts={selectedSpaces[0].spaceLayouts}
                                    layouts={selectedSpaces[0].spaceLayouts != "" ? JSON.parse(selectedSpaces[0].spaceLayouts)?.Layouts : { Layouts: [] }}
                                    concurrencyStamp={selectedSpaces[0].concurrencyStamp}
                                    isLinkedSpace={selectedSpaces[0].isLinkedSpace}
                                    layoutEdited={() => this.layoutEdited()}
                                    clearSelectedSpaces={() => this.setState({ selectedSpacesIds: [] })}
                                    showUnsavedChangesModal={this.state.showUnsavedChangesModal}
                                    ignoreUnsavedChanges={() => this.setState({ showUnsavedChangesModal: false, unsavedLayoutEditChanges: false })}
                                    closeUnsavedChangesModal={() => this.setState({ showUnsavedChangesModal: false })}
                                    unsavedChanges={this.state.unsavedLayoutEditChanges}
                                    onChangesSaved={() => this.layoutChangesSaved()}
                                />
                            }
                            {
                                this.state.selectedSpacesIds.length > 1 &&
                                <CreateLinkedSpaceLayoutPanel
                                    floorId={this.state.selectedSpacesData.filter(x => x.id == this.state.selectedSpacesIds[0])[0].floorId}
                                    selectedSpaces={this.state.selectedSpacesData.filter(x => this.state.selectedSpacesIds.includes(x.id))}
                                    clearSelectedSpaces={() => this.setState({ selectedSpacesIds: [] })}
                                    onChangesSaved={() => this.linkedSpaceChangesSaved()}
                                    linkedSpaceAlreadyExists={this.dataGridRef.current?.rows.map(x => x.id).includes(this.state.selectedSpacesIds.join(';')) ?? false}
                                />
                            }
                        </Card>
                    </Grid>
                </Grid>
                <SpaceMustBeOnSameFloorModal show={this.state.showSameFloorWarning} close={() => this.setState({ showSameFloorWarning: false })} />
            </div>
        )
    }
}

export default ListSpaceLayouts;

export interface IProps extends RouteComponentProps<IMatchParams>
{
}

export interface IState
{
    floorOptions: IDropdownOptions[];
    spaceTypeOptions: IDropdownOptions[];
    zoneOptions: IDropdownOptions[];
    selectedFloor: number;
    selectedSpacesIds: GridRowSelectionModel;
    selectedSpacesData: SpaceLayoutView[];
    unsavedLayoutEditChanges: boolean;
    showUnsavedChangesModal: boolean;
    showSameFloorWarning: boolean;
    selectedSpaceType: string;
    selectedZone: string;
    disableRefreshCache: boolean;
    pages: IPages<SpaceLayoutView>;
}

export interface IMatchParams
{
    buildingId: string;
}

export interface IDropdownOptions
{
    label: string;
    value: number | string
}

export interface SpaceLayoutView
{
    name: string;
    id: string;
    floorId: number
    floorName: string;
    zone: string;
    spaceLayouts: string;
    concurrencyStamp: string;
    isLinkedSpace: boolean;
}

export interface ISpaceLayout
{
    Name: string;
    Space_Id: string;
    SeatingArrangements: ISeatingArrangement[];
}

export interface ISeatingArrangement
{
    Style: string
    Capacity: number;
    Setup: number
    Breakdown: number;
}