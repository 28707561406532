import { Box, Divider, SelectChangeEvent, Typography } from '@mui/material';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssTimePicker from "../../../../Components/Inputs/TimePicker/IbssTimePicker";
import IbssDatePicker from "../../../../Components/Inputs/DatePicker/IbssDatePicker";
import { DateTime } from "luxon";
import { appContext } from '../../../../AppContext';
import { DateHelper } from '../../../../Common/DateHelper';
import IbssDrawer from '../../../../Components/Drawer/IbssDrawer';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import Helper, { getFloorUsingFloorId } from '../../../../Common/Helper';
import { IFloor, PagedResponse } from '../../../../Providers.Api/Models';
import IbssSwitchLabel from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { ISearchRecurring } from '../../../../Providers.IbssApiClientV2/IbssApiClientV2';
import { IBatch, Pager } from '../../../../Common/Pager';
import { Space } from '../../../../Providers.Api/Spaces/SpaceRepository';
import { ISpaceData } from '../../Tasks/Edit/DataModels';
import IbssChip from '../../../../Components/Navigation/Chip/IbssChip';
import SwapSpaceCard from '../../../../Components/Cards/SwapSpaceCard/SwapSpaceCard';

export default class SelectSpaceDrawer extends IbssComponent<IProps, IState> 
{
    private defaultWorkType = 'FormalMeeting';
    private defaultSpaceType = 'MeetingRoom';

    private pager = new Pager<ISpace>();

    private get labels() { return appContext().labels; }

    constructor(props: IProps) 
    {
        super(props);

        this.state =
        {
            start: this.props.availableFrom,
            end: this.props.availableTo,
            date: this.props.availableFrom,
            buildingOptions: [],
            selectedBuildingOption: this.props.buildingId,
            floorTypeOptions: [],
            selectedFloor: this.props.floorId,
            floorName: '',
            workTypeOptions: [],
            workTypeId: '',
            spaceTypeOptions: [],
            spaceTypeId: '',
            rawMinCapacity: '',
            spaces: new Map(),
            pageOfSpaces: [],
            isLoading: false,
            searchFilter: true,
            selectedSpaceId: '',
            selectedNodeId: 0,
            requiresCatering: false,
            requiresEquipment: false,
            requiresAudioVisual: false,
            requiresPresentationAids: false,

        };

        this.pager.pageSize = 10;
        this.pager.getItems = (skipToken, pageSize) => this.loadSpaces(skipToken, pageSize);
    }



    public async componentDidMount(): Promise<void>
    {
        this.populateBuildings(this.state.selectedBuildingOption);
        this.populateFloors(this.state.selectedBuildingOption);
        this.loadWorkTypes(this.state.selectedBuildingOption);
        this.loadSpaceTypes(this.state.selectedBuildingOption);
        await this.getSpaces(this.props.buildingId);
    }


    public async componentDidUpdate(prevProps: IProps): Promise<void>
    {
        if (prevProps.open !== this.props.open && this.props.open) 
        {
            this.setState({
                searchFilter: true,
                start: this.props.availableFrom,
                end: this.props.availableTo,
                date: this.props.availableFrom,
                selectedBuildingOption: this.props.buildingId,
                selectedFloor: this.props.floorId,
                spaceTypeId: this.props.spaceTypeId,
                rawMinCapacity: this.props.minCapacity.toString(),
                requiresCatering: this.props.requiresCatering,
                requiresEquipment: this.props.requiresHearingAids,
                requiresAudioVisual: this.props.requiresAudioVisual,
                requiresPresentationAids: this.props.requiresPresentationAids,
            });

            this.populateBuildings(this.props.buildingId);
            this.populateFloors(this.props.buildingId);
            this.loadWorkTypes(this.state.selectedBuildingOption);
            this.loadSpaceTypes(this.state.selectedBuildingOption);
        }

        if(prevProps.buildingId !== this.props.buildingId)
        {
            await this.getSpaces(this.props.buildingId);
        }
    }

    private startTimeChanged = (time: DateTime | null) => 
    {
        if (time && time.isValid) 
        {
            const { availableFrom } = this.props;
            const newStartTime = time.set({
                year: availableFrom.year,
                month: availableFrom.month,
                day: availableFrom.day,
            });

            this.setState({ start: newStartTime });
        }
    };

    private endTimeChanged = (time: DateTime | null) => 
    {
        if (time && time.isValid) 
        {
            const { availableFrom } = this.props;
            const newStartTime = time.set({
                year: availableFrom.year,
                month: availableFrom.month,
                day: availableFrom.day,
            });

            this.setState({ end: newStartTime });
        }
    };

    private dateChanged(date: DateTime | null): void
    {
        if(date != null)
        {
            this.setState({
                date: date,
                start: this.state.start.set({ year: date.year, month: date.month, day: date.day }),
                end: this.state.end.set({ year: date.year, month: date.month, day: date.day })
            });
        }
    }

    private populateBuildings(selectedValue: number): void
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options, selectedBuildingOption: selectedValue });
    }

    private populateFloors(selectedBuildingId: number): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1)); // sort by name
            
        options.unshift({ value: 0, label: this.labels.HubLabelAny });
        this.setState({ floorTypeOptions: options });
    }


    private async buildingChanged(event: SelectChangeEvent<number>): Promise<void> 
    {
        const selectedBuildingId = event.target.value as number;
        await this.setStateAsync({
            selectedBuildingOption: selectedBuildingId,
        });
        this.populateFloors(selectedBuildingId);
        this.loadWorkTypes(selectedBuildingId);
        this.loadSpaceTypes(selectedBuildingId);
        await this.getSpaces(selectedBuildingId);
    }

    private loadWorkTypes(building: number): void
    {
        const workTypes = Helper.getWorkSpaceTypesByNodeId(building);

        const options = workTypes
            .filter(i => i.Name != null)
            .map(i => ({ value: i.Name, label: i.Label }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));

        options.unshift({ value: 'Any', label: this.labels.HubLabelAny });
        this.setState({ workTypeOptions: options });
    }

    private loadSpaceTypes(building: number): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(building);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ value: i.Name, label: i.Label }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));

        options.unshift({ value: 'Any', label: this.labels.HubLabelAny });
        this.setState({ spaceTypeOptions: options });
    }

    private async floorChanged(event: SelectChangeEvent): Promise<void>
    {
        const getFloorName = this.state.floorTypeOptions.find(i => i.value == Number(event.target.value))?.label;
        
        await this.setState({ 
            selectedFloor: Number(event.target.value), 
            floorName: getFloorName ? getFloorName : '',  
        });
    }

    private handleWorkTypeChange(e: SelectChangeEvent): void
    {
        const state = this.state;
        const workTypeId = e.target.value;
        let spaceTypeId = this.state.spaceTypeId;
        const bothAny = (workTypeId == 'Any' && spaceTypeId == 'Any');
        const bothSomething = (workTypeId != 'Any' && spaceTypeId != 'Any');
        const hasDefault = state.spaceTypeOptions.some(i => i.value == this.defaultSpaceType);

        if (bothAny)
        {
            spaceTypeId = (hasDefault ? this.defaultSpaceType : state.spaceTypeOptions[1].value);
        }
        else if (bothSomething)
        {
            spaceTypeId = 'Any';
        }
        this.setState({ workTypeId: workTypeId, spaceTypeId: spaceTypeId });
    }

    private handleSpaceTypeChange(e: SelectChangeEvent): void
    {
        const state = this.state;
        const spaceTypeId = e.target.value;
        let workTypeId = this.state.workTypeId;
        const bothAny = (workTypeId == 'Any' && spaceTypeId == 'Any');
        const bothSomething = (workTypeId != 'Any' && spaceTypeId != 'Any');
        const hasDefault = state.workTypeOptions.some(i => i.value == this.defaultWorkType);

        if (bothAny)
        {
            workTypeId = (hasDefault ? this.defaultWorkType : this.state.workTypeOptions[1].value);
        }
        else if (bothSomething)
        {
            workTypeId = 'Any';
        }
        this.setState({ workTypeId: workTypeId, spaceTypeId: spaceTypeId });
    }

    private async getSpaces(buildingId: number): Promise<void>
    {
        const spaces = await appContext().apiCache.getSpacesByBuilding(buildingId);
        const indexedSpaces = new Map(spaces.map(i => [i.Space_Id, i]));
        await this.setStateAsync({ spaces: indexedSpaces });
    }

    private get payload(): ISearchRecurring
    {

        const state = this.state;

        const payload: ISearchRecurring = {
            Booking_Dates: [{
                Start_Time: this.state.start.toUtcByNode(this.props.buildingId).toISO(),
                End_Time: this.state.end.toUtcByNode(this.props.buildingId).toISO(),
            }],
            Floor_Id: state.selectedFloor != 0 ? state.selectedFloor : undefined,
            Space_Type: state.spaceTypeId,
            Space_Capacity: state.rawMinCapacity ? parseInt(state.rawMinCapacity) : undefined,
            Meta_Serv_Reqs_Catering: state.requiresCatering ? 1 : undefined,
            Meta_Serv_Reqs_AV: state.requiresAudioVisual ? 1 : undefined,
            Meta_Serv_Reqs_Presentation: state.requiresPresentationAids ? 1 : undefined,
            Meta_Serv_Reqs_Hearing: state.requiresEquipment ? 1 : undefined,
        };
        return payload;
    }

    private async loadSpaces(skipToken: string | null, pageSize: number): Promise<IBatch<ISpace>>
    {
        try
        {
            this.setState({ isLoading: true });
            const { spaces } = this.state;

            const spacesData = await appContext().ibssApiClientV2.v2.byNodeid.spaces.search.post<PagedResponse<ISpaceData[]>>({
                nodeId: this.state.selectedFloor != 0 ? this.state.selectedFloor : this.state.selectedBuildingOption,
                top: pageSize,
                skipToken: skipToken ?? undefined,
                body: this.payload,
            });

            const spacesDetails = spacesData.value.map(i =>
            {
                const space = spaces.get(i.Space_Id) ?? null;
                return {
                    id: i.Space_Id,
                    nodeId: space?.Node_Id ?? 0,
                    name: space?.Space_Name ?? '',
                    bookingPolicyId: space?.Booking_Policy_Id ?? '',
                    imageURI: space?.ImageURI ?? '',
                    spaceType: space?.Space_Type ?? '',
                    capacity: space?.Space_Capacity ?? 0,
                    hasCatering: space?.Meta_Serv_Reqs_Catering == 1,
                    hasHearing: space?.Meta_Serv_Reqs_Hearing == 1,
                    hasAv: space?.Meta_Serv_Reqs_AV == 1,
                    hasPresentationAids: space?.Meta_Serv_Reqs_Presentation == 1,
                    zone: space?.Meta_Loc_Zone ?? '',

                } as ISpace;
            });

            return { skipToken: spacesData.skipToken, items: spacesDetails };
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async handleFilterSubmit(): Promise<void>
    {
        this.pager.clearItems();
        const pageOfSpaces = await this.pager.getCurrentPage();
        this.setState({
            pageOfSpaces: pageOfSpaces,
            searchFilter: false,
        });
    }

    private async handlePreviousPageClick(): Promise<void>
    {
        return this.changePage(-1);
    }

    private async handleNextPageClick(): Promise<void>
    {
        return this.changePage(+1);
    }

    private async changePage(offset: number): Promise<void>
    {
        this.pager.pageIndex += offset;
        const pageOfSpaces = await this.pager.getCurrentPage();
        this.setState({ pageOfSpaces: pageOfSpaces });
    }

    public handleSpaceSelect(spaceId: string,nodeId: number): void
    {
        this.setState({ selectedSpaceId: spaceId, selectedNodeId: nodeId });
    }

    render(): JSX.Element
    {

        const chipMargin = '0 4px 4px 0';

        const pagingButtonStyle = (isDisabled: boolean) => ({
            border: 'none',
            backgroundColor: 'transparent',
            fontSize: '2rem',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            margin: '0 5px',
            color: isDisabled ? 'var(--ui-mid-tone)' : 'var(--ui-text-light)',
        });

        return (
            <Box>
            <IbssDrawer open={this.props.open} onClose={this.props.closeClicked} anchor='right' className='flex-search-filter-criteria' sx={{ backgroundColor: "var(--ui-background-alternate)" }}>
                <Box p={4}>
                <Box display={'flex'} justifyContent={'right'}>
                <Box className="flexMySearch-filter-criteria-close" onClick={() => this.props.closeClicked()}>&times;</Box>
                </Box>
                {this.state.searchFilter ?
                    <Box sx={{ '& > :not(style)': { my: 2 } }}>
                        <Typography variant='h5'>{this.labels.funcAdvancedBookingsRefineSearch_S}</Typography>
                        <Typography variant='body1' className='ui-text-light'>{this.labels.funcSwitchBuilding_D}</Typography>
                        <Divider sx={{my:'1'}} />
                        <Typography variant='body1' className='ui-text-light' my={2}>{this.labels.funcBookingDateTime_S}</Typography>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Box mr={1}>
                                {/* <Typography variant='body2'></Typography> */}
                                <IbssTimePicker
                                    sx={{ Width: '100%' }}
                                    value={this.state.start}
                                    onChange={time => this.startTimeChanged(time)}
                                    minutesStep={1}
                                    label={this.labels.HubLabelFrom}
                                />
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                            <img src={`/images/Sidebar_Icons/Light_theme/Vector 239.svg`} alt="Right Arrow" />
                            </Box>
                            <Box ml={1}>
                                {/* <Typography variant='body2'></Typography> */}
                                <IbssTimePicker
                                    value={this.state.end}
                                    onChange={time => this.endTimeChanged(time)}
                                    minutesStep={1}
                                    label={this.labels.HubLabelFrom}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <IbssDatePicker
                                value={this.state.date}
                                onChange={event => this.dateChanged(event)}
                                minDate={DateHelper.now()}
                                sx={{width:'100%'}}
                            />
                        </Box>
                        <Box>
                        <Divider sx={{my:'1.5rem'}} />
                        </Box>
                        <Box>
                            <Typography variant='body2' className='ui-text-light' mb={3}>{this.labels.HubButtonEditsearchcriteria}</Typography>
                        </Box>
                        <Box>
                            <IbssInputDropDown
                                id="buildingSelection"
                                inputLabel={this.labels.HubLabelBuilding}
                                fullWidth={true}
                                options={this.state.buildingOptions}
                                value={this.state.selectedBuildingOption}
                                onChange={(e: SelectChangeEvent<number>) => this.buildingChanged(e)}
                            />
                        </Box>
                        <IbssInputDropDown
                            id="buildingSelection"
                            inputLabel={this.labels.HubLabelFloor}
                            fullWidth={true}
                            options={this.state.floorTypeOptions}
                            value={this.state.selectedFloor}
                            onChange={(e: SelectChangeEvent<number>) => this.floorChanged(e as SelectChangeEvent)}
                        />

                        <IbssInputDropDown
                            id="buildingSelection"
                            inputLabel="Work type"
                            fullWidth={true}
                            options={this.state.workTypeOptions}
                            value={this.state.workTypeId}
                            onChange={(e: SelectChangeEvent<string>) => this.handleWorkTypeChange(e)}
                        />

                        <IbssInputDropDown
                            id="buildingSelection"
                            inputLabel="Space type"
                            fullWidth={true}
                            options={this.state.spaceTypeOptions}
                            value={this.state.spaceTypeId}
                            onChange={(e: SelectChangeEvent<string>) => this.handleSpaceTypeChange(e)}
                        />
                        <IbssTextField
                            id="capacitySelection"
                            label={this.labels.HubLabelCapacity}
                            fullWidth
                            type='number'
                            value={this.state.rawMinCapacity}
                            variant={'outlined'}
                            onChange={e => this.setState({ rawMinCapacity: e.target.value })}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        >
                        </IbssTextField>
                        <Box  sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: 2 }}>
                        <Box sx={{ gridColumn: 'span 6' }}>
                            <IbssSwitchLabel
                                id="cateringSelection"
                                label={
                                    <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                        {this.labels.HubLabelHasCatering}
                                    </Typography>
                                }
                                checked={this.state.requiresCatering}
                                onChange={e => this.setState({ requiresCatering: e.target.checked })}
                            />
                        </Box>
                        <Box sx={{ gridColumn: 'span 6' }}>
                            <IbssSwitchLabel
                                id="equipmentSelection"
                                label={
                                    <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                        {this.labels.funcHasAudioVisualEquipment_L}
                                    </Typography>
                                }
                                checked={this.state.requiresAudioVisual}
                                onChange={e => this.setState({ requiresAudioVisual: e.target.checked })}
                            />
                        </Box>
                        <Box sx={{ gridColumn: 'span 6' }}>
                            <IbssSwitchLabel
                                id="equipmentSelection"
                                label={
                                    <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                        {this.labels.funcHasPresentationAids_L}
                                    </Typography>
                                }
                                checked={this.state.requiresPresentationAids}
                                onChange={e => this.setState({ requiresPresentationAids: e.target.checked })}
                            />
                        </Box>
                        <Box sx={{ gridColumn: 'span 6' }}>
                            <IbssSwitchLabel
                                id="equipmentSelection"
                                label={
                                    <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
                                        {this.labels.funcHasHearingAids_L}
                                    </Typography>
                                }
                                checked={this.state.requiresEquipment}
                                onChange={e => this.setState({ requiresEquipment: e.target.checked })}
                            />
                       </Box>
                        </Box>
                        <IbssButton variant='contained' color='primary' fullWidth onClick={() => this.handleFilterSubmit()}>{this.labels.HubLabelMySearchLabel}</IbssButton>
                    </Box>
                    :
                    <>
                    <Box sx={{ '& > :not(style)': { my: 2 } }}>
                        <Typography variant='h5'>Find your new space</Typography>
                        <Typography variant='body1' className='ui-text-light'>{this.labels.funcSwitchNewSpaceBuilding_D}</Typography>
                        <Divider sx={{my:'1'}} />
                        <Typography variant='h6'>{this.labels.HubLabelSelectASpace}</Typography>
                        <Typography variant='body1' className='ui-text-light' my={2}>{this.labels.funcOriginalRoomRequirements_D}</Typography>
                    </Box>
                        {this.state.selectedFloor && <IbssChip sx={{ margin: chipMargin }} label={this.state.selectedFloor != 0 ? this.state.floorName : this.labels.HubLabelAny} />}
                        {this.state.spaceTypeId && <IbssChip sx={{ margin: chipMargin }} label={this.state.spaceTypeId} />}
                        {this.state.rawMinCapacity && <IbssChip sx={{ margin: chipMargin }} label={`${this.labels.funcMinCapacity_S}: ${this.state.rawMinCapacity}`} />}
                        {this.state.requiresCatering && <IbssChip sx={{ margin: chipMargin }} label={this.labels.funcCatering_S} />}
                        {this.state.requiresAudioVisual && <IbssChip sx={{ margin: chipMargin }} label={this.labels.funcHasAudioVisualEquipment_S} />}
                        {this.state.requiresPresentationAids && <IbssChip sx={{ margin: chipMargin }} label={this.labels.funcHasPresentationAids_S} />}
                        {this.state.requiresEquipment && <IbssChip sx={{ margin: chipMargin }} label={this.labels.funcHasHearingAids_S} />}


                        {this.state.pageOfSpaces.map((spaces, index) => (
                            <SwapSpaceCard
                                key={spaces.id}
                                space={spaces}
                                floorName={getFloorUsingFloorId(spaces.nodeId)}
                                selectedSpaceId={this.state.selectedSpaceId}
                                handleSpaceSelect={(id) => this.handleSpaceSelect(id,spaces.nodeId)}
                            />
                        ))}
                        <Box display={'flex'} justifyContent={'end'}>
                            <button
                                disabled={this.pager.isFirstPage}
                                onClick={() => this.handlePreviousPageClick()}
                                title={this.labels.HubButtonPrevious}
                                style={pagingButtonStyle(this.pager.isFirstPage)}
                            >
                                &lt;
                            </button>
                            <button
                                disabled={this.pager.isLastPage}
                                onClick={() => this.handleNextPageClick()}
                                title={this.labels.HubButtonNext}
                                style={pagingButtonStyle(this.pager.isLastPage)}
                            >
                                &gt;
                            </button>
                        </Box>
                        <Box sx={{ '& > :not(style)': { my: 1 } }}>
                            <IbssButton variant='contained' color='primary' fullWidth onClick={() => this.setState({
                                searchFilter: !this.state.searchFilter
                            })}>{this.labels.funcAdjustFilters_S}</IbssButton>
                            <IbssButton variant='contained' color='primary' fullWidth onClick={() => this.props.alternativeSpaceSubmitted(this.state.selectedSpaceId,this.state.selectedNodeId)}>Select Space</IbssButton>
                        </Box>
                    </>
                }
                </Box>
            </IbssDrawer>
            </Box>
        );
    }
}

interface IState
{
    start: DateTime;
    end: DateTime;
    date: DateTime;
    buildingOptions: Array<IListOption<number>>,
    floorTypeOptions: Array<IListOption<number>>,
    selectedBuildingOption: number,
    selectedFloor: number,
    floorName: string,
    workTypeOptions: Array<IListOption<string>>,
    workTypeId: string,
    spaceTypeOptions: Array<IListOption<string>>,
    spaceTypeId: string,
    rawMinCapacity: string,
    spaces: Map<string, Space>;
    pageOfSpaces: ISpace[];
    isLoading: boolean;
    searchFilter: boolean;
    selectedSpaceId: string;
    selectedNodeId: number;
    requiresCatering: boolean,
    requiresEquipment: boolean,
    requiresAudioVisual: boolean,
    requiresPresentationAids: boolean,
}

interface IProps 
{
    open: boolean;
    buildingId: number;
    floorId: number;
    spaceTypeId: string;
    minCapacity: number;
    lowestMinCapacity?: number;
    requiresCatering: boolean;
    requiresAudioVisual: boolean;
    requiresPresentationAids: boolean;
    requiresHearingAids: boolean;
    selectedSpaceId: string;
    availableFrom: DateTime;
    availableTo: DateTime;
    closeClicked: () => void;
    alternativeSpaceSubmitted: (spaceId: string,nodeId: number) => void;
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}

export interface IWorkType
{
    id: string;
    name: string;
}

export interface ISpaceType
{
    id: string;
    name: string;
}

export interface ISpace
{
    id: string;
    nodeId: number;
    name: string;
    bookingPolicyId: string,
    imageURI: string;
    capacity: number;
    hasCatering: boolean;
    spaceType: string;
    hasHearing: boolean;
    hasAv: boolean;
    hasPresentationAids: boolean;
    zone: string;
}


interface IProps 
{
    open: boolean;
    buildingId: number;
    floorId: number;
    spaceTypeId: string;
    minCapacity: number;
    lowestMinCapacity?: number;
    requiresCatering: boolean;
    requiresAudioVisual: boolean;
    requiresPresentationAids: boolean;
    requiresHearingAids: boolean;
    selectedSpaceId: string;
    availableFrom: DateTime;
    availableTo: DateTime;
    closeClicked: () => void;
    alternativeSpaceSubmitted: (spaceId: string,nodeId: number) => void;
}