import { DateTime } from "luxon";
import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export interface ISpaceInfo
{
    ActionURI: string,
    ImageURI: string,
    Available_From: string,
    Expiry_Date: string,
    Has_Image: number,
    Node_Id: number,
    Space_Info_Id: number,
    Space_Info_Title: string,
    Space_Info_Message: string,
    Space_Info_Short_Message: string,
}

export interface ISelectedDates
{
    Start_Time: DateTime;
    End_Time: DateTime
}

export interface IOnBehalfOfVisitor
{
    Email: string;
    First_Name: string;
    Last_Name: string;
    Display_Name: null;
    Company: string;
    Visit_Id: null;
    IsVisitor: boolean;
}

export interface IBookingPolicy 
{
    Node_Id: number;
    Booking_Policy_Id: string;
    Booking_Policy_Name: string;
    Booking_Policy: {
        PolicyType: string;
        BuildingId: string;
        FloorId: string;
        Allowed_TimeRange: string;
        Booking_Policy_Description: string;
        BookingSlots: 
        {
            ExcludedDates: 
            {
                StartDate: string;
                EndDate: string;
                StartTime: string;
                EndTime: string;
            }[];
        };
    };
}

export class BuildingBookingPolicy extends ODataSelect 
{
    public Node_Id = 0;
    public Booking_Policy_Id = "";
    public Booking_Policy_Name = "";
    public Booking_Policy: IBookingPolicyObject = 
    {  
        PolicyType: "",
        BuildingId: "",
        FloorId: "",
        Allowed_TimeRange: "",
        Booking_Policy_Description: "",
        BookingSlots: 
        {
            ExcludedDates: []
        }
    };
    
    public init(response: any, nodeId: number): BuildingBookingPolicy
    {
        super.init(response, nodeId);
        return this;
    }
}

export interface IBookingPolicyObject 
{
    PolicyType: string;
    BuildingId: string;
    FloorId: string;
    Allowed_TimeRange: string;
    Booking_Policy_Description: string;
    BookingSlots: IBookingSlots;
}

export interface IBookingSlots 
{
    ExcludedDates: IExcludedDate[];
}

export interface IExcludedDate 
{
    StartDate: string;
    EndDate: string;
    StartTime: string;
    EndTime: string;
}
