import React, { Component } from 'react'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { RouteComponentProps, RouterProps, generatePath } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { appContext } from '../../../../AppContext';
import IbssActionButton, { IActionButton } from '../../../../Components/Buttons/ActionButton/IbssActionButton';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { IDispatch, IPropsFromState } from '../../../../redux/Interfaces';
import IbssFilter from '../../../../Components/Forms/Filter/IbssFilter';
import Spinner from '../../../../Components/Navigation/LoadingSpinner/Spinner';
import { ICateringOrderPolicy } from '../../../../Providers.Api/CateringOrderPolicies/CateringOrderPoliciesRepository';
import CreateIcon from '@mui/icons-material/Create';
import { Duration } from 'luxon';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import PreparingExportDialog from '../../../../Components/Dialogs/PreparingExportDialog/PreparingExportDialog';

export default class ListCateringOrderPolicies extends IbssPage<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            loading: false,
            taskList: [],
            isCreateRight: false,
            isCancelRight: false,
            isUpdateRight: false,
            buildingId: this.appState.buildingId,
            message: "",
            selectedTaskIds: [],
            actionType: "",
            searchTerm: "",
            disableBtn: true,
            enableBtn: true,
            filterStatusOptions: [],
            showPreparingExportPopup: false
        }
    }

    public async componentDidMount(): Promise<void> 
    {
        const { history } = this.props;
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        const buildingId = parseInt(this.props.match.params["buildingid"]);
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        if (buildingId !== undefined)
        {
            await this.appState.set({ buildingId: buildingId });
            if (buildingId !== 0)
            {
                history.push("/buildings/" + this.state.buildingId + "/catering-order-policies");
            }
            else 
            {
                this.alert.show("", this.labels.HubLabelSelectBuildingError);
            }
        }

        this.setRights();
        this.loadTasks();
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState, snapshot?: IState): Promise<void> 
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            const { history } = this.props;
            const buildingID = this.state.buildingId;
            const path = generatePath(this.props.match.path, { buildingid: buildingID });
            history.replace(path);
            await this.buildingChanged();
        }
    }

    private async buildingChanged(): Promise<void>
    {
        this.setState({ loading: true });
        this.pageTitle = this.labels.HubLabelFacilityManagementText + ' ' + this.appState.buildingName;
        await this.loadTasks();
        this.setState({ loading: false });
    }

    private setRights(): void 
    {
        this.setState({
            isCreateRight: this.local.hasRight("DATAMODEL.CateringOrderPolicies.Create"),
            isCancelRight: this.local.hasRight("DATAMODEL.CateringOrderPolicies.Delete"),
            isUpdateRight: this.local.hasRight("DATAMODEL.CateringOrderPolicies.Update"),
        });
    }

    private async loadTasks(): Promise<void> 
    {
         try
        {
            this.setState({ loading: true });
            const response = await this.apiClient.cateringOrderPolicies.getMany(this.state.buildingId);
            if (!response) 
            {
                return;
            }
            const cateringPolicies = response.map((item:ICateringOrderPolicy) => ({
                id: item.OrderPolicy_Id,
                ...item,
            }));
            this.setState({ taskList: cateringPolicies, loading: false });
            this.tasksSelected(this.state.selectedTaskIds)
        }
        finally 
        {
            this.setState({ loading: false });
        }
    }

    private filterTasks(searchTerm: string): ICateringOrderPolicy[] 
    {
        const filteredTasks = this.state.taskList.filter(task =>
        {
            let key: keyof ICateringOrderPolicy;
            for (key in task)
            {

                if (task[key]?.toString().includes(searchTerm))
                {
                    return true;
                }
            }
            return false;
        });
        return filteredTasks;
    }


    private async deleteClicked(): Promise<void> 
    {
        try 
        {
            for (const id of this.state.selectedTaskIds) 
            {
                await this.apiClient.cateringOrderPolicies.delete(this.state.buildingId, String(id));
            }
            this.loadTasks();
        } catch (error) 
        {
            console.log(error);
        }
    }

    private editCateringOrderPolicy(param:ICateringOrderPolicy): React.ReactNode
    {
        if(this.state.isUpdateRight)
        {
            const { history } = this.props;
            return (
                <IbssSvgIcon onClick={() => history.push(`/buildings/${this.state.buildingId}/catering-order-policies/${param.OrderPolicy_Id}/edit`)}>
                    <CreateIcon />
                </IbssSvgIcon>
            )
        } 
        else 
        {
            return <p>-</p>;
        }
    }


    private tasksSelected(selection: GridRowSelectionModel): void
    {
        this.setState({ selectedTaskIds: selection });      
    }

    public timeConversion(minutes: number): string 
    {
        const duration = Duration.fromObject({ minutes });
        return duration.toFormat('hh:mm');
    }

    private exportClicked(): void
    {
        this.setState({ showPreparingExportPopup: true });

        appContext().ibssApiClientV2.v2.byNodeid.cateringOrderPolicy.download.post({
            nodeId: this.state.buildingId,
            body: {
                Node_Id: this.state.buildingId,
            }
        });
    }
    

    public render(): JSX.Element
    {
        const { history } = this.props;

        const dataGridHeader: GridColDef[] =
            [
                {
                    headerName: this.labels.HubLabelMenuOrderPolicy,
                    field: "Name",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelDescription,
                    field: "Description",
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelPreparationTime,
                    field: "PreparationTime_Mins",
                    renderCell: (params) => this.timeConversion(params.row.PreparationTime_Mins),
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelOperatingHours,
                    field: "Kitchen_OperatingHours_Start",
                    renderCell: (params) => 
                    <>{params.row.Kitchen_OperatingHours_Start} - {params.row.Kitchen_OperatingHours_Stop}</>,
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelAction,
                    minWidth: 120,
                    field: "",
                    flex: 1,
                    filterable: false,
                    sortable: false,
                    renderCell: (params) => <>{this.editCateringOrderPolicy(params.row)}</>
                },
            ];
            
        // Action buttons
        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonDelete,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.deleteClicked(),
                    disabled: this.state.selectedTaskIds.length > 0 && this.state.isCancelRight ? false : true
                },
                {
                    label: this.labels.HubButtonAdd,
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    disabled: !this.state.isCreateRight,
                    onClick: () => { history.push(`/buildings/${this.state.buildingId}/catering-order-policies/new`) }, 
                }
            ];

        const filteredTasks = this.filterTasks(this.state.searchTerm);

        return (
            <>
                <div className="rightPanel">
                    <PreparingExportDialog open={this.state.showPreparingExportPopup} onClose={() => this.setState({ showPreparingExportPopup: false })} />
                    {this.state.loading && <Spinner />}
                    <div className="rightPanel-main-content">
                        <div className="table-panel">
                            <Grid container rowSpacing={0} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0, pt: 1 }}>
                                <Grid item md={12} >
                                    <Box className="table-panel-header" sx={{ ml: 0 }}>{this.labels.HubMenuCateringOrderPolicy}</Box>
                                </Grid>
                                <Grid item md={12} sx={{ display: 'flex', justifyContent: 'right' }} mt={2}>
                                    <IbssFilter
                                        searchTerm={this.state.searchTerm}
                                        searchTermChanged={(event) => this.setState({ searchTerm: event.target.value })}
                                        showExport
                                        exportButtonClicked={() => this.exportClicked()}
                                    />
                                </Grid>
                            </Grid>
                            <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                <IbssActionButton
                                    buttons={actionButtons}
                                />
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <IbssDataGrid
                                    checkboxSelection
                                    columns={dataGridHeader}
                                    disableRowSelectionOnClick
                                    rows={filteredTasks}
                                    onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.tasksSelected(rowSelectionModel)}
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 25 } },
                                    }}
                                    pageSizeOptions={[25, 50, 100]}
                                />
                            </Box>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

interface IProps extends RouterProps, IPropsFromState, IDispatch
{
}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
    buildingid: string;
}

interface IState
{
    loading: boolean;
    taskList: ICateringOrderPolicy[];
    isCreateRight: boolean;
    isCancelRight: boolean;
    isUpdateRight: boolean;
    buildingId: number;
    message: string;
    selectedTaskIds: GridRowSelectionModel;
    actionType: string;
    searchTerm: string;
    disableBtn: boolean;
    enableBtn: boolean;
    filterStatusOptions: IFilterOption[];
    showPreparingExportPopup: boolean;
}

interface IFilterOption
{
    label: string;
    value: string;
}

