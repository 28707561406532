import { deprecate } from '@okta/okta-auth-js';
import _ from 'lodash';
import { Component } from 'react';
import { InputOption } from '../Components/Inputs/SelectList2/InputSelectBox';
import { appContext } from '../AppContext';
import { INode, ISearchConfigItem } from '../Providers.Api/Models';
import { DateTime } from 'luxon';
import { DateHelper } from './DateHelper';
import { IRootNode } from '../Providers.Api/Models';
import fileDownload from 'js-file-download';
import { DateView } from '@mui/x-date-pickers';

export default class Helper
{
    public static countTerms<TObject>(value: string, terms: string): number
    {
        const listOfTerms = terms.split(" ").filter(i => i != "").map(i => i.toLowerCase());
        if (listOfTerms.length == 0)
        {
            return -1;
        }

        const lowerValue = value.toLowerCase();
        const numOfTermsFound = listOfTerms.filter(term => (lowerValue.indexOf(term) != -1)).length;
        return numOfTermsFound;
    }

    public static nameOf<T>(name: keyof T): keyof T
    {
        return name;
    }

    public static handleResDataLength(component: any, res: any, emptyDataString: any)
    {
        if (res.data.length === 0)
        {
            if (component.constructor.name == 'identityProviders')
            {
                component.setState({
                    identityProviders: res.data,
                    tableMessage: emptyDataString
                });
            } else if (component.constructor.name == 'Floor' || component.constructor.name == 'Organisations' || component.constructor.name == 'Region' || component.constructor.name == 'Room' || component.constructor.name == 'Building')
            {
                component.setState({
                    spaces: res.data,
                    tableMessage: emptyDataString
                });
            } else if (component.constructor.name == 'IRoles' || component.constructor.name == 'IUsers')
            {
                component.setState({
                    providersDisplayNames: res.data,
                    tableMessage: emptyDataString
                });
            } else if (component.constructor.name == 'RolesDetails')
            {
                component.setState({
                    usersRole: res.data,
                    rolesTabTableMessage: emptyDataString
                });
            }
        } else
        {
            if (component.constructor.name == 'identityProviders')
            {
                component.setState({ identityProviders: res.data });
            } else if (component.constructor.name == 'Floor' || component.constructor.name == 'Organisations' || component.constructor.name == 'Region' || component.constructor.name == 'Room' || component.constructor.name == 'Building')
            {
                component.setState({ spaces: res.data });
            } else if (component.constructor.name == 'IRoles' || component.constructor.name == 'IUsers')
            {
                component.setState({ providersDisplayNames: res.data });
            } else if (component.constructor.name == 'RolesDetails')
            {
                component.setState({ usersRole: res.data });
            }
        }
    }

    public static handleTableSpaceError(errorCatchComponent: any, error: any, errorMessgae: any)
    {
        if (error.response)
        {
            if (error.response.statusText)
            {
                return { tableMessage: error.response.statusText }
            }
        } else
        {
            return { tableMessage: errorMessgae }
        }
    }

    public static convertApiResponseObjectToLower(data: any)
    {
        /** Convert all response Key's first latter into lowerCase */
        let newData: any = {}
        if (data && typeof data === 'object')
        {
            Object.entries(data).map(([objectKey, objectValue]) =>
            {
                newData[(objectKey.charAt(0).toLowerCase()).concat(objectKey.slice(1))] = objectValue
            })

            return newData
        }
        return newData
    }

    public static convertObjectVariableFirstLatterToUpperCase(data: any)
    {
        /** Convert object Key's first latter into upperCase */
        let newData: any = {}
        if (data && typeof data === 'object')
        {
            Object.entries(data).map(([objectKey, objectValue]) =>
            {
                newData[(objectKey.charAt(0).toUpperCase()).concat(objectKey.slice(1))] = objectValue
            })
            return newData
        }
        return newData
    }

    public static multiSort(array: any, sortObject: any)
    {
        const sortKeys = Object.keys(sortObject);

        // Return array if no sort object is supplied.
        if (!sortKeys.length)
        {
            return array;
        }

        // Change the values of the sortObject keys to -1, 0, or 1.
        for (let key in sortObject)
        {
            sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
        }

        const keySort = (a: any, b: any, direction: any) =>
        {
            direction = direction !== null ? direction : 1;

            if (a === b)
            { // If the values are the same, do not switch positions.
                return 0;
            }

            // If b > a, multiply by -1 to get the reverse direction.
            return a > b ? direction : -1 * direction;
        };

        return array.sort((a: any, b: any) =>
        {
            let sorted = 0;
            let index = 0;

            // Loop until sorted (-1 or 1) or until the sort keys have been processed.
            while (sorted === 0 && index < sortKeys.length)
            {
                const key = sortKeys[index];

                if (key)
                {
                    const direction = sortObject[key];

                    sorted = keySort(a[key], b[key], direction);
                    index++;
                }
            }

            return sorted;
        });
    }

    public static handleSortTableDataDivWrapProperyArray(array: any, column: string, direction: any)
    {
        try
        {
            const soredArray = _.orderBy(array, (item: any) => item[column].props.children.toString() ? item[column].props.children.toString().toLowerCase() : item[column].props.children, direction);
            return soredArray
        } catch (error)
        {
            return array;
        }
    }

    private static _environment: string | null;
    public static get environment(): string
    {
        if (Helper._environment == null)
        {
            const currentUrl = window.location.href;
            if (currentUrl.includes('localhost'))
            {
                Helper._environment = "dev.iconics.cloud"
            }
            else
            {
                const splitUrl = currentUrl.split("/")
                const envVariable = splitUrl[2];
                const newSplitUrl = envVariable.split(".")
                Helper._environment = `${newSplitUrl[2]}.${newSplitUrl[3]}${newSplitUrl[4] ? '.' + newSplitUrl[4] : ''}`
            }
        }
        return Helper._environment;
    }

    public static getWorkingDayArray(weekStartDay: any, weekEndDay: any)
    {
        /** FOR JS
            * Sunday = 0, Monday = 1, Tuesday = 2, Wednesday = 3, Thursday = 4, Friday = 5, Saturday = 6
            * Sunday = 7
            */
        let workingDayList: any = []

        if (weekStartDay < weekEndDay)
        {
            for (let index = weekStartDay; index <= weekEndDay; index++)
            {
                workingDayList.push(index)
            }
        } else
        {
            for (let index = 1; index <= weekEndDay; index++)
            {
                workingDayList.push(index)
            }
            for (let index = weekStartDay; index <= 7; index++)
            {
                workingDayList.push(index)
            }
        }

        workingDayList = workingDayList.map((item: any) =>
        {
            if (item === 7)
            {
                return 0
            }
            return item
        })

        return workingDayList
    }

    public static getAirStatus(val: number)
    {
        let obj: any = { 1: 'Good', 2: 'Fair', 3: 'Poor', 0: 'No Data' };
        return obj[val]
    }

    public static getAllBuildingsData(): INode[]
    {
        const local = appContext().localStorageProvider;
        let buildings: INode[] = [];
        let data = local.getNodeData();
        data.Regions.map(region =>
        {
            buildings = [...buildings, ...region.Buildings]
        });
        return buildings;
    }

    public static getFloorsFromSelectedBuilding(buildingId: number | null)
    {
        let floors: any;
        if (buildingId){
            getAllBuildingsData().map((building: any) =>
            {
                if (building.Node_Id.toString() === buildingId.toString())
                {
                    floors = building.Floors
                }
            });
        }
        return floors

    }

    public static getWorkSpaceTypesByNodeId(nodeId: number): ISearchConfigItem[]
    {
        const local = appContext().localStorageProvider;
        let workSpaceTypes: ISearchConfigItem[] = [];
        const data = local.getNodeData();
        data.Regions.forEach((eachRegion) =>
        {
            eachRegion.Buildings.forEach((eachBuilding) =>
            {
                if (nodeId === eachBuilding.Node_Id && eachBuilding?.SearchConfig?.Workspaces?.WORKTYPES != null)
                {
                    workSpaceTypes = [...workSpaceTypes, ...eachBuilding.SearchConfig.Workspaces.WORKTYPES];
                }
            });
        });
        return workSpaceTypes;
    }

    public static getSpaceTypesByNodeId(buildingId: number): { result: ISearchConfigItem[], error: boolean }
    {
        const local = appContext().localStorageProvider;
        let error = false;
        let spaceTypes: ISearchConfigItem[] = [];
        let data = local.getNodeData();
        data.Regions.map(region =>
        {
            region.Buildings.map(building =>
            {
                if (buildingId === building.Node_Id)
                {
                    if(building.SearchConfig !== undefined && building?.SearchConfig?.Workspaces?.WORKSPACES != null && building?.SearchConfig?.Amenities?.AMENITIES != null)
                    {
                        spaceTypes = [...spaceTypes, ...building.SearchConfig.Workspaces.WORKSPACES, ...building.SearchConfig.Workspaces['SUPPORT SPACES'], ...building.SearchConfig.Amenities.AMENITIES ];
                    }
                    else
                    {
                        error = true;
                    }
                }
            });
        });
        return { result: spaceTypes, error: error };
    }

    public static getFloorTypesByNodeId(nodeId: any)
    {
        const local = appContext().localStorageProvider;
        let floorTypes: any = [];
        let data = local.getNodeData();
        data.Regions.map((eachRegion: any) =>
        {
            eachRegion.Buildings.map((eachBuilding: any) =>
            {
                eachBuilding.Floors.map((eachFloor: any) =>
                {
                    if (nodeId === eachBuilding.Node_Id)
                    {
                        floorTypes = [...floorTypes, ...eachFloor.Node_Name]
                    }
                });
            });
        });
        // sort bY Node_SortName
        floorTypes.sort(function(a:any, b:any) {
            var nameA = a.Node_SortName
            var nameB = b.Node_SortName
              if (nameA < nameB) {
                   return -1;
           }
                if (nameA > nameB) {
                   return 1;
           }
               return 0; // names must be equal
            });
        return floorTypes
    }

    public static getFloorsByBuildingId(buildingId: number)
    {
        const local = appContext().localStorageProvider;
        let floorTypes: any = [];
        let data = local.getNodeData();
        data.Regions.map((eachRegion: any) =>
        {
            eachRegion.Buildings.map((eachBuilding: any) =>
            {
                if (buildingId == eachBuilding.Node_Id)
                {
                    floorTypes = [...floorTypes, ...eachBuilding.Floors]
                }
            })
        });
        // sort bY Node_SortName
        floorTypes.sort(function(a:any, b:any) {
            var nameA = a.Node_SortName
            var nameB = b.Node_SortName
              if (nameA < nameB) {
                   return -1;
           }
                if (nameA > nameB) {
                   return 1;
           }
               return 0; // names must be equal
            });
        return floorTypes
    }

    public static getZoneFromSelectedBuildingAndFloor(building: any, floor: any)
    {
        const local = appContext().localStorageProvider;
        let zones: any = [];
        let data = local.getNodeData();
        data.Regions.map((eachRegion: any) =>
        {
            eachRegion.Buildings.map((eachBuilding: any) =>
            {
                if (building == eachBuilding.Node_Id)
                {
                    eachBuilding.Floors.map((eachFloor: any) =>
                    {
                        if (floor == eachFloor.Node_Id)
                        {
                            if (eachFloor.Zones != null && eachFloor.Zones != undefined)
                            {
                                zones = [...zones, ...eachFloor.Zones]

                            }
                        }

                    });
                }
            })
        });
        return zones;
    }

    public static getBuildingNameUsingBuildingId(buildingId: any)
    {
        let buildingName = '';
        getAllBuildingsData().map((building: any) =>
        {
            if (building.Node_Id === parseInt(buildingId))
            {
                buildingName = building.Name
            }
        });
        return buildingName
    }

    public static getBuildingIdUsingBuildingName(buildingName: any)
    {
        let buildingId = null;
        getAllBuildingsData().map((building: any) =>
        {
            if (building.Name === buildingName)
            {
                buildingName = building.Node_Id
            }
        });
        return buildingId
    }

    public static getFloorsFromSelectedBuildingUsingBuildingId(buildingId: any)
    {
        let floors: any = [];
        getAllBuildingsData().map((building: any) =>
        {
            if (building.Node_Id === parseInt(buildingId))
            {
                floors = building.Floors
            }
        });
        return floors

    }

    public static getFloorNameUsingFloorAndBuildingId(buildingId: any, floorId: any)
    {

        let floor = getFloorsFromSelectedBuildingUsingBuildingId(buildingId).find((floor: any) => floor.Node_Id == floorId);
        return floor?.Node_Name
    }

    public static getWkngDaysBySelectedBuilding(buildingId: (number | null)): (IWorkingDay | null)
    {
        let wkngStrtAndStp: (IWorkingDay | null) = null;

        Helper.getAllBuildingsData().map(building =>
        {
            if (building.Node_Id === buildingId)
            {
                wkngStrtAndStp = {
                    Occ_Wkng_Days_Stt: building.Occ_Wkng_Days_Stt,
                    Occ_Wkng_Days_Stp: building.Occ_Wkng_Days_Stp,
                    Occ_Wkng_Hrs_Stt: building.Occ_Wkng_Hrs_Stt,
                    Occ_Wkng_Hrs_Stp: building.Occ_Wkng_Hrs_Stp,
                    search_config: building.SearchConfig.Config.PageWhenDefaultTime
                }
            }
        });
        return wkngStrtAndStp;
    }

    public static makeOptions(data: any, label: string, value: string)
    {
        let options: any = [];
        if (data?.length)
            data.map((obj: any) =>
            {
                options.push({ label: obj[label], value: obj[value] })
            });
        return options
    }

    public static getBuildingOptionsForDropdown()
    {
        return makeOptions(getAllBuildingsData(), 'Name', 'Node_Id')
    }

    public static  startDate() {
        const now = DateTime.utc().startOf('day');
        return now.toISO();
      }

    public static getBuildingNodeIdUsingFloorNodeId(nodeId: any)
    {
        let buildingNodeId = '';
        getAllBuildingsData().map((eachBuilding: any) =>
        {
            eachBuilding?.Floors?.map((eachFloor: any) =>
            {
                if (nodeId == eachFloor.Node_Id)
                {
                    buildingNodeId = eachBuilding.Node_Id
                }

            });
        });
        return buildingNodeId
    }

    public static getBuildingNameUsingFloorNodeId(nodeId: any)
    {
        let buildingName = '';
        getAllBuildingsData().map((eachBuilding: any) =>
        {
            eachBuilding?.Floors?.map((eachFloor: any) =>
            {
                if (nodeId === eachFloor.Node_Id)
                {
                    buildingName = eachBuilding.Name
                }

            });
        });
        return buildingName
    }

    public static getBuildingIdUsingFloorNodeId(nodeId: any)
    {
        let buildingId = '';
        getAllBuildingsData().map((eachBuilding: any) =>
        {
            eachBuilding.Floors?.map((eachFloor: any) =>
            {
                if (nodeId === eachFloor.Node_Id)
                {
                    buildingId = eachBuilding.Node_Id
                }

            });
        });
        return buildingId
    }

    public static setState<S, K extends keyof S, P>(component: Component<any, S, P>, state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null)): Promise<void>
    {
        return new Promise((resolve) =>
        {
            component.setState(state, resolve);
        });
    }

    // temporarly created function for getting environment, needed to discuss with milesh or Adam about usung environment in a better way
    public static getCurrentHostEnvironment()
    {
        let activeurl = window.location.href;
        let breakUrl = activeurl.split("/")
        let re = breakUrl[2];
        breakUrl = re.split(".")
        re = breakUrl[0];

        let host = window.location.hostname.replace(re, '');
        return host;
    }

    public static getFloorUsingFloorId(floorId: any){
        const local = appContext().localStorageProvider;
        const NodeData = local.getNodeData();
        
        let buildingArray: any = [];
        NodeData.Regions.forEach((item:any) => {
            const building = [...item.Buildings];
            buildingArray = [...buildingArray, ...building];
            
        });

        let FloorArray: any = []
        buildingArray.forEach((item: any) => {
            if (item.Floors !== null) {
                const floor = [...item.Floors];
                FloorArray = [...FloorArray, ...floor];
            }
        });

        const floorName = FloorArray.filter((item: any) => item.Node_Id === floorId);

        return floorName[0].Node_Name;
    }

    public static makeDropdownOptions(data: Array<any>, labelField: string, valueField: string): Array<InputOption>
    {
        let options = new Array<InputOption>();
        // options.push(new InputOption("...", ""));

        if (data)
        {
            data.map(i =>
            {
                options.push(new InputOption(i[labelField], i[valueField]));
            });
        }

        return options;
    }

    public static convertPropertyNamesToCamelCase(obj: object) {
        if(obj != null && obj != undefined){
            const updatedObj = Object.assign({}, ...Object.entries(obj).map(
                ([k, v]) => ({ [(k)[0].toLocaleLowerCase() + (k.slice(1, k.length))]: v })
            ))
            return updatedObj;
        } else {
            return obj;
        }
        

    }

    public getBuildingById(rootNode: IRootNode, buildingId: number): (INode | undefined)
    {
        const building = rootNode.Regions.flatMap(i => i.Buildings).find(i => i.Node_Id === buildingId);
        return building;
    }

    public static getStorageUrl(): string
    {
        return `https://storage.ibss.${Helper.environment}`;
    }

    public static downloadFile(file: Blob | null, outputFilename: string): void
    {
        if (file == null)
        {
            return;
        }

        const url = window.URL.createObjectURL(file);
        const exportFile = document.createElement('a');
        exportFile.href = url;
        exportFile.download = outputFilename;
        exportFile.click();
    }
}

/** @deprecated */
export function getBuildingTimeZoneByNodeId(nodeId: any): string
{
    return DateHelper.getZoneByNode(nodeId);
}

export function donutColor(color: number){
    let colorCode;
    if (color >= 0 && color <= 10) {
      colorCode = "#F44336";
    } else if (color >= 11 && color <= 75) {
      colorCode = "#FFC400";
    } else if (color >= 76 && color <= 100) {
      colorCode = "#00C853";
    }
    return colorCode;
  };

/** @deprecated */
export function handleResDataLength(component: any, res: any, emptyDataString: any)
{
    return Helper.handleResDataLength(component, res, emptyDataString);
}

/** @deprecated */
export function handleTableSpaceError(errorCatchComponent: any, error: any, errorMessgae: any)
{
    return Helper.handleTableSpaceError(errorCatchComponent, error, errorMessgae);
}

/** @deprecated */
export const convertApiResponseObjectToLower = (data: any) =>
{
    return Helper.convertApiResponseObjectToLower(data);
}

/** @deprecated */
export const convertObjectVariableFirstLatterToUpperCase = (data: any) =>
{
    return Helper.convertObjectVariableFirstLatterToUpperCase(data);
}

/** @deprecated */
export function multiSort(array: any, sortObject: any)
{
    return Helper.multiSort(array, sortObject);
}

/** @deprecated */
export const handleSortTableDataDivWrapProperyArray = (array: any, column: string, direction: any) =>
{
    return Helper.handleSortTableDataDivWrapProperyArray(array, column, direction);
}

/** @deprecated */
export const getWorkingDayArray = (weekStartDay: any, weekEndDay: any) =>
{
    return Helper.getWorkingDayArray(weekStartDay, weekEndDay);
}

/** @deprecated */
export const getAirStatus = (val: number) =>
{
    return Helper.getAirStatus(val);
}

/** @deprecated */
export const getAllBuildingsData = () =>
{
    return Helper.getAllBuildingsData();
}

/** @deprecated */
export const getFloorsFromSelectedBuilding = (buildingId: number) =>
{
    return Helper.getFloorsFromSelectedBuilding(buildingId);
}

/** @deprecated */
export const getWorkSpaceTypesByNodeId = (nodeId: any) =>
{
    return Helper.getWorkSpaceTypesByNodeId(nodeId);
}

/** @deprecated */
export const getFloorTypesByNodeId = (nodeId: any) =>
{
    return Helper.getFloorTypesByNodeId(nodeId);
}

/** @deprecated */
export const getZoneFromSelectedBuildingAndFloor = (building: any, floor: any) =>
{
    return Helper.getZoneFromSelectedBuildingAndFloor(building, floor);
}

/** @deprecated */
export const getBuildingNameUsingBuildingId = (buildingId: any) =>
{
    return Helper.getBuildingNameUsingBuildingId(buildingId);
}

/** @deprecated */
export const getBuildingIdUsingBuildingName = (buildingName: any) =>
{
    return Helper.getBuildingIdUsingBuildingName(buildingName);
}

/** @deprecated */
export const getFloorsFromSelectedBuildingUsingBuildingId = (buildingId: any) =>
{
    return Helper.getFloorsFromSelectedBuildingUsingBuildingId(buildingId);
}

/** @deprecated */
export const getFloorNameUsingFloorAndBuildingId = (buildingId: any, floorId: any) =>
{
    return Helper.getFloorNameUsingFloorAndBuildingId(buildingId, floorId);
}

/** @deprecated */
export const getWkngDaysBySelectedBuilding = (buildingId: any) =>
{
    return Helper.getWkngDaysBySelectedBuilding(buildingId);
}

/** @deprecated */
export const makeOptions = (data: any, label: string, value: string) =>
{
    return Helper.makeOptions(data, label, value);
}

/** @deprecated */
export const getBuildingOptionsForDropdown = () =>
{
    return Helper.getBuildingOptionsForDropdown();
}

/** @deprecated */
export const startDate = () =>
{
    return Helper.startDate();
}

/** @deprecated */
export const getBuildingNameUsingFloorNodeId = (nodeId: any) =>
{
    return Helper.getBuildingNameUsingFloorNodeId(nodeId);
}

export const getBuildingNodeIdUsingFloorNodeId = (nodeId: any) =>
{
    return Helper.getBuildingNodeIdUsingFloorNodeId(nodeId);
}
/** @deprecated */
export const getBuildingIdUsingFloorNodeId = (nodeId: any) =>
{
    return Helper.getBuildingIdUsingFloorNodeId(nodeId);
}

export const getFloorUsingFloorId = (floorId: any) => 
{
    return Helper.getFloorUsingFloorId(floorId);
}

 /*  Space Analytics Start*/

 export const dateConverter=(str: string | number | Date)=>{
    const date = new Date(str);
    const mnth = ("0" + (date.getMonth()+1)).slice(-2);
    const day  = ("0" + date.getDate()).slice(-2);
    const hours  = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const year = date.getFullYear();
   
    return `${year}-${mnth}-${day}T${hours}:${minutes}`
 }
 
 /* SpaceUtilization API parameter call condition */
 export const classSapceCall = (space: string) => {
    let classSpace = "Occ_Work_Space_KPI_Target";
    
    if (space === "Support") {
      classSpace = "Occ_Support_Space_KPI_Target";
    }
    if (space === "Amenity") {
      classSpace = "Occ_Amenity_Space_KPI_Target";
    }
    return classSpace;
  };

 export const truncateString = (str:string, maxLength:number) : string => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.substring(0, maxLength) + '...';
  };

   /* Dashboard API parameter for 'Period Type' call condition */
 export const periodTypeCall = (period: string)=>{
    let PeriodType = 1;
     if (period === "Week") {
      PeriodType = 0;
    }
    if (period === "Day") {
      PeriodType = 3;
    }
    if (period === "Year") {
      PeriodType = 2;
    }
    return PeriodType;
  }

 /* CapacityUtilization API parameter call condition */
  export const classCapacityCall = (parameter: string)=>{
    let classCapacity = "Occ_Work_Capacity_KPI_Target";
     if (parameter === "Support") {
      classCapacity = "Occ_Support_Capacity_KPI_Target";
    }
    if (parameter === "Amenity") {
      classCapacity = "Occ_Amenity_Capacity_KPI_Target";
    }
    return classCapacity;
  }

/* SpaceAnalytics Header section call */
export const headerSelectionCall = (nodeLevelName: string, buildingFloor: string, buildingOptions: string, buildingWorkSpaceType: string, buildingClassType: any, tableLength: number) =>{
    let space = null;
    if((nodeLevelName === "Floor" && buildingFloor === "Any" && buildingOptions !== "All") ||
    (buildingFloor !== "Any" && buildingWorkSpaceType !== "Total" && nodeLevelName === "Floor") ||
    (buildingFloor !== "Any" && buildingWorkSpaceType === "Total" && nodeLevelName === "Floor")){
      space = `Floor${tableLength > 1 ? "s" : ""}`;
    }else if((buildingOptions === "All" && buildingWorkSpaceType === "Total" && nodeLevelName === "Building" && buildingFloor ==="Any")){
      space = `Building${tableLength > 1 ? "s" : ""}`;
    }else if((nodeLevelName === "Building")){
      space = `${buildingClassType} Space${tableLength > 1 ? "s" : ""}`;
    }else if((buildingWorkSpaceType === "Total" && buildingOptions !== "All" && nodeLevelName !== "Floor") ||
    (buildingWorkSpaceType === "Total" && buildingOptions !== "All" && nodeLevelName === "Space") ||
    (buildingFloor === "Any" && buildingWorkSpaceType !== "Total" && nodeLevelName === "Space") ||
    (buildingFloor !== "Any" && buildingWorkSpaceType !== "Total" && nodeLevelName === "Space") ){
      space = `Space${tableLength > 1 ? "s" : ""}`;
    }
    return space
  }

/* Space Analytics headerImage */
 export const headerImage = (nodeLevelName: string, buildingFloor: string, buildingOptions: string, buildingWorkSpaceType: string)=>{
    let img = null;
    if((nodeLevelName === "Floor" && buildingFloor === "Any" && buildingOptions !== "All") ||
    (buildingFloor !== "Any" && buildingWorkSpaceType !== "Total" && nodeLevelName === "Floor") ||
    (buildingFloor !=="Any" && buildingWorkSpaceType === "Total" && nodeLevelName === "Floor")
    ){
      img = "Floor";
    }else if((buildingWorkSpaceType === "Total" && buildingOptions !== "All" && nodeLevelName !== "Floor") ){
      img = "workspace";
    }else if(buildingOptions === "All" && buildingWorkSpaceType === "Total" && nodeLevelName === "Building" && buildingFloor === "Any"){
      img = "Building";
    }else if((buildingWorkSpaceType === "Total" && buildingOptions !== "All" && nodeLevelName === "Space") ||
    (buildingFloor === "Any" && buildingWorkSpaceType !== "Total" && nodeLevelName === "Space") ||
    (buildingFloor !== "Any" && buildingWorkSpaceType !== "Total" && nodeLevelName === "Space") ||
    (buildingWorkSpaceType !== "Total" && nodeLevelName === "Building")
    ){
      img = buildingWorkSpaceType
    }
    return img;
  }

  /* Header Space Utilization */
  export const headerSpace_CapaUtiCompare = (currentSpace: number, buildingPeriodType: string, similarType: string)=>{
    let para = null;
    if(currentSpace > 5){
      para = `Higher than last ${buildingPeriodType}`
    }else if( currentSpace < -5){
      para = `Lower than last ${buildingPeriodType}`;
    }else{
      para = similarType;
    }
    return para;
  }

  /*  Header Space Capacity KPI Difference */
   export const headerSpaceCapaKpiDiff = (para: number)=>{
      let diff = null;
      if(para > 0){
        diff = "Down";
      }else if(para < 0){
        diff = "Up";
      }else{
        diff = "Equal"
      }
      return diff;
    }
  
  /*  Comparision arrow */
   export const comparisionArrow = (para: number, upArrow: string, down: string, rightArrow: string)=>{
      let arrow = null;
      if(para > 0){
        arrow = down;
      }else if(para < 0){
        arrow = upArrow;
      }else{
        arrow = rightArrow;
      }
      return arrow;
    }

/* Analytics row image call */
 export const imgCall =(spaceType: string,buildingid: number,filterCriteria: string,NodeLevelName: string)=>{
    let img = "";
    if(spaceType === "Total" &&
    buildingid === 1){
      img = "Building";
    }else if(filterCriteria === "Total" &&
    NodeLevelName === "Building"){
      img = "workspace";
    }else if(NodeLevelName === "Floor"){
      img = "Floor";
    }else{
      img = spaceType;
    }
    return img;
  }

  /* space_type_label empty condition for name render */
 export const spaceName = (Space_Name: string, Space_Type_Label: string, onelensDateBuilding: string, buildingNodeId: number)=>{
    let space = "";
    if((Space_Type_Label !== "Total") && onelensDateBuilding === "Building" && buildingNodeId !== 1){
      space = Space_Type_Label
    }else if(( Space_Name !== "Total") ){
      space = Space_Name
    }else if(Space_Name === "Total" && onelensDateBuilding === "Space"){
      space = Space_Name
    }else{
      space = Space_Type_Label
    }
    return space;
  }

   /*Filter calendar according to type day, month, year*/
  export const getCalendarView = (selectedPeriodType: number) => {
    let view: DateView = 'month';
    if (selectedPeriodType === 1) {
      view = view;
    } else if (selectedPeriodType === 2) {
      view = "year";
    } else if (selectedPeriodType === 3) {
      view = "day"
    } else {
      view = "day"
    }
    return view;
  };
  /*  Space Analytics End*/

  /* Day, Week, Month, Year  */
  export const periodType = [
    { Name: 'Day', Value: 3 },
    { Name: 'Week', Value: 0 },
    { Name: 'Month', Value: 1 },
    { Name: 'Year', Value: 2 },
  ];

/*getperiodtype string*/
  export const getPeriodTypeString = (periodType: number) => {

    let view: string = 'Month';
    
    if (periodType === 1) {
      view = view;
    } else if (periodType === 2) {
      view = "Year";
    } else if (periodType === 3) {
      view = "Day"
    } else {
      view = "Week"
    }
    return view;
  };

  /* Get current week monday */
  export const getMondayOfCurrentWeek = () => {
    const today = DateTime.local();
    const monday = today.startOf('week');
    const lastday = monday.plus({ days: 6 });
    return {monday, lastday}
  };

  /* Space difference between current and target value */
  export const spaceDiff = (current: number,target: number | undefined)=>{
    let difference = 0;
    if(target){
      difference = target - current;
    }
    return Math.round(difference);
  }

export interface IWorkingDay
{
    Occ_Wkng_Days_Stt: string,
    Occ_Wkng_Days_Stp: string,
    Occ_Wkng_Hrs_Stt: string,
    Occ_Wkng_Hrs_Stp: string,
    search_config: string,
}

