import { MenuItemKey } from "../../Layout/Sidebar/MenuItemKey";
import ListRoles from "../../../Pages/Admin/Roles/List/ListRoles";
import ListUsers from "../../../Pages/Admin/Users/List/ListUsers";
import ListIdentityProviders from "../../../Pages/Admin/IdentityProviders/List/ListIdentityProviders";
import EditIdentityProvider from "../../../Pages/Admin/IdentityProviders/Edit/EditIdentityProvider";
import EditRole from "../../../Pages/Admin/Roles/Edit/EditRole";
import ListOrganisations from "../../../Pages/Admin/Organisations/List/ListOrganisations";
import ListRegions from "../../../Pages/Admin/Regions/List/ListRegions";
import ListSpaces2 from "../../../Pages/Admin/Spaces/List2/ListSpaces2";
import ListZones from "../../../Pages/Admin/Zones/List/ListZones";
import ListFloors from "../../../Pages/Admin/Floors/List/ListFloors";
import AdminListBuildings from "../../../Pages/Admin/Buildings/List/ListBuildings";
import EditSpace from "../../../Pages/Admin/Spaces/Edit/EditSpace";
import EditTaskCategory from "../../../Pages/Admin/TaskCategories/Edit/EditTaskCategory";
import EditTaskType from "../../../Pages/Admin/TaskTypes/Edit/EditTaskType";
import EditTaskState from "../../../Pages/Admin/TaskStates/Edit/EditTaskState";
import EditSpaceState from "../../../Pages/Admin/SpaceStates/Edit/EditSpaceState";
import EditUser from "../../../Pages/Admin/Users/Edit/EditUser";
import EditDevice from "../../../Pages/Admin/Devices/Edit/EditDevice";
import EditParameter from "../../../Pages/Admin/Parameters/Edit/EditParameter";
import EditBuildingKpis from "../../../Pages/Admin/BuildingKpis/Edit/EditBuildingKpis";
import EditSignage from "../../../Pages/Admin/Signages/Edit/EditSignage";
import ListFiles from "../../../Pages/Admin/Files/List/ListFiles";
import AdminListNotifications from "../../../Pages/Admin/Notifications/List/ListNotifications";
import EditNotification from "../../../Pages/Admin/Notifications/Edit/EditNotification";
import EditBookingPolicy from "../../../Pages/Admin/BookingPolicies/Edit/EditBookingPolicy";
import ListBookingPolicies from "../../../Pages/Admin/BookingPolicies/List/ListBookingPolicies";
import AssignBookingPolicyToSpace from "../../../Pages/Admin/BookingPolicies/AssignToSpace/AssignBookingPolicyToSpace";
import ListSpaces from "../../../Pages/Admin/Spaces/List/ListSpaces";
import EditAdvancedSpace from "../../../Pages/Admin/Spaces/EditAdvanced/EditAdvancedSpace.tsx";
import AdminCreateManyDelegates from "../../../Pages/Admin/Delegates/CreateMany/CreateManyDelegates";
import EditTags from "../../../Pages/Admin/Tags/Edit/EditTags";
import SystemFilters from "../../../Pages/Admin/SystemFilters/SystemFilters";
import ListNotificationReminders from "../../../Pages/Admin/NotificationReminders/List/ListNotificationReminders";
import EditNotificationReminders from "../../../Pages/Admin/NotificationReminders/Edit/EditNotificationReminders";
import ListCostCodes from "../../../Pages/Shared/CostCodes/List/ListCostCodes";
import { IRoute } from "./IbssRouter";

export default class AdminRoutes
{
    public routes: IRoute[] = [
        {
            requiredRights: [
                "ADMINPORTAL.Security.IdentityProviders",
                "ADMINPORTAL.Security.*"
            ],
            path: "/security-identity-providers",
            component: ListIdentityProviders,
            menuItemId: MenuItemKey.Admin_Setup_Security_IdentityProviders
        },
        {
            requiredRights: [
                "ADMINPORTAL.Security.IdentityProviders",
                "ADMINPORTAL.Security.*"
            ],
            path: "/security-identity-providers/details",
            component: EditIdentityProvider,
            menuItemId: MenuItemKey.Admin_Setup_Security_IdentityProviders
        },
        {
            requiredRights: [
                "ADMINPORTAL.Security.IdentityProviders",
                "ADMINPORTAL.Security.*"
            ],
            path: "/security-identity-providers/details/:id",
            component: EditIdentityProvider,
            menuItemId: MenuItemKey.Admin_Setup_Security_IdentityProviders
        },
        {
            requiredRights: [
                "ADMINPORTAL.Security.Users",
                "ADMINPORTAL.Security.*"
            ],
            path: "/security-users/userdetails",
            component: EditUser,
            menuItemId: MenuItemKey.Admin_Manage_Users
        },
        {
            requiredRights: [
                "ADMINPORTAL.Security.Roles",
                "ADMINPORTAL.Security.*"
            ],
            path: "/security-roles/rolesdetails",
            component: EditRole,
            menuItemId: MenuItemKey.Admin_Setup_Security_Roles
        },
        {
            requiredRights: [
                "ADMINPORTAL.Security.Users",
                "ADMINPORTAL.Security.*"
            ],
            path: "/security-users/userdetails/:id",
            component: EditUser,
            menuItemId: MenuItemKey.Admin_Manage_Users
        },
        {
            requiredRights: [
                "ADMINPORTAL.Security.Roles",
                "ADMINPORTAL.Security.*"
            ],
            path: "/security-roles/rolesdetails/:id",
            component: EditRole,
            menuItemId: MenuItemKey.Admin_Setup_Security_Roles
        },
        {
            requiredRights: [
                "ADMINPORTAL.Security.Roles",
                "ADMINPORTAL.Security.*"
            ],
            path: "/security-roles",
            component: ListRoles,
            menuItemId: MenuItemKey.Admin_Setup_Security_Roles
        },
        {
            requiredRights: [
                "ADMINPORTAL.Security.Delegates",
                "ADMINPORTAL.Security.*"
            ],
            path: "/admin/delegates/create",
            component: AdminCreateManyDelegates,
            menuItemId: MenuItemKey.Admin_Setup_Security_DelegatePermissions
        },
        {
            requiredRights: [
                "ADMINPORTAL.Security.Users",
                "ADMINPORTAL.Security.*"
            ],
            path: "/security-users",
            component: ListUsers,
            menuItemId: MenuItemKey.Admin_Manage_Users
        },
        {
            requiredRights: [
                "ADMINPORTAL.CostCodes.Read",
                "API.CostCodes.V2"
            ],
            path: "/cost-codes/",
            component: ListCostCodes,
            menuItemId: MenuItemKey.Admin_Manage_CostCodes
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=Root",
            component: ListOrganisations,
            menuItemId: MenuItemKey.Admin_Setup_Organisation
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=Region",
            component: ListRegions,
            menuItemId: MenuItemKey.Admin_Setup_Organisation_Regions
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=Building",
            component: AdminListBuildings,
            menuItemId: MenuItemKey.Admin_Setup_Organisation_Buildings
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=Floor",
            component: ListFloors,
            menuItemId: MenuItemKey.NotSet
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=Zone",
            component: ListZones,
            menuItemId: MenuItemKey.NotSet
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=Space",
            component: ListSpaces2,
            menuItemId: MenuItemKey.NotSet
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=Taskcategories/:id",
            component: EditTaskCategory
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.TaskCategories",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/spaces-task-categories/filter=Taskcategories/:id",
            component: EditTaskCategory
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.TaskTypes",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/spaces-task-types/filter=TaskTypes/:id",
            component: EditTaskType
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=TaskTypes/:id",
            component: EditTaskType
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.TaskStates",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/spaces-task-types/filter=TaskStates/:id",
            component: EditTaskState
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=TaskStates/:id",
            component: EditTaskState
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/edit/:id/:filter(Region)",
            component: EditSpace,
            menuItemId: MenuItemKey.Admin_Setup_Organisation_Regions
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/edit/:id/:filter(Region)/:tab",
            component: EditSpace,
            menuItemId: MenuItemKey.Admin_Setup_Organisation_Regions
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/edit/:id/:filter(Building)",
            component: EditSpace,
            menuItemId: MenuItemKey.Admin_Setup_Organisation_Buildings
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/edit/:id/:filter(Building)/:tab",
            component: EditSpace,
            menuItemId: MenuItemKey.Admin_Setup_Organisation_Buildings
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.TaskStates",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/spaces-task-types/filter=TaskStates/nodeid=:nodeid/id=:id",
            component: EditTaskState
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=TaskStates/nodeid=:nodeid/id=:id",
            component: EditTaskState
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.TaskTypes",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/spaces-task-types/filter=TaskTypes/nodeid=:nodeid/id=:id",
            component: EditTaskType
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=Building/TaskTypes/nodeid=:nodeid/id=:id",
            component: EditTaskType
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.SpaceStates",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/spaces-task-types/filter=SpaceStates/nodeid=:nodeid/id=:id",
            component: EditSpaceState
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=SpaceStates/nodeid=:nodeid/id=:id",
            component: EditSpaceState
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.TaskCategories",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/spaces-task-categories/filter=TaskCategories/nodeid=:nodeid/id=:id",
            component: EditTaskCategory
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=TaskCategories/nodeid=:nodeid/id=:id",
            component: EditTaskCategory
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=:filter/Device/nodeid=:nodeid/id=:id",
            component: EditDevice
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=:filter/Parameter/nodeid=:nodeid/id=:id",
            component: EditParameter
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=:filter/BuildingKPI/nodeid=:nodeid/id=:id",
            component: EditBuildingKpis
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/portfolio-setup-spaces/filter=:filter/Signage/nodeid=:nodeid/id=:id",
            component: EditSignage
        },
        {
            requiredRights: [
                "DATAMODEL.BookingPolicies.Read"
            ],
            path: "/booking-policies/:buildingid",
            component: ListBookingPolicies,
            menuItemId: MenuItemKey.Admin_Manage_BookingPolicies
        },
        {
            requiredRights: [
                "DATAMODEL.BookingPolicies.Read"
            ],
            path: "/booking-policies/assign/:bookingPolicyId",
            component: AssignBookingPolicyToSpace,
            menuItemId: MenuItemKey.Admin_Manage_BookingPolicies
        },
        {
            requiredRights: [
                "DATAMODEL.BookingPolicies.Read"
            ],
            path: "/booking-policies/:buildingId/create",
            component: EditBookingPolicy,
            menuItemId: MenuItemKey.Admin_Manage_BookingPolicies
        },
        {
            requiredRights: [
                "DATAMODEL.BookingPolicies.Read"
            ],
            path: "/booking-policies/edit/:bookingId",
            component: EditBookingPolicy,
            menuItemId: MenuItemKey.Admin_Manage_BookingPolicies
        },
        {
            requiredRights: [
                "DATAMODEL.Filters.Read"
            ],
            path: "/admin/system-filters/buildingId=:buildingid",
            component: SystemFilters,
            menuItemId: MenuItemKey.Admin_Manage_SystemFilters
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/admin/buildings/:buildingid/spaces",
            component: ListSpaces,
            menuItemId: MenuItemKey.Admin_Manage_Spaces
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/admin/buildings/:floorid/spaces/:spaceid/edit",
            component: EditAdvancedSpace,
            menuItemId: MenuItemKey.Admin_Manage_Spaces
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Spaces",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/admin/buildings/spaces/new",
            component: EditAdvancedSpace,
            menuItemId: MenuItemKey.Admin_Manage_Spaces
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.TagDefinitions",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/admin/tags/new",
            component: EditTags,
            menuItemId: MenuItemKey.Admin_Manage_Tags
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Notifications",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/notifications",
            component: AdminListNotifications,
            menuItemId: MenuItemKey.Admin_Setup_Organisation_Notifications
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioSetup.Notifications",
                "ADMINPORTAL.PortfolioSetup.*"
            ],
            path: "/notifications/:notificationid",
            component: EditNotification,
            menuItemId: MenuItemKey.Admin_Setup_Organisation_Notifications
        },
        {
            requiredRights: [
                "ADMINPORTAL.Files.Roamer",
                "ADMINPORTAL.Files.*"
            ],
            path: "/filemgmt/roamer",
            component: ListFiles,
            menuItemId: MenuItemKey.Admin_Manage_FileManagement_Roamer
        },
        {
            requiredRights: [
                "ADMINPORTAL.Files.Unity",
                "ADMINPORTAL.Files.*"
            ],
            path: "/filemgmt/unity",
            component: ListFiles,
            menuItemId: MenuItemKey.Admin_Manage_FileManagement_Unity
        },
        {
            requiredRights: [
                "ADMINPORTAL.Files.Inspire",
                "ADMINPORTAL.Files.*"
            ],
            path: "/filemgmt/inspire",
            component: ListFiles,
            menuItemId: MenuItemKey.Admin_Manage_FileManagement_Inspire
        },
        {
            requiredRights: [
                "ADMINPORTAL.Files.Common",
                "ADMINPORTAL.Files.*"
            ],
            path: "/filemgmt/common",
            component: ListFiles,
            menuItemId: MenuItemKey.Admin_Manage_FileManagement_Common
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioManagement.NotificationReminders"
            ],
            path: "/admin/notification-reminders/:buildingid",
            component: ListNotificationReminders,
            menuItemId: MenuItemKey.Admin_Manage_NotificationRecuring
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioManagement.NotificationReminders"
            ],
            path: "/admin/notification-reminders/:buildingid/:reminderid/edit",
            component: EditNotificationReminders,
            menuItemId: MenuItemKey.Admin_Manage_NotificationRecuring
        },
        {
            requiredRights: [
                "ADMINPORTAL.PortfolioManagement.NotificationReminders"
            ],
            path: "/admin/notification-reminders/:buildingid/new",
            component: EditNotificationReminders,
            menuItemId: MenuItemKey.Admin_Manage_NotificationRecuring
        },
    ];
}
