import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import "./spaces-search.scss"
import { connect } from "react-redux";
import Helper from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import SearchSpaceCriteria, { IProps as ISearchCriteriaProps, ISearchCriteriaResult } from "./SearchSpaceCriteria";
import { RouteComponentProps, generatePath, withRouter } from "react-router-dom";
import momentBusiness from 'moment-business-days';
import MapButton from "../../../../Components/Buttons/MapButton/MapButton";
import ListButton from "../../../../Components/Buttons/ListButton/ListButton";
import FloorPlan, { IFloorPlanSpace as IFloorPlanSpace, IMapUrl, IPagedFloorPlanSpaces } from "../../../../Components/Data/FloorPlan/FloorPlan";
import { RouterProps } from "react-router-dom";
import { appContext } from "../../../../AppContext";
import SpaceCard, { Props as SpaceCardProps } from "../../../../Components/Cards/SpaceCard/SpaceCard";
import Alert from "../../../../Components/Miscellaneous/Alert/Alert";
import { IPropsFromState } from "../../../../redux/Interfaces";
import { DateTime } from "luxon";
import { DateHelper } from "../../../../Common/DateHelper";
import { Box, Grid } from "@mui/material";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import React from "react";
import Guid from "../../../../Common/Guid";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { IFloor, ISearchConfigItem, PagedResponse } from "../../../../Providers.Api/Models";
import { AvailableSpace } from "./DataModels";
import { QueryParams } from "./QueryParams";
import { SearchSpacesHelper } from "./SearchSpacesHelper";
import { ISearchRecurring } from "../../../../Providers.IbssApiClientV2/IbssApiClientV2";
import SearchSpaceFilter from "./SearchSpaceFilter";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";

class SearchSpaces extends IbssPage<IProps, IState, QueryParams>
{
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; };
    private get apiCache() { return appContext().apiCache };
    private get isOneLens() { return this.area == 'onelens'; }
    private cachedFloors: IFloor[] = [];
    private cachedSpaces: Space[] = [];

    constructor(props: IProps)
    {
        super(props, new QueryParams());
        this.cachedFloors = this.local.getNodeData().Regions.flatMap(region => region.Buildings).flatMap(building => building.Floors);
        const mapUrls = this.cachedFloors.map(i => ({ floorId: i.Node_Id, url: i.Floor_MapURI }));

        this.state =
        {
            isLoading: false,
            spaces: null,
            openDrawer: false,
            searchData: ["building", "workType", "spaceType", "floor", "zone", "date", "startTime", "endTime"],
            searchCriteria: [],
            view: View.List,
            mapUrls: mapUrls,
            loadMap: Guid.empty,
            mapFailedToLoad: false,

            // search criteria
            buildingId: -1,
            workspaceType: null,
            spaceType: null,
            spaceTypeLabel: null,
            floorId: null,
            zone: null,
            startTime: DateHelper.null(),
            endTime: DateHelper.null(),
            audioVisual: false,
            presentationAids: false,
            hearingAids: false,
            catering: false,
            linkedSpace: false,
            layouts: false,
            numberOfPeople: null,
            selected: '',
        }
    }

    public async queryParamsDidUpdate(firstLoad: boolean, prevQueryParams: QueryParams): Promise<void>
    {
        this.pageTitle = (this.isOneLens ? `${this.labels.HubLabelFacilityManagementText} ${this.appState.buildingName}` : this.labels.HubMenuSearchaSpace);
        const queryParams = this.queryParams;
        if (!firstLoad && queryParams.building != prevQueryParams.building)
        {
            this.props.history.replace(`/${this.isOneLens ? 'one-lens' : 'flex'}/spaces/search?${SearchSpacesHelper.buildQuery(this.queryParams.building)}`);
            return;
        }

        this.pageTitle = (this.isOneLens ? `${this.labels.HubLabelFacilityManagementText} ${this.appState.buildingName}` : this.labels.HubMenuSearchaSpace);
        const buildings = appContext().localStorageProvider.getNodeData().Regions.flatMap(i => i.Buildings);
        const building = buildings.find(i => i.Node_Id == queryParams.building) ?? buildings[0] ?? null;

        if (!building)
        {
            this.props.history.push('/flex-home');
            return;
        }

        const buildingId = building.Node_Id;
        const spaceTypes = Helper.getSpaceTypesByNodeId(buildingId);
        if (spaceTypes.error)
        {
            this.alert.show(this.labels.HubLabelSetyourpreferences, this.labels.HubLabelSetUserPrefenceError, () => this.redirectToUserPrefPage());
            return;
        }

        if (!queryParams.workType && !queryParams.spaceType && queryParams.spaceType != prevQueryParams.spaceType)
        {
            const workTypes = Helper.getWorkSpaceTypesByNodeId(buildingId);
            const defaultWorkType = workTypes[0]?.Name;
            this.pushQueryParams({ workType: defaultWorkType }, true);
            return;
        }
        else if (!queryParams.workType && !queryParams.spaceType)
        {
            const defaultSpaceType = spaceTypes.result.find(i => i.Name == this.queryParams.spaceType) ?? spaceTypes.result[0];
            this.pushQueryParams({ spaceType: defaultSpaceType?.Name }, true);
            return;
        }
        else if (queryParams.workType && queryParams.spaceType && queryParams.spaceType != prevQueryParams.spaceType)
        {
            this.pushQueryParams({ workType: undefined }, true);
            return;
        }
        else if (queryParams.workType && queryParams.spaceType)
        {
            this.pushQueryParams({ spaceType: undefined }, true);
            return;
        }

        this.cachedSpaces = await this.apiCache.getSpacesByBuilding(buildingId);
        this.spaceCardsById = new Map();

        const spaceType = spaceTypes.result.find(i => i.Name == queryParams.spaceType);
        const dateOrDefault = (queryParams.date ?? DateHelper.now(buildingId));
        const allowedMinutes = Array.from({ length: 12 }, (_, i) => i * 5);
        const startDateTime = (queryParams.start ? queryParams.start.set({ year: dateOrDefault.year, month: dateOrDefault.month, day: dateOrDefault.day }) : dateOrDefault.snapToMinute([5], { direction: 1 }));
        const endDateTime = (queryParams.end ? queryParams.end.set({ year: dateOrDefault.year, month: dateOrDefault.month, day: dateOrDefault.day }) : startDateTime.plus({ hours: 1 }));
        const view = (queryParams.view == 'map' ? View.Map : View.List);
        const floorId = queryParams.floor ?? null;
        const floorForMap = this.cachedFloors.find(floor => floorId ? floor.Node_Id === floorId : parseInt(Helper.getBuildingIdUsingFloorNodeId(floor.Node_Id)) == buildingId);

        await this.setStateAsync({
            spaces: null,
            buildingId: buildingId,
            view: view,
            workspaceType: queryParams.workType ?? null,
            spaceType: spaceType?.Name ?? null,
            spaceTypeLabel: spaceType?.Label ?? null,
            floorId: (view == View.Map ? floorForMap?.Node_Id : floorId) ?? null,
            zone: queryParams.zone ?? null,
            startTime: startDateTime,
            endTime: endDateTime,
            audioVisual: queryParams.av ?? false,
            presentationAids: queryParams.resources ?? false,
            hearingAids: queryParams.hearingAids ?? false,
            catering: queryParams.catering ?? false,
            linkedSpace: queryParams.linkedSpace ?? false,
            layouts: queryParams.layouts ?? false,
            numberOfPeople: queryParams.capacity ?? 0,
            loadMap: Guid.new(),
            mapFailedToLoad: false,
        });

        await this.makeSearchCriteria();
        const spaces = await appContext().inMemoryCache.lazyGetWithQuickExpiry(this.spacesCacheKey, () => this.getNextPageOfSpaces());
        await this.setStateAsync({ spaces: spaces });
    }

    private redirectToUserPrefPage(): void
    {
        const { history } = this.props;
        history.push(`/flex-user-pref-workplace`);
    }

    private async showMapView(): Promise<void>
    {
        this.pushQueryParams({ view: 'map' });
    }

    private async showListView(): Promise<void>
    {
        this.pushQueryParams({ view: 'list' });
    }

    private async loadMoreSpacesClicked(): Promise<void>
    {
        await this.cacheNextPageOfSpaces();
    }

    private async floorPlanSpacesRequested(skipToken: string, floorId: number): Promise<IPagedFloorPlanSpaces>
    {
        let pageOfSpaces: PagedResponse<AvailableSpace[]>;
        if (!skipToken)
        {
            pageOfSpaces = await appContext().inMemoryCache.lazyGetWithQuickExpiry(this.spacesCacheKey, () => this.getNextPageOfSpaces());
        }
        else
        {
            pageOfSpaces = await this.cacheNextPageOfSpaces();
        }

        const pagedSpacesForMap = {
            skipToken: pageOfSpaces.skipToken,
            spaces: pageOfSpaces.value.map(i => ({
                id: i.Space_Id,
                colour: "",
                getColourFromData: true,
                periodCurrentSpaceValue: 0,
            })),
        };
        return pagedSpacesForMap;
    }

    private async cacheNextPageOfSpaces(): Promise<PagedResponse<AvailableSpace[]>>
    {
        const state = this.state;
        const nextPage = await this.getNextPageOfSpaces();
        const allSpaces = { skipToken: nextPage.skipToken, value: [...state.spaces?.value ?? [], ...nextPage.value] };
        appContext().inMemoryCache.setWithQuickExpiry(this.spacesCacheKey, allSpaces);
        await this.setStateAsync({ spaces: allSpaces });
        return nextPage;
    }

    private async getNextPageOfSpaces(): Promise<PagedResponse<AvailableSpace[]>>
    {
        const state = this.state;
        const nodeId = state.floorId ?? state.buildingId;

        if (state.spaces?.skipToken == '')
        {
            return { skipToken: '', value: [] };
        }
        try
        {
            this.setState({ isLoading: (state.view != View.Map) });
            const pageOfSpaces = await appContext().ibssApiClientV2.v2.byNodeid.spaces.search.post<PagedResponse<AvailableSpace[]>>({
                nodeId: nodeId,
                top: 25,
                skipToken: state.spaces?.skipToken,
                body: this.spacesFilter,
            });
            return pageOfSpaces;
        }
        catch
        {
            return { skipToken: '', value: [] };
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private get spacesCacheKey(): string
    {
        return JSON.stringify({
            name: 'ibssApiClientV2.v2.byNodeid.spaces.search.post',
            filter: JSON.stringify(this.spacesFilter),
        });
    }

    private get spacesFilter(): ISearchRecurring
    {
        const state = this.state;
        const filter =
        {
            Floor_Id: state.floorId ?? undefined,
            Meta_Loc_Zone: state.zone ?? undefined,
            Booking_Dates: [{
                Start_Time: state.startTime.toUtcByNode(this.state.buildingId).toISO(),
                End_Time: state.endTime.toUtcByNode(this.state.buildingId).toISO(),
            }],
            Space_Type: state.spaceType ?? undefined,
            Space_Work_Type: state.workspaceType ?? undefined,
            Meta_Serv_Reqs_AV: state.audioVisual ? 1 : undefined,
            Meta_Serv_Reqs_Catering: state.catering ? 1 : undefined,
            Meta_Serv_Reqs_Hearing: state.hearingAids ? 1 : undefined,
            Meta_Serv_Reqs_Presentation: state.presentationAids ? 1 : undefined,
            Space_Setup: (state.linkedSpace ? 5 : (state.layouts ? 1 : undefined)),
            Space_Capacity: state.numberOfPeople ?? 1,
        };
        return filter;
    }

    private async mapViewFloorDropdownChange(floorId: number): Promise<void>
    {
        this.pushQueryParams({ floor: floorId || undefined });
    }

    private async makeSearchCriteria(): Promise<void>
    {
        const userPreferences = this.local.getUserPreferences();
        let searchCriteria: ISearchCriteriaValue[] = [];
        const getStrtDate = Helper.getWkngDaysBySelectedBuilding(userPreferences.SearchPrefs.DefaultBuilding);
        const Occ_Wkng_Days_Stt = getStrtDate?.Occ_Wkng_Days_Stt ?? "1";
        const Occ_Wkng_Days_Stp = getStrtDate?.Occ_Wkng_Days_Stp ?? "5";
        const workingDayArray = Helper.getWorkingDayArray(parseInt(Occ_Wkng_Days_Stt), parseInt(Occ_Wkng_Days_Stp))
        momentBusiness.updateLocale('us', { workingWeekdays: workingDayArray });
        const todaysDate = DateHelper.today().toFormatOrDefault();
        const todaysDashDate = DateHelper.today().toFormatOrDefault();
        const nextDay = momentBusiness(todaysDate).nextBusinessDay().format('yyyy-MM-DD');
        const date = `${todaysDashDate}T${userPreferences.WorkingHoursPrefs.UserEndTime ? userPreferences.WorkingHoursPrefs.UserEndTime : "18:30"}`
        const userPrefEndTime = DateHelper.fromIsoToJsDate(date);
        let startDateAndTime = DateHelper.fromIsoToJsDate(DateHelper.now().toString());
        let endDateAndTime = DateHelper.fromIsoToJsDate(DateHelper.now().toString());

        if (startDateAndTime > userPrefEndTime)
        {
            startDateAndTime = DateHelper.fromIsoToJsDate(`${nextDay}T${userPreferences.WorkingHoursPrefs.UserStartTime}`);
            endDateAndTime = DateHelper.fromIsoToJsDate(`${nextDay}T${userPreferences.WorkingHoursPrefs.UserEndTime}`);
        }
        else
        {
            endDateAndTime = userPrefEndTime;
        }

        this.session.setFlexSpaceSearchCriteria(this.state.startTime, this.state.endTime);

        this.state.searchData.map((option: string) =>
        {
            if (option === 'building' && this.state.buildingId != -1)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Building.svg`), value: Helper.getBuildingNameUsingBuildingId(this.state.buildingId) ?? 'NO DATA' })
            }
            else if (option === 'workType' && this.state.workspaceType != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Desk.svg`), value: this.state.workspaceType ?? 'NO DATA' })
            }
            else if (option === 'spaceType' && this.state.spaceTypeLabel != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Desk.svg`), value: this.state.spaceTypeLabel ?? 'NO DATA' })
            }
            else if (option === 'floor' && this.state.floorId != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Floor.svg`), value: Helper.getFloorNameUsingFloorAndBuildingId(this.state.buildingId, this.state.floorId) ?? 'NO DATA' })
            }
            else if (option === 'zone' && this.state.zone != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Zone.svg`), value: this.state.zone ?? 'NO DATA' })
            }
            else if (option === 'date' && this.state.startTime != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Calendar.svg`), value: this.state.startTime?.toLocaleDateString() ?? 'NO DATA' })
            }
            else if (option === 'startTime' && this.state.startTime.isValid)
            {
                const startTime = this.state.startTime.toFormat("HH:mm");
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Time (Fill).svg`), value: startTime ?? 'NO DATA' })
            }
            else if (option === 'endTime' && this.state.endTime.isValid)
            {
                const endTime = this.state.endTime.toFormat("HH:mm");
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Time (Fill).svg`), value: endTime ?? 'NO DATA' })
            }
        });
        await this.setStateAsync({ searchCriteria: searchCriteria });
    }

    private hideSearchCritera(): void
    {
        this.setState({ openDrawer: false });
    }

    private showSearchCriteria(): void
    {
        this.setState({ openDrawer: true });
    }

    private navigateToSpaceDetails(spaceId: string): void
    {
        this.props.history.push(`/flex-find-a-space/${this.state.buildingId}/searchaspace/${spaceId}`);
    }

    private mapFailedToLoad(): void
    {
        this.setState({ isLoading: false, mapFailedToLoad: true });
    }

    private async searchCriteriaChanged(result: ISearchCriteriaResult): Promise<void>
    {
        this.pushQueryParams({
            building: result.buildingId,
            workType: result.workspaceType || undefined,
            spaceType: result.spaceType || undefined,
            floor: result.floorId || undefined,
            zone: result.zone || undefined,
            date: result.startTime,
            start: result.startTime,
            end: result.endTime,
            av: result.audioVisual || undefined,
            resources: result.presentationAids || undefined,
            hearingAids: result.hearingAids || undefined,
            catering: result.catering || undefined,
            linkedSpace: result.linkedSpace || undefined,
            layouts: result.layouts || undefined,
            capacity: result.numberOfPeople || undefined,
        });
    }

    private keyPressed(event: React.KeyboardEvent<HTMLDivElement>): void
    {
        if (event.key === 'Enter') 
        {
            event.preventDefault();
            return;
        }
        else if (!this.spaceCards) 
        {
            return;
        }

        let selectedIndex = this.spaceCards.findIndex(space => space.spaceId === this.state.selected);
        let nextSpaceId;

        switch (event.key)
        {
            case 'ArrowLeft': {
                nextSpaceId = this.spaceCards[selectedIndex - 1]?.spaceId;
                break;
            }
            case 'ArrowRight': {
                nextSpaceId = this.spaceCards[selectedIndex + 1]?.spaceId;
                break;
            }
            case 'ArrowUp': {
                const { x: currentXUp, y: currentYUp } = this.getLocation(this.state.selected);
                for (let i = selectedIndex - 1; i >= 0; i--)
                {
                    const { x: positionX, y: positionY } = this.getLocation(this.spaceCards[i].spaceId);
                    if (currentXUp === positionX && currentYUp >= positionY) 
                    {
                        nextSpaceId = this.spaceCards[i].spaceId;
                        break;
                    }
                }
                break;
            }
            case 'ArrowDown': {
                const { x: currentXDown, y: currentYDown } = this.getLocation(this.state.selected);
                for (let i = selectedIndex + 1; i < this.spaceCards.length; i++)
                {
                    const { x: positionX, y: positionY } = this.getLocation(this.spaceCards[i].spaceId);
                    if (currentXDown === positionX && currentYDown <= positionY) 
                    {
                        nextSpaceId = this.spaceCards[i].spaceId;
                        break;
                    }
                }
                break;
            }
            case ' ': {
                this.navigateToSpaceDetails(this.state.selected);
                return;
            }
            default: {
                return;
            }
        }

        if (nextSpaceId) 
        {
            this.setState({ selected: nextSpaceId });
        }
    }

    private spaceCardFocussed(id: string): void
    {
        this.setState({ selected: id });
    }

    private getLocation(id: string): ILocation
    {
        const el = document.getElementById(id);
        if (!el) 
        {
            return { el: null, x: 0, y: 0 };
        }
        const location = el.getBoundingClientRect();
        const x = location.left + window.scrollX;
        const y = location.top + window.scrollY;
        return { el, x, y };
    }

    private spaceCardsById: Map<string, SpaceCardProps> = new Map();
    private get spaceCards(): SpaceCardProps[]
    {
        const spaces = (this.state.spaces?.value ?? []);
        const cardsById = this.spaceCardsById;

        const cards = spaces
            .map(space =>
            {
                if (!cardsById.has(space.Space_Id))
                {
                    const cachedSpace = this.cachedSpaces.find(i => i.Space_Id == space.Space_Id);
                    if (!cachedSpace)
                    {
                        return null;
                    }
                    const spaceCard = SpaceCardProps.fromSpace(cachedSpace);
                    cardsById.set(space.Space_Id, spaceCard);
                }
                const card = cardsById.get(space.Space_Id) ?? null;
                return card;
            })
            .filter(i => !!i) as SpaceCardProps[];

        return cards;
    }

    public get searchResults(): SearchResults
    {
        const state = this.state;
        if (state.view == View.Map && state.mapFailedToLoad)
        {
            return SearchResults.MapFailedToLoad;
        }
        else if (state.view == View.Map)
        {
            return SearchResults.Map;
        }
        else if ((state.spaces?.value ?? []).length == 0)
        {
            return SearchResults.NoResults;
        }
        else
        {
            return SearchResults.List;
        }
    }

    public render(): JSX.Element
    {
        const filterCriteraDrawerData: ISearchCriteriaProps =
        {
            open: this.state.openDrawer,
            closeClicked: () => this.hideSearchCritera(),
            updateSearchResults: result => this.searchCriteriaChanged(result),
            notificationList: this.props.notificationList,
            notificationReadedList: this.props.notificationReadedList,
            lightModeTheme: this.props.lightModeTheme,

            // search criteria
            buildingOptions: this.state.buildingId,
            selectedWorkspaceTypes: this.state.workspaceType ?? "Any",
            selectedSpaceTypes: this.state.spaceType ?? "Any",
            selectedFloor: this.state.floorId == null ? "Any" : this.state.floorId.toString(),
            selectedZone: this.state.zone ?? "Any",
            startTime: this.state.startTime.toJSDate(),
            End_Date_For_filter_modal: this.state.endTime.toJSDate(),
            av: this.state.audioVisual,
            presentationAids: this.state.presentationAids,
            hearingAids: this.state.hearingAids,
            catering: this.state.catering,
            linkedSpace: this.state.linkedSpace,
            layouts: this.state.layouts,
            numberOfPeople: this.state.numberOfPeople?.toString() ?? "",
            onelensSpaceAnalyticsOverview: {
                buildingOption: "",
                floor: "",
                classType: "",
                WorkSpace: "",
                periodTypeValue: "",
                periodType: 0,
                buildingNodeId: 0,
                fmsfc_start_date_for_filter_modal: "",
                End_Date_For_filter_modal: ""
            }
        }

        const listButtonActive = (this.state.view === View.List);
        const mapButtonActive = (this.state.view === View.Map);

        return (
            <>
                <link rel="stylesheet" href="/src/pages/Flex/Search/SearchComponent.css"></link>
                {this.state.isLoading && <Spinner />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content" style={{ paddingBottom: "10px" }} >
                        <div className="space-box-cont">
                            <div className="left-space-box-cont flex-row-bredcrumb">
                                {this.state.buildingId > 0 &&
                                    <SearchSpaceFilter
                                        startDateTime={this.state.startTime}
                                        selectedBuilding={this.state.buildingId}
                                        selectedFloor={this.state.floorId == null ? "Any" : this.state.floorId.toString()}
                                        selectedZone={this.state.zone ?? "Any"}
                                        selectedWorkspaceType={this.state.workspaceType ?? "Any"}
                                        selectedSpaceType={this.state.spaceType ?? "Any"}
                                        onDateTimeClick={() => this.showSearchCriteria()}
                                        onBuildingChange={buildingId => this.pushQueryParams({ building: buildingId })}
                                        onFloorChange={floor => this.pushQueryParams({ floor: floor === "Any" ? undefined : parseInt(floor) })}
                                        onZoneChange={zone => { this.pushQueryParams({ zone: zone === "Any" ? undefined : zone }) }}
                                        onSpaceTypeChange={spaceType => this.pushQueryParams({ spaceType: spaceType === "Any" ? undefined : spaceType })}
                                        onWorkSpaceTypeChange={workspaceType => this.pushQueryParams({ workType: workspaceType === "Any" ? undefined : workspaceType })}
                                    />
                                }
                            </div>
                            <div className="right-space-box-cont">
                                {!this.state.openDrawer ? (
                                    <div>
                                        <IbssButton sx={{ whiteSpace: 'nowrap' }} variant="contained" color="primary" onClick={() => this.showSearchCriteria()}> {this.labels.funcAdditionalCriteria_S} </IbssButton>
                                    </div>
                                ) : ""}
                            </div>

                            {this.state.openDrawer ? (
                                <SearchSpaceCriteria {...filterCriteraDrawerData} />
                            ) : ""}

                        </div>
                        <Grid container className="space-box-cont">
                            <Grid sm={8} mb={1} className="left-space-box-cont flex-row-bredcrumb">
                                <div className="search-results-title">{this.labels.HubLabelSearchResults}</div>
                            </Grid>
                            <Grid sm={4} spacing={2} mb={1} className="btn-right-aligned">
                                <MapButton onClick={() => this.showMapView()} active={mapButtonActive} style={{ marginRight: "10px" }} />
                                <ListButton onClick={() => this.showListView()} active={listButtonActive} />
                            </Grid>
                            <Grid sm={12} className={"search-results-height mt-0 " + this.resultsCssClass(this.searchResults)}>
                                {this.renderResults(this.searchResults)}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );
    }

    private renderResults(searchResults: SearchResults): JSX.Element
    {
        switch (searchResults)
        {
            case SearchResults.NoResults:
                return (!this.state.isLoading ? <Alert key="noResults" title={this.labels.HubLabelNoSpacesAvailable} text={this.labels.HubLabelFlexSearchCriteriaNoSpaces} /> : <></>);

            case SearchResults.MapFailedToLoad:
                return (<Alert key="mapFailedToLoad" title={this.labels.HubmapFailedToLoad} text={this.labels.HubLabelUsingTheListView} />);

            case SearchResults.Map:
                return (
                    <FloorPlan
                        key="map"
                        mapUrls={this.state.mapUrls}
                        onFloorSelected={(floorId: number) => this.mapViewFloorDropdownChange(floorId)}
                        displayFloorDropdown={true}
                        loadSpaces={this.state.loadMap}
                        onRequestSpaces={(skipToken, floorId) => this.floorPlanSpacesRequested(skipToken, floorId)}
                        spaceModalClicked={spaceId => this.navigateToSpaceDetails(spaceId)}
                        mapFailedToLoad={() => this.mapFailedToLoad()}
                        floorId={this.state.floorId ?? 0}
                        startTime={this.state.startTime}
                        endTime={this.state.endTime}
                        enableSpaceClick={true}
                    />);

            case SearchResults.List:
                return (
                    <>
                        <div
                            key="list"
                            className="space-card-container"
                            onKeyDown={e => this.keyPressed(e)}
                        >
                            {
                                this.spaceCards.map(props => (
                                    <SpaceCard
                                        key={props.spaceId}
                                        pointer={true}
                                        {...props}
                                        onClick={spaceId => this.navigateToSpaceDetails(spaceId)}
                                        buildingId={this.state.buildingId}
                                        onCardFocused={id => this.spaceCardFocussed(id)}
                                        focus={props.spaceId === this.state.selected}
                                    />))
                            }
                        </div>
                        {this.state.spaces?.skipToken != '' &&
                            <Box
                                className="text-center my-4">
                                <button
                                    type="button"
                                    className="edit-search btn-primary btn-md"
                                    onClick={() => this.loadMoreSpacesClicked()}
                                >
                                    {this.labels.HubButtonLoadMore}
                                </button>
                            </Box>
                        }
                    </>
                );

            default:
                return (<></>);
        }
    }

    private resultsCssClass(searchResults: SearchResults): string
    {
        switch (searchResults)
        {
            case SearchResults.NoResults:
            case SearchResults.MapTooManyFloors:
            case SearchResults.MapFailedToLoad:
                return "search-results-cont--alert";

            case SearchResults.Map:
                return "search-results-cont--map";

            case SearchResults.List:
                return "search-results-cont--list";

            default:
                return "";
        }
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
        flexMySearchFilterCriteria: state.flexMySearchFilterCriteria
    };
};

export default withRouter(connect(mapStateToProps)(SearchSpaces) as any);

enum View
{
    Map,
    List,
}

enum SearchResults
{
    NoResults,
    MapTooManyFloors,
    MapFailedToLoad,
    Map,
    List,
}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState
{
}

interface IState
{
    isLoading: boolean;
    spaces: PagedResponse<AvailableSpace[]> | null;
    openDrawer: boolean;
    searchData: Array<string>;
    searchCriteria: Array<ISearchCriteriaValue>;
    view: View;
    mapUrls: IMapUrl[];
    loadMap: Guid;
    mapFailedToLoad: boolean;

    // search criteria
    buildingId: number;
    workspaceType: (string | null);
    spaceType: (string | null);
    spaceTypeLabel: (string | null);
    floorId: (number | null);
    zone: (string | null);
    startTime: DateTime;
    endTime: DateTime;
    audioVisual: boolean;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;
    numberOfPeople: (number | null);
    // end of search criteria

    selected: string;
}

interface ISearchCriteriaValue
{
    src: string;
    value: string;
}

interface IMatchParams
{
    buildingid: string;
}

interface ILocation 
{
    el: HTMLElement | null;
    x: number;
    y: number;
}

interface ISpaceConfig
{
    id: string;
    colour: string;
    getColourFromData: boolean,
    periodCurrentSpaceValue: number,
}

interface ISpaceTypesResult
{
    result: ISearchConfigItem[];
    error: boolean;
}