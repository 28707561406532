import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export interface ISpaceInfo
{
    ActionURI: string,
    ImageURI: string,
    Available_From: string,
    Expiry_Date: string,
    Has_Image: number,
    Node_Id: number,
    Space_Info_Id: number,
    Space_Info_Title: string,
    Space_Info_Message: string,
    Space_Info_Short_Message: string,
}

export class BookingResponse extends ODataSelect
{
    _CreatedAt = '';
    _CreatedBy = '';
    Booking_AutoCheckIn = false;
    Booking_Cancelled_Time = '';
    Booking_Cancelled_By = '';
    Booking_Checkin_Time = '';
    Booking_Early_Checkin = '';
    Booking_Completed_Time = '';
    Booking_Description = '';
    Booking_End = '';
    Booking_Host_Email = '';
    Booking_Host_Name = '';
    Booking_Id = '';
    Booking_IsActive = false;
    Booking_IsApproved = 0;
    Booking_IsCancelled = false;
    Booking_IsCheckedIn = false;
    Booking_IsCompleted = false;
    Booking_IsEarly_Checkin = false;
    Booking_IsLate_Checkin = false;
    Booking_IsPrivate = false;
    Booking_Name = '';
    Booking_Owner_Email = '';
    Booking_Owner_Id = '';
    Booking_Owner_Name = '';
    Booking_Parties: Array<IGetV2BookingResponse_BookingParty> = [];
    Booking_Share_Loc = 0;
    Booking_Start = '';
    Booking_Status = '';
    Cost_Code_Allocation: Array<ICostCodeAllocation> | null = [];
    Meta_Ext_Booking_Id = '';
    Meta_Organisation = '';
    Node_Id = 0;
    Online_Meeting = '';
    Space_Id = '';
    Space_Layout = '';
    Space_Name = '';
    Parent_Booking_Relation_Type = '';
    Parent_Booking_Id = '';
    ID = 0;
    Booking_Resources: IBookingResources[] = [];
    Booking_Attendance = 0;
}

export interface IGetV2BookingResponse_BookingParty
{
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Resource_Id: string | null,
    Booking_Visitor: boolean,
    Booking_Participant_Type: number,
}

export interface ICostCodeAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}

export interface IBookingResources
{
    Record_Id: string;
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Resource_Id: string;
    Booking_Visitor: boolean;
    Booking_Participant_Type: number;
}

export class ITagToEventType extends ODataSelect
{
    public Node_Id = "";
    public Tag2EventType_Id = "";
    public Tag_Id = "";
    public TagType = "";
    public Name = "";
    public Value = "";
    public Icon = "";
    public Record_Id = "";
    public EventType_Id = 0;

    public init(response: any, nodeId: number): ITagToEventType
    {
        super.init(response, nodeId);
        return this;
    }
}

export class ITodayBookings extends ODataSelect
{
    public _ID = "";
    public Space_Id = "";
    public Space_Name = "";
    public Booking_Start = "";
    public Booking_End = "";

    public init(response: any, nodeId: number): ITodayBookings
    {
        super.init(response, nodeId);
        return this;
    }
}
