import { DateTime } from "luxon";
import { DateHelper } from "../../../../Common/DateHelper";
import { BookingStatus } from "../../../../Providers.Api/Bookings/BookingRepository";
import { ParentBookingRelationTypeEnum } from "../../../../Providers.Api/Bookings/GetV2BookingsEndpoint";
import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class Booking extends ODataSelect
{
    public Node_Id = 0;
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Description = "";
    public Booking_Start =  DateHelper.null();
    public Booking_End = DateHelper.null();
    public Booking_Completed_Time = '';
    public Booking_Early_Checkin = '';
    public Booking_Status: BookingStatus = "";
    public Booking_Owner_Name = "";
    public Booking_Owner_Email = "";
    public Booking_IsActive = 0;
    public Booking_IsCancelled = 0;
    public Booking_IsCheckedIn = 0;
    public Booking_AutoCheckIn = 0;
    public Booking_IsEarly_Checkin = 0;
    public Booking_IsLate_Checkin = 0;
    public Booking_Share_Loc = 0;
    public Booking_IsApproved = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Layout = "";
    public _CreatedAt = '';
    public _CreatedBy = "";
    public Booking_Parties = "";
    public Booking_Series_Id = ""
    public Parent_Booking_Id = "";
    public Parent_Booking_Relation_Type = ParentBookingRelationTypeEnum.Main;
    public Meta_Loc_Zone = "";
    public Approval_Groups ="";

    public init(response: any, nodeId: number): Booking
    {
        super.init(response, nodeId);
        this.Booking_Start = DateTime.fromISO(response.Booking_Start);
        this.Booking_End = DateTime.fromISO(response.Booking_End);
        return this;
    }
}
export class SystemFilter extends ODataSelect
{
    public CreatedAt = DateHelper.null();
    public CreatedByUserId?= '';
    public EventType_Id = 0;
    public Filter = '';
    public Filter_Id?= '';
    public ModifiedAt = DateHelper.null();
    public ModifiedByUserId?= '';
    public Name = '';
    public Node_Id = 0;
    public OwnedByRoleId = '';
    public OwnedByUserId = '';
    public Status = '';
    public Building_Bypass = false;
    public TenancyId?= '';

    public init(response: any, nodeId: number): SystemFilter
    {

        super.init(response, nodeId);
        this.CreatedAt = DateHelper.fromIsoByNode(response.CreatedAt, nodeId);
        this.ModifiedAt = DateHelper.fromIsoByNode(response.ModifiedAt, nodeId);
        return this;
    }
}

export class MyBooking extends ODataSelect
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Start = DateHelper.null();
    public Booking_End = DateHelper.null();
    public Booking_Early_Checkin = DateHelper.null();
    public Booking_Participant_CheckedIn = 0;
    public Booking_Status: BookingStatus = "";
    public Booking_Owner_Name = "";
    public _CreatedAt = DateHelper.null();
    public CreatedBy = "";
    public Booking_Series_Id = "";
    public Parent_Booking_Relation_Type = "";
    public Booking_Participant_Email= "";

    public init(response: any, nodeId: number): ODataSelect
    {
        Object.assign(this, response);
        this.Booking_Start = DateTime.fromISO(response.Booking_Start);
        this.Booking_End = DateTime.fromISO(response.Booking_End);
        this.Booking_Early_Checkin = DateTime.fromISO(response.Booking_Early_Checkin);
        this._CreatedAt = DateTime.fromISO(response._CreatedAt);
        return this;
    }
}

export class PrimariesBooking extends ODataSelect
{
    public Node_Id = 0;
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Description = "";
    public Booking_Start =  DateHelper.null();
    public Booking_End = DateHelper.null();
    public Booking_Completed_Time = '';
    public Booking_Early_Checkin = '';
    public Booking_Status: BookingStatus = "";
    public Booking_Owner_Email = "";
    public Booking_IsActive = 0;
    public Booking_IsCancelled = 0;
    public Booking_IsCheckedIn = 0;
    public Booking_AutoCheckIn = 0;
    public Booking_IsEarly_Checkin = 0;
    public Booking_IsLate_Checkin = 0;
    public Booking_Share_Loc = 0;
    public Booking_IsApproved = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Layout = "";
    public _CreatedAt = '';
    public _CreatedBy = "";
    public Booking_Parties = "";
    public Booking_Series_Id = ""
    public Parent_Booking_Id = "";
    public Meta_Loc_Zone = "";
    public Approval_Groups ="";
    public Booking_Participant_Email = "";

    public init(response: any, nodeId: number): PrimariesBooking
    {
        super.init(response, nodeId);
        this.Booking_Start = DateHelper.fromIsoByNode(response.Booking_Start as unknown as string, nodeId);
        this.Booking_End = DateHelper.fromIsoByNode(response.Booking_End as unknown as string, nodeId);
        return this;
    }
}
export class MyPrimaries extends ODataSelect
{
   public Delegate_Id = '';
   public DelegateUser_Id = '';
   public DelegateUserName = '';
   public DelegateEmail = '';
   public DelegateDisplayName = '';
   public PrimaryDisplayName = ''
   public PrimaryUserName? = '';
   public PrimaryEmail? = '';
   public PrimaryUser_Id? = '';

   public init(response: any, nodeId: number): ODataSelect
   {
       Object.assign(this, response);
       return this;
   }
}
export class UsersRoles extends ODataSelect
{
    public id = "";
    public name = "";
}

export class DownloadBooking extends ODataSelect
{
    _CreatedAt = '';
    _CreatedBy = '';
    Booking_Id = '';
    Booking_Owner_Name = '';
    Booking_Owner_Email = '';
    Booking_Start = '';
    Booking_End = '';
    Booking_Name = '';
    Space_Id = '';
    Space_Name = '';
    Space_Layout = '';
    Booking_Status = '';
    Booking_Checkin_Time = '';
    Booking_Completed_Time = '';
    Meta_Loc_Zone = '';
    Booking_Cancelled_Time = '';
    Floor_Name = '';
}
export class SpaceZones extends ODataSelect
{
    public Node_Id = 0;
    public Node_Name = "";
    public Space_Zone_Id = 0
    public Meta_Loc_Zone = ""
}