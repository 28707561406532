import "../../../App.css";
import "../../../styles/css/login.scss";
import * as React from "react";
import { Component } from "react";
import IbssFooter from '../../../Components/Layout/Footer/IbssFooter';
import { appContext } from "../../../AppContext";
import ApiMessageBuilder from "../../../Providers.Api/ApiMessageBuilder";
import { LogOutReason } from "../../../Providers.Api/Authentication";
import { IExternalIdentityProvider } from "../../../Providers.Api/ExternalIdentityProviders/GetExternalIdentityProvidersEndpoint";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../../../azure-authentication-config";
import {AuthenticationResult} from "@azure/msal-common";
import { OktaAuth } from "@okta/okta-auth-js";
import SvgIcon from '@mui/material/SvgIcon';
import { Box, Typography } from '@mui/material';
import { PaperProps } from '@mui/material/Paper';
import apis from "../../../Providers.Api/apis";
import { MessageContainer, FormFieldContainer, IconContainer, TextFieldContainter, LinkContainer, FormContainer, ImageContainer, LoginContainer, LoginFrame } from "./LoginComponents";
import IbssButton from "../../../Components/Buttons/Button/IbssButton";
import IbssTextField from "../../../Components/Inputs/TextField/IbssTextField";
import IbssLink from "../../../Components/Navigation/Link/IbssLink";
import { ReactComponent as EmailIcon } from './EmailEnvelope.svg';
import { ReactComponent as LockIcon } from './PasswordLock.svg';
import LoginSplash from './LoginSplash.png';
import { RouteComponentProps } from "react-router-dom";
import IbssDialog from "../../../Components/Dialogs/BaseDialog/IbssDialog";

enum LoginProvider
{
    LoginTypeSelection = 'LOGIN_TYPE_SELECTION',
    NativeLogin = 'NATIVE_LOGIN',
    ForgottenPassword = 'FORGOTTEN_PASSWORD'
}

export default class Login extends Component<IProps, IState>
{
    private labels = appContext().labels;
    private alert = appContext().alert;
    private apiMessageBuilder = new ApiMessageBuilder();
    private get local() { return appContext().localStorageProvider; }
    private get session() { return appContext().sessionStorageProvider; }
    private get cache() { return appContext().cache; }
    private boxRef: React.RefObject<HTMLDivElement>; 
    private apiClient = appContext().apiClient;

    private headingStyle =
    {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '30px',
        lineHeight: '38px',
    };

    private textStyle =
    {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '20px',
    };

    constructor(props: IProps)
    {
        super(props);

        this.session.clear();
        this.local.clear();
        this.cache.clearAll();
        this.boxRef = React.createRef();

        const searchParams = new URLSearchParams(window.location.search);
        const reason = (searchParams.get("reason") ?? "").toLowerCase();

        let message: string;
        switch (reason)
        {
            case LogOutReason[LogOutReason.SessionExpired].toLowerCase():
                message = this.labels.HubLabelLogInAgain;
                break;

            case LogOutReason[LogOutReason.AccountIncomplete].toLowerCase():
                message = this.labels.HubLabelNoPermissionToLogIn;
                break;

            case LogOutReason[LogOutReason.LoginFailed].toLowerCase():
                message = this.labels.HubLabelLoginFailedPleaseTryAgain;
                break;

            case LogOutReason[LogOutReason.VersionChanged].toLowerCase():
                message = this.labels.funcSystemUpgradedLogInAgain_L;
                break;

            default:
                message = "";
                break;
        }

        this.state =
        {
            email: "",
            password: "",
            oktaIssuer: "",
            oktaClientID: "",
            azureClientID: "",
            azureTenantID: "",
            showPasswordField: false,
            errors: { email: "", password: "" },
            message: message,
            loginFrame: LoginProvider.LoginTypeSelection,
            allExternalIdentityProviders: [],
            showPrivacyPolicyDialog: false,
            enablePrivacyPolicyButtons: false,
            privacyPolicyContent: "",
            scrollEnabled: false,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.loadExternalIdentityProviders();
        if (!this.local.getUserPolicy())
        {
            await this.loadPrivacyPolicy();
        }
        this.checkScrollBar();
    }
    
    public checkScrollBar(): void 
    {
        const boxElement = this.boxRef.current;
        if (boxElement) 
        {
          if (boxElement.scrollHeight > boxElement.clientHeight) 
          {
            this.setState({
                scrollEnabled: false
            })
          } else 
          {
             this.setState({
                scrollEnabled: true
            })
          }
        }
    }
    
    private async loadPrivacyPolicy(): Promise<void>
    {
        try
        {
            const response = await this.apiClient.privacyPolicies.getPrivacyPolicy();
            if(response?.length > 0)
            {
                this.setState({ privacyPolicyContent: response,showPrivacyPolicyDialog: true });
            }
        } catch (error)
        {
            this.setState({ showPrivacyPolicyDialog: false });
        }
    }

    private async loadExternalIdentityProviders(attemptNumber?: number): Promise<void>
    {
        const attempt = attemptNumber ?? 1;

        let identityProviders: IExternalIdentityProvider[];
        try
        {
            identityProviders = await this.apiClient.externalIdentityProviders.getExternalIdentityProviders(true);

            if (!identityProviders.length) 
            {
                this.props.history.push('/out-of-service');
            }
            const sortedIdentityProviders = [...identityProviders ].sort((a, b) => a.SecurityProviderTypeId - b.SecurityProviderTypeId);
            this.setState({ allExternalIdentityProviders: sortedIdentityProviders });
        }
        catch
        {
            if(attempt === 1)
            {
                window.setTimeout(()=> this.loadExternalIdentityProviders(2), 100);
            }
            else
            {
                this.props.history.push('/out-of-service');
            }
        }
    }

    private handleAADLogin(identityProvider: IExternalIdentityProvider): void
    {
        // having a SecurityProviderTypeId of 1 corresponds to a AAD security provider. 
        //const identityProvider = this.state.allExternalIdentityProviders.find(externalIdentityProvider => externalIdentityProvider.SecurityProviderTypeId === 1)

        // if client app does not have AAD security Provider, do not proceed, early return, 
        if (!identityProvider) return;

        if (!identityProvider.ClientId || !identityProvider.TenantDomain) return;

        // msal configuration
        const myMSALObj: PublicClientApplication = new PublicClientApplication(msalConfig);

        myMSALObj["config"].auth.clientId = identityProvider.ClientId;
        myMSALObj["config"].auth.authority += identityProvider.TenantDomain + "/";

        myMSALObj.loginPopup(loginRequest)
            .then((authResult: AuthenticationResult) =>
            {
                // var issuer = ""+azureClientID;
                //myMSALObj.loginRedirect(loginRequest)
                if (authResult.idToken)
                {
                    // successful login API calls always result in an ID Token, not an access token, being returned. successful login API calls always result in an ID Token, not an access token, being returned.
                    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/docs/scopes.md#scope-types
                    this.props.history.push('/auth');
                }
            })
            .catch(function (error)
            {
                console.log(error);
            });
    }

    private handleOktaLogin(identityProvider: IExternalIdentityProvider): void
    {
        // having a SecurityProviderTypeId of 2 corresponds to a Okta security provider. 
        //const identityProvider = this.state.allExternalIdentityProviders.find(externalIdentityProvider => externalIdentityProvider.SecurityProviderTypeId === 2)

        // if client app does not have Okta security Provider, do not proceed, early return, 
        if (!identityProvider) return;

        if (!identityProvider.ClientId || !identityProvider.TenantDomain) return;

        const config =
        {
            issuer: "https://" + identityProvider.TenantDomain,
            clientId: identityProvider.ClientId,
            redirectUri: window.location.origin + "/implicit/callback",
            scopes: ["openid email profile"],
        };

        const authClient = new OktaAuth(config);
        authClient.signInWithRedirect();

    }

    private handleIdentityProvider(identityProvider: IExternalIdentityProvider): void
    {
        const user = this.local.getUserDetails();
        this.local.setUserDetails({
            ...user,
            userType: identityProvider.Name,
            providerType: identityProvider.SecurityProviderTypeId,
        })
        switch (identityProvider.SecurityProviderTypeId)
        {
            case 1:
                this.handleAADLogin(identityProvider);
                break;

            case 2:
                this.handleOktaLogin(identityProvider);
                break;
            default:
                this.alert.show(this.labels.HubLabelLoginFailed, this.labels.HubLabelUnableToLoginMessage);
                break;
        }
    }

    private handleNativeEmailLogin(): void
    {
        // handle native login submission

        const { email, password } = this.state;
        const isValid = this.validateCredentials();
        const inputValidationErrorsExist: boolean = !!this.state.errors.email || !!this.state.errors.password;
        if (!isValid || inputValidationErrorsExist) return; // if there are input validation errors, don't call the api.

        const user = this.local.getUserDetails();
        this.local.setUserDetails({
            ...user,
            userType: 'Native',
            providerType: 0,
        })

        apis.loginWithCredentials.post('authenticate2', { email: email, password: password })
            .then((res: any) =>
            {
                this.local.setToken(res?.data?.token);
                this.props.history.push('/auth');

            })
            .catch((error: any) =>
            {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        password: error.response.data.message,
                    }
                }));
            })
    }

    private validateCredentials(): boolean
    {
        const { email, password } = this.state;
        const isEmailValid = this.validateEmail(email);
        const isPasswordValid = this.validatePassword(password);
        const isValid = isEmailValid && isPasswordValid;
        return isValid;
    }

    private validateEmail(email: string): boolean
    {
        // front end validation that tests if a string is of the form expected of an email addesss.
        // sets state.errors.email if the string is empty or doesn't match the regex pattern.
        // sets isValid to false unless there are no email errors.
        let isValid = false;
        const pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!email)
        {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    email: this.labels.HubLabelEnterEmailMessage,
                }
            }));
        }
        else if (!pattern.test(email))
        {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    email: this.labels.HubLabelIncorrectEmailError
                }
            }));
        }
        else
        {
            isValid = true;
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    email: '',
                }
            }));
        }
        return isValid;
    }

    private validatePassword(password: string): boolean
    {
        // front end validation that tests if password is non-empty
        // sets state.errors.password if the string is empty
        // sets isValid to false unless there are no email errors.

        let isValid = false;
        if (!password)
        {
            // updated state for email errors
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    password: this.labels.HubLabelEnterPasswordMessage
                }
            }));
        }
        else 
        {
            isValid = true;
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    password: '',
                }
            }));
        }
        return isValid;
    }

    private requestNewPasswordClicked()
    {
        const emailIsValid = this.validateEmail(this.state.email);
        if (!emailIsValid) return;

        apis.requestNewPassword(this.state.email)
            .then((res: any) =>
            {
                this.alert.show("", this.apiMessageBuilder.getMessage(res.data, 1));
            })
            .catch((error: any) =>
            {
                console.log(error);
            });
    }

    private emailChanged(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        //handle syncing up what the user is typing in the email field with email state.
        const { value } = event.target;
        this.setState({ email: value }, () => this.validateEmail(this.state.email));
    }

    private passwordChanged(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        //handle syncing up what the user is typing in the password field with password state.
        const { value } = event.target;
        this.setState({ password: value }, () => this.validatePassword(this.state.password));
    }

    private nativeLoginClicked(): void
    {
        //handle user choosing Native Email Login by clicking on Ibss Logo
        this.setState(prevState =>
        {
            if (prevState.loginFrame === LoginProvider.NativeLogin) 
            {
                // if LoginFrame is already on the email login section, clicking on Ibss logo returns user to loginType selection section.
                return {
                    ...prevState,
                    loginFrame: LoginProvider.LoginTypeSelection
                }
            }
            else
            {
                return {
                    ...prevState,
                    loginFrame: LoginProvider.NativeLogin
                }
            }
        })
    }

    private forgottenPasswordClicked(): void
    {
        //handle user clicking on Forgotten Password Link on Native Login screen
        this.setState({ loginFrame: LoginProvider.ForgottenPassword })
    }

    private privacyPolicyDeclined(): void
    {
        window.location.href = "https://www.intelligentbuildingssoftware.com/";
    }

    private privacyPolicyAccepted(): void
    {
        this.local.setUserPolicy(true);
        this.setState({ showPrivacyPolicyDialog: false });
    }

    private privacyPolicyScrolled(e : React.UIEvent<HTMLDivElement>): void 
    {   
        const hasScrolledToBottom = e.currentTarget.scrollHeight - e.currentTarget.clientHeight <= e.currentTarget.scrollTop + 20;
        if (hasScrolledToBottom) 
        {
            this.setState ({ enablePrivacyPolicyButtons: true });
        }
    }

    public render(): JSX.Element
    {
        return (
            <>
            <Box sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100vh',
                padding: '40px 0px',
                backgroundColor: (theme) => theme.palette.grey[400] // uiMidTone
            }}>
                <LoginFrame className={'login-template'}>
                    <LoginContainer square elevation={0}>
                        {/*Intro */}
                        <Box sx={{
                            boxSizing: 'border-box',
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            padding: '0px 5px 0px 0px;',
                            gap: '5px',
                            width: '415px',
                            height: '93px',
                            flex: 'none',
                            order: 0,
                            alignSelf: 'stretch',
                            flexGrow: 0,
                        }}>
                            {/* logo */}
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', padding: '5px', gap: '10px', boxSizing: 'border-box' }}>
                                <img src={`/images/Logos/Roamer_logo.png`} alt="Iconics Roamer logo" onClick={() => this.nativeLoginClicked()} />
                            </Box>
                            {/* welcome info */}
                            <Box sx={{ boxSizing: 'border-box', display: "flex", flexDirection: "column", alignItems: "flex-start", padding: '0px 5px', gap: '10px', }}>
                                <Box>
                                    <Typography sx={{ ...this.headingStyle, color: (theme) => theme.palette.text.primary }}>
                                        {/* `Welcome to IBSS Hub` */}
                                        {this.labels.HubLabelWelcomeToHub}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ ...this.textStyle, color: (theme) => theme.palette.text.primary }}>
                                        {/*`Your portal to all things IBSS from ICONICS.`*/}
                                        {`${this.labels.HubLabelYourPortal}.`}
                                    </Typography>
                                    <Typography sx={{ ...this.textStyle, color: (theme) => theme.palette.text.primary }}>
                                        {/* `Flex, Admin Portal, and OneLens` */}
                                        {this.labels.HubLabelThreeProducts}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <FormContainer>
                        {
                            this.state.loginFrame === LoginProvider.LoginTypeSelection && <>
                                <MessageContainer>
                                    <Typography sx={{ ...this.textStyle, color: (theme) => theme.palette.text.primary }}>
                                        {`${this.labels.HubLabelContinueToLogin}:`}
                                    </Typography>
                                </MessageContainer>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: "10px 0px", gap: "10px" }}>
                                    {this.state.allExternalIdentityProviders.map(i =>
                                    {
                                        return <IbssButton key={(i.ID).toString()} id={(i.ID).toString()} variant="contained" color="primary" fullWidth onClick={() => this.handleIdentityProvider(i)}>
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontStyle: 'normal', fontWeight: 400, fontSize: '15px', lineHeight: '26px', letterSpacing: '0.46px' }}>
                                                {i.Name}
                                            </Typography>
                                        </IbssButton>
                                    })}
                                </Box>
                            </>
                        }
                        {
                            this.state.loginFrame === LoginProvider.NativeLogin && <>
                                <MessageContainer>
                                    <Typography sx={{ ...this.textStyle, color: (theme) => theme.palette.text.secondary }}>
                                        {this.labels.HubLabelSignInText}
                                    </Typography>
                                </MessageContainer>

                                <FormFieldContainer>
                                    <IconContainer>
                                        <SvgIcon
                                            component={EmailIcon}
                                            sx={{ color: 'grey[400]' }} // ui MidTone color
                                        />
                                    </IconContainer>
                                    <TextFieldContainter>
                                        <IbssTextField
                                            id='email'
                                            name='email'
                                            autoComplete= 'email'
                                            autoFocus
                                            label={this.labels.HubLabelEmail}
                                            placeholder={this.labels.HubLabelEmail.toLowerCase()}
                                            required={true}
                                            type="email"
                                            variant="standard"
                                            fullWidth
                                            onChange={(event) => this.emailChanged(event)}
                                            value={this.state.email}
                                            error={!!this.state.errors.email}
                                            helperText={this.state.errors.email}
                                        />
                                    </TextFieldContainter>
                                </FormFieldContainer>

                                <FormFieldContainer>
                                    <IconContainer>
                                        <SvgIcon
                                            component={LockIcon}
                                            sx={{ color: 'grey[400]' }} // ui MidTone color
                                        />
                                    </IconContainer>
                                    <TextFieldContainter>
                                        <IbssTextField
                                            id="password"
                                            name="password"
                                            label={this.labels.HubLabelPassword}
                                            placeholder={this.labels.HubLabelPassword.toLowerCase()}
                                            required={true}
                                            variant="standard"
                                            fullWidth
                                            inputProps={{
                                                type: "password",
                                                autoComplete: 'current-password'
                                            }}
                                            onChange={(event) => this.passwordChanged(event)}
                                            value={this.state.password}
                                            error={!!this.state.errors.password}
                                            helperText={this.state.errors.password}
                                        />
                                    </TextFieldContainter>
                                </FormFieldContainer>

                                <IbssButton
                                    id='login'
                                    name='login'
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    sx={{mt:2}}
                                    onClick={() => this.handleNativeEmailLogin()}
                                >{this.labels.HubLabelLogin}
                                </IbssButton>
                                <LinkContainer>
                                    <IbssLink to="#" underline="hover" onClick={() => this.forgottenPasswordClicked()}>
                                        {this.labels.HubLabelForgottenPassword}
                                    </IbssLink>
                                </LinkContainer>
                            </>
                        }
                        {
                            this.state.loginFrame === LoginProvider.ForgottenPassword && <>
                                <MessageContainer>
                                    <Typography sx={{ ...this.textStyle, color: (theme) => theme.palette.text.secondary }}>
                                        {this.labels.HubLabelRequestNewPassword}
                                    </Typography>
                                </MessageContainer>
                                <FormFieldContainer>
                                    <IconContainer>
                                        <SvgIcon
                                            component={EmailIcon}
                                            sx={{ color: 'grey[400]' }} // ui MidTone color
                                        />
                                    </IconContainer>
                                    <TextFieldContainter>
                                        <IbssTextField
                                            id='email'
                                            name='email'
                                            autoComplete= 'email'
                                            autoFocus
                                            label={this.labels.HubLabelEmail}
                                            placeholder={this.labels.HubLabelEmail.toLowerCase()}
                                            required={true}
                                            type='email'
                                            variant="standard"
                                            fullWidth
                                            onChange={(event) => this.emailChanged(event)}
                                            value={this.state.email}
                                            error={!!this.state.errors.email}
                                            helperText={this.state.errors.email}
                                        />
                                    </TextFieldContainter>
                                </FormFieldContainer>
                                <Box mt={2} width={'100%'}>
                                <IbssButton
                                    id='request-new-password'
                                    name='request-new-password'
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => this.requestNewPasswordClicked()}
                                >
                                    <Typography sx={{ fontFamily: 'Source Sans Pro', fontStyle: 'normal', fontWeight: 400, fontSize: '15px', lineHeight: '26px', letterSpacing: '0.46px' }}>
                                        {this.labels.HubLabelSubmitRequest}
                                    </Typography>
                                </IbssButton>
                                <LinkContainer>
                                    <IbssLink to="#" underline="hover" onClick={() => this.nativeLoginClicked()}>
                                        {`< ${this.labels.HubButtonBack}`}
                                    </IbssLink>
                                </LinkContainer>
                                </Box>
                            </>
                        }
                        </FormContainer>
                    </LoginContainer>
                    <ImageContainer>
                        <img style={{ maxHeight: '460px' }} src={LoginSplash} alt="Meeting location in office next to the window" />
                    </ImageContainer>
                </LoginFrame>
                <IbssFooter />

                
            </Box>
            <IbssDialog
                    id="privacyPolicy"
                    sx={{padding:"20px"}}
                    open={this.state.showPrivacyPolicyDialog}
                    fullWidth
                    dialogContent=
                    {
                    <>
                        <Box sx={{width:"100%",display:'flex',justifyContent:'center'}}>
                            <Box className="d-flex" sx={{maxWidth:"59%"}}>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', padding: '5px', gap: '10px', boxSizing: 'border-box' }}>
                                    <img src={`/images/Logos/Roamer_logo.png`} alt="Iconics Roamer logo" />
                                </Box>
                                <Box sx={{ boxSizing: 'border-box', display: "flex", flexDirection: "column", alignItems: "flex-start", padding: '0px 5px', gap: '10px', }}>
                                    <Box>
                                        <Typography sx={{ ...this.headingStyle, color: (theme) => theme.palette.text.primary }}>
                                            {this.labels.HubLabelWelcomeToHub}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{ ...this.textStyle, color: (theme) => theme.palette.text.secondary }}>
                                            {this.labels.funcPolicyModal_Subtitle}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="text-center">
                            <Box tabIndex={0}  aria-label="privacy policy and license" ref={this.boxRef} className="border-theme-light rounded text-justify my-3" onScroll={e => this.privacyPolicyScrolled(e)} sx={{ height:"calc(100vh - 475px);", overflow: 'auto', padding:1,fontSize:"13px" }} dangerouslySetInnerHTML={{__html: this.state.privacyPolicyContent}}>
                            </Box>
                            <Box className="mt-3">
                                Consent below to our <a href="#policy" className="primary-link">Privacy Policy</a> and <a href="#license" className="primary-link">EULA</a>.
                            </Box>
                        </Box>
                    </>
                    }
                    footer = 
                    {
                        <>
                            <div className="d-flex justify-content-center w-100">
                                <Box sx={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                                    <IbssButton color="primary" variant="contained"
                                            onClick={() => this.privacyPolicyAccepted()}
                                            disabled={!(this.state.enablePrivacyPolicyButtons || (this.state.scrollEnabled))}
                                        >
                                            {this.labels.funcAccept}
                                    </IbssButton>
                                    <IbssButton color="primary" variant="contained"
                                            onClick={() => this.privacyPolicyDeclined()}
                                            disabled={!(this.state.enablePrivacyPolicyButtons || (this.state.scrollEnabled))}
                                        >
                                            {this.labels.funcCateringOrderDecline_S}
                                    </IbssButton>
                                </Box>
                            </div>
                        </>
                    }
                />
            </>
        );
    }
}

interface IProps extends RouteComponentProps, PaperProps
{
}

interface IState
{
    email: string;
    password: string;
    oktaIssuer: string;
    oktaClientID: string;
    azureClientID: string;
    azureTenantID: string;
    showPasswordField: boolean;
    errors: IValidationError
    message: string;
    loginFrame: LoginProvider;
    allExternalIdentityProviders: Array<IExternalIdentityProvider>;
    showPrivacyPolicyDialog: boolean;
    enablePrivacyPolicyButtons: boolean;
    privacyPolicyContent: string;
    scrollEnabled: boolean;
}

interface IValidationError
{
    email: string;
    password: string;
}
