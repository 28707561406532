import React from "react";
import { IbssComponent } from "../../../../Components/Core/BaseComponent/IbssComponent";
import ServerDataGrid, { Column, DataGridQueryResult, IDataQuery, IPage, IPages } from "../../../../Components/Data/ServerDataGrid/ServerDataGrid";
import { DateHelper } from "../../../../Common/DateHelper";
import Helper, { getBuildingNameUsingFloorNodeId, getBuildingNodeIdUsingFloorNodeId, getBuildingTimeZoneByNodeId } from "../../../../Common/Helper";
import { Booking, MyBooking, PrimariesBooking } from "./DataModels";
import { DateTime } from "luxon";
import { appContext } from "../../../../AppContext";
import { ODataQuery } from "../../../../Providers.Api/ODataQuery";
import * as GetV2BookingsEndpoint from "../../../../Providers.Api/Bookings/GetV2BookingsEndpoint";
import { BookingFilter, BookingFilter2 } from "../../../../Providers.Api/Bookings/BookingRepository";
import { PagedResponse } from "../../../../Providers.Api/Models";
import IbssChip from "../../../../Components/Navigation/Chip/IbssChip";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { KeyValue } from "../../../../Common/KeyValue";
import * as GetMyV2BookingsForOthersEndpoint from "../../../../Providers.Api/Bookings/GetMyV2BookingsForOthersEndpoint";
import IbssIconButton from "../../../../Components/Buttons/IconButton/IbssIconButton";
import RepeatIcon from "../../../../Components/Icons/RepeatIcon";
import { InMemoryCache } from "../../../../Common/InMemoryCache";

export class DataGrid extends IbssComponent<IProps, IState>
{
    private dataGridRef = React.createRef<ServerDataGrid<BookingView>>();
    private get dataGrid() { return this.dataGridRef.current; }
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get bookingService() { return appContext().bookingService; }
    private get alert() { return appContext().alert; }

    constructor(props: IProps)
    {
        super(props);

        this.state = {
            page: {
                value: [],
                skipToken: "",
            },
        };
    }

    public refresh(): Promise<void>
    {
        return this.dataGrid?.refresh() ?? Promise.resolve();
    }

    public refreshPage(): Promise<void>
    {
        return this.dataGrid?.refreshPage() ?? Promise.resolve();
    }

    private getDateAndTime(row: BookingView, type: string): string
    {
        const buildingNodeId = getBuildingNodeIdUsingFloorNodeId(row.Node_Id);
        const getBuilding = getBuildingTimeZoneByNodeId(buildingNodeId);
        const localNow = DateTime.local();
        const currentZone = localNow.zoneName;
        if (type === 'start')
        {
            return DateTime.fromISO(row.Booking_Start, { zone: getBuilding }).toLocaleDateTimeString();
        }
        else if (type === "end")
        {
            return DateTime.fromISO(row.Booking_End, { zone: getBuilding }).toLocaleDateTimeString();
        } else
        {
            if (currentZone === getBuilding) 
            {
                return this.labels.HubLabelLocalTime
            }
            else 
            {
                // Get the current time in the specified timezone
                const dateTime = DateTime.local().setZone(getBuilding);

                const utcOffsetMinutes = dateTime.offset;

                const utcOffsetHours = utcOffsetMinutes / 60;

                return `UTC ${utcOffsetHours > 0 ? "+" + utcOffsetHours : utcOffsetHours}`
            }
        }
    }

    private getRelationshipType(type: string): string
    {
        if (type === "BookingRelationTypeMain")
        {
            return this.labels.dataBookingMain_S;
        }
        else if (type === "BookingRelationTypeSetup")
        {
            return this.labels.dataBookingCreateSetup_S;
        }
        else if (type === "BookingRelationTypeTearDown")
        {
            return this.labels.dataBookingCreateReset_S;
        }
        else
        {
            return "";
        }
    }

    private async handleDataQueryChange(gridQuery: IDataQuery): Promise<DataGridQueryResult<BookingView>>
    {
        try
        {
            this.props.onLoadingChange(true);
            const startDate = DateTime.fromJSDate(this.props.bookingStartDate).setZoneByNode(this.props.buildingid).startOf('day');
            const endDate = DateTime.fromJSDate(this.props.bookingEndDate).setZoneByNode(this.props.buildingid).plus({ days: 1 }).startOf('day');
            const odataQuery = new ODataQuery({ top: gridQuery.pageSize, skipToken: gridQuery.skipToken, orderBy: Helper.nameOf<GetV2BookingsEndpoint.Booking>("Booking_Start") });

            if (this.props.isPrimariesBookings)
            {
                return this.loadPrimariesBookings(gridQuery, odataQuery, startDate, endDate);
            }
            else if (this.props.isMyBookings)
            {
                return this.loadMyBookings(gridQuery, odataQuery, startDate, endDate);
            }
            else if (this.props.isMyBookingsForOthers) 
            {
                return this.loadMyBookingsForOthers(gridQuery, odataQuery, startDate, endDate);
            }
            else 
            {
                return this.loadOneLensBookings(gridQuery, odataQuery, startDate, endDate);
            }
        }
        finally
        {
            this.props.onLoadingChange(false);
        }
    }

    private async loadPrimariesBookings(gridQuery: IDataQuery, odataQuery: ODataQuery, startDate: DateTime, endDate: DateTime): Promise<DataGridQueryResult<BookingView>>
    {
        const bookingStatus = this.props.bookingStatuses[0] ? this.props.bookingStatuses[0] : ''; // in flex, users can filter by only one bookingStatus

        odataQuery.filter = new BookingFilter({
            baseFilter: odataQuery.filter,
            bookingStatus: (bookingStatus === "" || bookingStatus === "Any" ? "" : bookingStatus),
            bookingStatusNot: (bookingStatus === "" ? ['Cancelled', 'No Show', 'Auto Cancelled'] : []),
            createdBy: this.props.selectedUserBookedByObjectEmail,
            spaceName: this.props.selectedSpaceOptionValue ?? "",
        });

        const endpoint = {
            name: 'ibssApiClientV2.v2.byNodeid.bookings.myPrimariesBookings.get',
            options: {
                nodeId: 1,
                top: gridQuery.pageSize,
                skipToken: gridQuery.skipToken,
                select: PrimariesBooking,
                filter: odataQuery.toODataFilter(' and ', `Booking_Start ge datetime'${startDate.toUTC().toISO()}' and Booking_End lt datetime'${endDate.toUTC().toISO()}'`),
                orderBy: Helper.nameOf<MyBooking>("Booking_Start"),
                primary: this.props.selectedPrimary === '-1' ? undefined : this.props.selectedPrimary
            }
        };

        const pagedBookings = await appContext().inMemoryCache.lazyGetWithQuickExpiry(JSON.stringify(endpoint), () => appContext().ibssApiClientV2.v2.byNodeid.bookings.myPrimariesBookings.get<PagedResponse<MyBooking[]>>(endpoint.options));
        const email = this.local.getUserDetails().email.toLowerCase();
        const displayName = this.local.getUserDetails().displayName;
        const bookingsView = pagedBookings.value.map(i => BookingView.fromMyBookingsResponse(i, email, displayName));
        return new DataGridQueryResult(bookingsView, pagedBookings.skipToken);
    }

    private async loadMyBookings(gridQuery: IDataQuery, odataQuery: ODataQuery, startDate: DateTime, endDate: DateTime): Promise<DataGridQueryResult<BookingView>>
    {
        const bookingStatus = this.props.bookingStatuses[0] ? this.props.bookingStatuses[0] : ''; // in flex, users can filter by only one bookingStatus

        odataQuery.filter = new BookingFilter({
            baseFilter: odataQuery.filter,
            bookingStatus: (bookingStatus === "" || bookingStatus === "Any" ? "" : bookingStatus),
            bookingStatusNot: (bookingStatus === "" ? ['Cancelled', 'No Show', 'Auto Cancelled'] : []),
            createdBy: this.props.selectedUserBookedByObjectEmail,
            spaceName: this.props.selectedSpaceOptionValue ?? "",
        });

        const endpoint = {
            name: 'ibssApiClientV2.v2.byNodeid.bookings.myBookings.get',
            options: {
                nodeId: 1,
                select: MyBooking,
                filter: odataQuery.toODataFilter(' and ', `Booking_Start ge datetime'${startDate.toUTC().toISO()}' and Booking_End lt datetime'${endDate.toUTC().toISO()}'`),
                top: gridQuery.pageSize,
                skipToken: gridQuery.skipToken,
                orderBy: Helper.nameOf<MyBooking>("Booking_Start"),
            }
        };

        const pagedBookings = await appContext().inMemoryCache.lazyGetWithQuickExpiry(JSON.stringify(endpoint), () => appContext().ibssApiClientV2.v2.byNodeid.bookings.myBookings.get<PagedResponse<MyBooking[]>>(endpoint.options));
        const email = this.local.getUserDetails().email.toLowerCase();
        const displayName = this.local.getUserDetails().displayName;
        const bookingsView = pagedBookings.value.map(i => BookingView.fromMyBookingsResponse(i, email, displayName));
        return new DataGridQueryResult(bookingsView, pagedBookings.skipToken);
    }

    private async loadMyBookingsForOthers(gridQuery: IDataQuery, odataQuery: ODataQuery, startDate: DateTime, endDate: DateTime): Promise<DataGridQueryResult<BookingView>>
    {
        const bookingStatus = this.props.bookingStatuses[0] ? this.props.bookingStatuses[0] : ''; // in flex, users can filter by only one bookingStatus
        odataQuery.filter = new BookingFilter
            ({
                baseFilter: odataQuery.filter,
                bookingStatus: (bookingStatus === "" || bookingStatus === "Any" ? "" : bookingStatus),
                bookingOwnerEmail: this.props.selectedUserBookedForObjectEmail,
                spaceName: this.props.selectedSpaceOptionValue ?? "",
            });

        const email = this.local.getUserDetails().email.toLowerCase();

        const endpoint = {
            name: 'bookingService.getBookingsForOthers',
            options: [odataQuery, email, startDate, endDate],
        };

        const pagedBookings = await appContext().inMemoryCache.lazyGetWithQuickExpiry(JSON.stringify(endpoint), () => this.bookingService.getBookingsForOthers(...endpoint.options as [ODataQuery, string, DateTime, DateTime]));
        const bookingsView = pagedBookings.value.map(i => BookingView.fromMyBookingsForOthersResponse(i, email));
        return new DataGridQueryResult(bookingsView, pagedBookings.skipToken);
    }

    private async loadOneLensBookings(gridQuery: IDataQuery, odataQuery: ODataQuery, startDate: DateTime, endDate: DateTime): Promise<DataGridQueryResult<BookingView>>
    {
        odataQuery.nodeId = this.props.buildingid;

        odataQuery.filter = new BookingFilter2({
            baseFilter: odataQuery.filter,
            // statuses: (this.state.bookingStatus == "" || this.state.bookingStatus == "Any" ? undefined : [ this.state.bookingStatus ]), // - todo remove redundant code.
            statuses: (this.props.bookingStatuses.length === 0 || this.props.bookingStatuses.includes("Any") ? undefined : this.props.bookingStatuses),
            relationships: (this.props.relationshipStatuses.length === 0 ? undefined : this.props.relationshipStatuses),
            bookingOwnerEmail: this.props.selectedUserBookedForObjectEmail,
            createdBy: this.props.selectedUserBookedByObjectEmail,
            spaceName: this.props.selectedSpaceOptionValue ?? "",
        });

        const startTimeAsString = startDate?.toUtcByNode(this.props.buildingid)?.toISO() ?? "";
        const endTimeAsString = endDate?.toUtcByNode(this.props.buildingid)?.toISO() ?? "";
        const bookingStartStr = startTimeAsString ? `Booking_Start ge datetime'${startTimeAsString}'` : '';
        const bookingEndStr = endTimeAsString ? `Booking_End lt datetime'${endTimeAsString}'` : '';

        const filterQueryStr = this.props.filterQuery && this.props.selectedFilterId !== '-1' ? `(${this.props.filterQuery})` : '';
        let filters = [odataQuery.toODataFilter('', ''), bookingStartStr, bookingEndStr, filterQueryStr].filter(filterQuery => filterQuery !== '').join(' and ');

        if (this.props.selectedBookingZone !== 'Any')
        {
            filters = `${filters} and Meta_Loc_Zone eq '${this.props.selectedBookingZone}'`
        }

        const endpoint = {
            name: 'ibssApiClientV2.v2.byNodeid.bookings.get',
            options: {
                nodeId: this.props.buildingid,
                top: gridQuery.pageSize,
                skipToken: gridQuery.skipToken,
                select: Booking,
                filter: filters,
                orderBy: Helper.nameOf<MyBooking>("Booking_Start"),
            }
        };

        const response = await appContext().inMemoryCache.lazyGetWithQuickExpiry(JSON.stringify(endpoint), () => appContext().ibssApiClientV2.v2.byNodeid.bookings.get<PagedResponse<Booking[]>>(endpoint.options));
        const bookingsView = response.value.map(i => BookingView.fromBookingsResponse(i));
        return new DataGridQueryResult(bookingsView, response.skipToken);
    }

    private handleSelectionChange(selection: GridRowSelectionModel): void
    {
        const pageOfRows = this.dataGrid?.rows as BookingView[];
        const selectedRows = pageOfRows.filter(row => selection.includes(row.Booking_Id))
        this.props.onRowSelectionChange(selectedRows);

        let isUserInApprovalGroup: boolean;
        if (this.props.isPrimariesBookings)
        {
            isUserInApprovalGroup = true;
        }
        else
        {
            isUserInApprovalGroup = selectedRows.every(row =>
            {
                const approvalGroups = JSON.parse(row.approvalGroups);
                if (!approvalGroups || approvalGroups.length === 0)
                {
                    return false;
                }
                return approvalGroups.some((group: string) => this.props.currentUsersRoleIds.includes(group));
            });
        }


        const cancelRows: BookingView[] = [];
        const approveRows: BookingView[] = [];
        const rejectRows: BookingView[] = [];
        const checkInRows: BookingView[] = [];
        const checkOutRows: BookingView[] = [];
        const selectAllRows: BookingView[] = [];

        for (let i = 0; i <= selectedRows.length - 1; i++)
        {
            const rowStatus = selectedRows[i]["Booking_Status"];
            const rowApproved = selectedRows[i]["Booking_IsApproved"];
            if (this.props.isMyBookingsForOthers)
            {
                if (rowStatus === "New" || rowStatus === "Amended") 
                {
                    selectAllRows.push(selectedRows[i])
                }
            } else
            {
                if ((rowStatus === "New" && rowApproved === 0) ||
                    (rowStatus === "Amended" && rowApproved === 0) || //Awaiting Approval
                    (rowStatus === "New" && rowApproved === 3) ||
                    (rowStatus === "New" && rowApproved === 4) || //Approved
                    (rowStatus === "Late Checkin" && rowApproved === 3) ||
                    (rowStatus === "Late Checkin" && rowApproved === 4) || //Late Check in
                    (rowStatus === "Amended" && rowApproved === 3) ||
                    (rowStatus === "Amended" && rowApproved === 4) //Amended
                )
                {

                    cancelRows.push(selectedRows[i])
                }
            }

        }
        for (let j = 0; j <= selectedRows.length - 1; j++)
        {
            const rowStatus = selectedRows[j]["Booking_Status"];
            const rowApproved = selectedRows[j]["Booking_IsApproved"];
            const rowType = selectedRows[j]["Parent_Booking_Relation_Type"];
            if (((rowStatus === "New" && rowApproved === 0) ||
                (rowStatus === "Amended" && rowApproved === 0)) && rowType === "BookingRelationTypeMain")
            {
                approveRows.push(selectedRows[j])
            }
        }
        for (let k = 0; k <= selectedRows.length - 1; k++)
        {
            const rowStatus = selectedRows[k]["Booking_Status"];
            const rowApproved = selectedRows[k]["Booking_IsApproved"];
            const rowType = selectedRows[k]["Parent_Booking_Relation_Type"];
            if (((rowStatus === "New" && rowApproved === 0) ||
                (rowStatus === "Amended" && rowApproved === 0)) && rowType === "BookingRelationTypeMain")
            {
                rejectRows.push(selectedRows[k])
            }
        }
        for (let l = 0; l <= selectedRows.length - 1; l++)
        {
            const rowStatus = selectedRows[l]["Booking_Status"];
            const rowApproved = selectedRows[l]["Booking_IsApproved"];
            const rowEarlyCheckin = selectedRows[l]["Booking_Early_Checkin"];
            if ((((rowStatus === "New" || rowStatus === "Amended" || rowStatus === "Late Checkin") && rowApproved === 3) && DateTime.fromISO(rowEarlyCheckin) < DateTime.utc()) ||
                ((rowStatus === "New" || rowStatus === "Amended" || rowStatus === "Late Checkin") && rowApproved === 4 && DateTime.fromISO(rowEarlyCheckin) < DateTime.utc()))
            {
                checkInRows.push(selectedRows[l])
            }
        }
        for (let m = 0; m <= selectedRows.length - 1; m++)
        {
            const rowStatus = selectedRows[m]["Booking_Status"];
            const rowApproved = selectedRows[m]["Booking_IsApproved"];

            if ((rowStatus === "In Progress" && rowApproved === 3) ||
                (rowStatus === "In Progress" && rowApproved === 4) ||
                (rowStatus === "Amended" && selectedRows[m]["Booking_IsCheckedIn"] === 1) ||
                (rowStatus === "Early Check In"))
            {
                checkOutRows.push(selectedRows[m]);
            }
        }

        if (selectedRows.length <= 0 && cancelRows.length <= 0)
        {
            this.props.onCanCancelChange(true);
        }
        else if (selectedRows.length === cancelRows.length && this.props.isCancel)
        {
            const otherRecordsAvailable = selectedRows.find((row) => row.Parent_Booking_Relation_Type !== 'BookingRelationTypeMain');
            if (otherRecordsAvailable && this.props.isCancel && !(this.props.isMyBookings || this.props.isMyBookingsForOthers))
            {
                this.props.onCanCancelChange(true);
            }
            else
            {
                this.props.onCanCancelChange(false);
            }
        }
        else
        {
            this.props.onCanCancelChange(true);
        }

        if (selectedRows.length <= 0 && approveRows.length <= 0)
        {
            this.props.onCanAproveChange(true);
        }
        else if (selectedRows.length === approveRows.length && this.props.isApproveRight && isUserInApprovalGroup)
        {
            this.props.onCanAproveChange(false);
        }
        else
        {
            this.props.onCanAproveChange(true);
        }

        if (selectedRows.length <= 0 && rejectRows.length <= 0 && this.props.isDenyRight)
        {
            this.props.onCanRejectChange(true);
        }
        else if (selectedRows.length === rejectRows.length && this.props.isDenyRight && isUserInApprovalGroup)
        {
            this.props.onCanRejectChange(false);
        }
        else
        {
            this.props.onCanRejectChange(true);
        }

        if (selectedRows.length <= 0 && checkInRows.length <= 0)
        {
            this.props.onCanCheckInChange(true);
        }
        else if (selectedRows.length === checkInRows.length && this.props.isCheckIn)
        {
            this.props.onCanCheckInChange(false);
        }
        else
        {
            this.props.onCanCheckInChange(true);
        }
        if (selectedRows.length <= 0 && checkOutRows.length <= 0)
        {
            this.props.onCanCheckOutChange(true);
        }
        else if (selectedRows.length === checkOutRows.length && this.props.isCheckOut)
        {
            this.props.onCanCheckOutChange(false);
        }
        else
        {
            this.props.onCanCheckOutChange(true);
        }

        if (selectedRows.length <= 0 && selectAllRows.length <= 0)
        {
            this.props.onCanCancelAllChange(true);
        }
        else if (selectedRows.length === selectAllRows.length && this.props.isCancel)
        {
            this.props.onCanCancelAllChange(false);
        }
        else
        {
            this.props.onCanCancelAllChange(true);
        }
    }

    private seriesBookingSelected(row: BookingView): void
    {
        this.props.onSeriesBookingSelected(row);
    }

    private async checkOutOneClicked(data: BookingView): Promise<void>  
    {
        const bookingOwner = this.props.isMyBookingsForOthers ? data.Booking_Owner_Name : data.Booking_Owner_Email;
        try
        {
            this.props.onLoadingChange(true);
            const spaceId = data.Space_Id.split(';')[0];
            let body = {
                BookingOwnerEmail: bookingOwner
            }
            await appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.checkout.patch({
                nodeId: data.Node_Id,
                id: spaceId,
                body: body,
            })
            this.props.onLoadingChange(false);
            this.alert.show("", this.labels.HubLabelBookingCheckOutSuccessfully);
            await this.dataGrid?.refreshPage();
        }
        catch
        {
            this.props.onLoadingChange(false);
        }
    }

    public async checkInOneClicked(): Promise<void>
    {
        const booking = this.props.rowSelectionModel[0];
        try
        {
            this.props.onLoadingChange(true);
            const spaceId = booking.Space_Id.split(';')[0];
            let body = {
                BookingOwnerEmail: booking.Booking_Owner_Email
            }
            await appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.checkin.patch({
                nodeId: booking.Node_Id,
                id: spaceId,
                body: body,
            })
            this.props.onLoadingChange(false);
            this.alert.show("", this.labels.HubLabelBookingCheckInSuccessfully);
            await this.dataGrid?.refreshPage();
        }
        catch
        {
            this.props.onLoadingChange(false);
        }
    }

    private async approveOneClicked(data: BookingView): Promise<void>  
    {
        try
        {
            this.props.onLoadingChange(true);
            await appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.approve.patch<void>({
                nodeId: data.Node_Id!,
                bookingid: data.Booking_Id
            })
            this.props.onLoadingChange(false);
            await this.dataGrid?.refreshPage();
        }
        catch (error)
        {
            this.props.onLoadingChange(false);
        }
    }

    private async rejectOneClicked(data: BookingView): Promise<void>  
    {
        try
        {
            this.props.onLoadingChange(true);
            appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.deny.patch({
                nodeId: data.Node_Id,
                bookingid: data.Booking_Id
            })
            this.alert.show("", this.labels.HubLabelBookingsRejectedSuccesfullyMsg);
            this.props.onLoadingChange(false);
            await this.dataGrid?.refreshPage();
        }
        catch
        {
            this.props.onLoadingChange(false);
        }
    }

    private async cancelOneClicked(data: BookingView): Promise<void>  
    {
        this.props.onCancelBooking();
    }

    public get dataGridColumns(): Column<BookingView>[]
    {
        const columnWidth = 130;
        // for the server field names, there is an assumption that all 3 endpoints (my bookings, my bookings for others and one lens bookins) use the same field names
        const dataGridColumns: Column<BookingView>[] =
            this.props.isPrimariesBookings ? [
                {
                    headerName: this.labels.HubLabelBookedBy,
                    field: "_CreatedBy",
                    minWidth: columnWidth,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelBookingStart,
                    field: "Booking_Start",
                    minWidth: columnWidth,
                    flex: 1,
                    valueGetter: (params) => this.getDateAndTime(params.row, 'start'),
                },
                {
                    headerName: this.labels.HubLabelBookingEnd,
                    field: "Booking_End",
                    minWidth: columnWidth,
                    flex: 1,
                    valueGetter: (params) => this.getDateAndTime(params.row, 'end'),
                },
                {
                    headerName: this.labels.HubLabelBookingName,
                    field: "Booking_Name",
                    minWidth: columnWidth,
                    flex: 1,
                    filterable: false,
                },
                {
                    headerName: this.labels.HubLabelSpace,
                    field: "Space_Name",
                    minWidth: columnWidth,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelAction,
                    minWidth: columnWidth,
                    field: "",
                    flex: 1,
                    filterable: false,
                    sortable: false,
                    renderCell: (params) =>
                        <>
                            {this.renderActionButtonFromBooking(params.row)}
                        </>
                },
                {
                    headerName: "Status",
                    minWidth: columnWidth,
                    flex: 1,
                    field: "status",
                    renderCell: (params) => (this.props.isMyBookingsForOthers || this.props.isMyBookings) ? this.flexBookingStatus(params.row) : this.renderBookingOverAllStatus(params.row),
                    valueGetter: (params) => params.row.Booking_Status,
                    filterable: false,
                    sortable: false
                }
            ] : [
                ...(this.props.isMyBookings || this.props.isMyBookingsForOthers ? [{
                    headerName: this.labels.HubLabelBuilding,
                    field: "Building_Name",
                    minWidth: columnWidth,
                    flex: 1,
                }] : []),
                {
                    headerName: this.labels.HubLabelBookingName,
                    field: "Booking_Name",
                    minWidth: 200,
                    flex: 1,
                    filterable: false,
                    renderCell: param => (
                        <div className="d-flex">
                            {
                                param.row.BookingSeriesId != "" &&
                                <div style={{ marginTop: '7px', fontSize: '18px', marginRight: '3px' }}>
                                    <RepeatIcon />
                                </div>
                            }
                            <div style={{ alignContent: 'center' }}>{param.row.Booking_Name}</div>
                        </div>
                    )
                },
                ...(!(this.props.isMyBookings || this.props.isMyBookingsForOthers) ? [{
                    headerName: this.labels.funcType_S,
                    field: "Parent_Booking_Relation_Type",
                    valueGetter: (params: { row: BookingView }) => this.getRelationshipType(params.row.Parent_Booking_Relation_Type),
                    minWidth: columnWidth,
                    flex: 1,
                    filterable: false,
                }] : []),
                {
                    headerName: this.labels.HubLabelBookingStart,
                    field: "Booking_Start",
                    minWidth: columnWidth,
                    flex: 1,
                    valueGetter: (params) => this.getDateAndTime(params.row, 'start'),
                },
                {
                    headerName: this.labels.HubLabelBookingEnd,
                    field: "Booking_End",
                    minWidth: columnWidth,
                    flex: 1,
                    valueGetter: (params) => this.getDateAndTime(params.row, 'end'),
                },
                {
                    headerName: this.labels.HubLabelTimeZone,
                    field: "timeZone",
                    minWidth: columnWidth,
                    flex: 1,
                    valueGetter: (params) => this.getDateAndTime(params.row, 'timezone'),
                },
                ...(!this.props.isMyBookings && !this.props.isMyBookingsForOthers ? [{
                    headerName: this.labels.HubLabelZone,
                    field: Helper.nameOf<BookingView>('locationZone'),
                    minWidth: columnWidth,
                    flex: 1,
                },
                ] : []),
                {
                    headerName: this.labels.HubLabelSpace,
                    field: "Space_Name",
                    minWidth: columnWidth,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelBookedFor,
                    field: "Booking_Owner_Name",
                    minWidth: columnWidth,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelBookedBy,
                    field: "_CreatedBy",
                    minWidth: columnWidth,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelAction,
                    minWidth: columnWidth,
                    field: "",
                    flex: 1,
                    filterable: false,
                    sortable: false,
                    renderCell: (params) =>
                        <>
                            {this.props.isMyBookings && this.renderCheckInCheckOutActionButton(params.row)}
                            {this.props.isMyBookingsForOthers && this.renderCancelAllActionButton(params.row)}
                            {!this.props.isMyBookings && !this.props.isMyBookingsForOthers && !this.props.isPrimariesBookings && this.renderActionButtonFromBooking(params.row)}
                        </>
                },
                {
                    headerName: "Status",
                    minWidth: columnWidth,
                    flex: 1,
                    field: "status",
                    renderCell: (params) => (this.props.isMyBookingsForOthers || this.props.isMyBookings) ? this.flexBookingStatus(params.row) : this.renderBookingOverAllStatus(params.row),
                    valueGetter: (params) => params.row.Booking_Status,
                    filterable: false,
                    sortable: false
                }];

        return dataGridColumns;
    }

    public render(): JSX.Element
    {
        const props = this.props;
        return (
            <ServerDataGrid
                ref={this.dataGridRef}
                loadDataOnMount={false}
                checkboxSelection
                columns={this.dataGridColumns}
                disableRowSelectionOnClick
                pageSizeOptions={[this.props.pageSize]}
                pages={this.props.pages}
                onDataQueryChange={i => this.handleDataQueryChange(i)}
                onPagesChange={pages => this.props.onPagesChange(pages)}
                onRowSelectionModelChange={e => this.handleSelectionChange(e)}
            />
        );
    }

    private renderBookingOverAllStatus(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        const bookingApproval = row.Booking_IsApproved;
        if ((bookingStatus === "New" && bookingApproval === 0) ||
            (bookingStatus === "Amended" && bookingApproval === 0))
        {
            return <IbssChip label={this.labels.HubTabAwaitingApproval} sx={{
                backgroundColor: 'var(--ui-error-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "New" && bookingApproval === 3) ||
            (bookingStatus === "New" && bookingApproval === 4))
        {
            return <IbssChip label={this.labels.HubLabelApproved} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "Early Check In" && bookingApproval === 3) ||
            (bookingStatus === "Early Check In" && bookingApproval === 4) ||
            (this.props.isMyBookings && bookingStatus === "Early Check In"))
        {
            return <IbssChip label={this.labels.HublabelEarlyCheckInText} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "Late Checkin" && bookingApproval === 3) ||
            (bookingStatus === "Late Checkin" && bookingApproval === 4) ||
            (this.props.isMyBookings && bookingStatus === "Late Checkin"))
        {
            return <IbssChip label={this.labels.HublabelLateCheckIn} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "Amended" && bookingApproval === 3) ||
            (bookingStatus === "Amended" && bookingApproval === 4) ||
            (this.props.isMyBookings && bookingStatus === "Amended"))
        {
            return <IbssChip label={this.labels.HubLabelamended} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "In Progress" && bookingApproval === 3) ||
            (bookingStatus === "In Progress" && bookingApproval === 4) ||
            (this.props.isMyBookings && bookingStatus === "In Progress"))
        {
            return <IbssChip label={this.labels.HubLabelInProgress} sx={{
                backgroundColor: 'var(--ui-mid-tone)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelCancelled} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (bookingStatus === "Auto Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelAutoCancelledStatus} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (bookingStatus === "Completed")
        {
            return <IbssChip label={this.labels.HubLabelCompleted} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "No Show")
        {
            return <IbssChip label={this.labels.HubLabelNoShow} sx={{
                backgroundColor: 'var(ui-error-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (this.props.isMyBookings && bookingStatus === "New")
        {
            return <IbssChip label={this.labels.HubLabelNew} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }

    private flexBookingStatus(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        if (bookingStatus === "New")
        {
            return <IbssChip label={this.labels.HubLabelNew} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "Amended")
        {
            return <IbssChip label={this.labels.HubLabelamended} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "Early Check In")
        {
            return <IbssChip label={this.labels.HublabelEarlyCheckInText} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "Late Checkin")
        {
            return <IbssChip label={this.labels.HublabelLateCheckIn} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "In Progress")
        {
            return <IbssChip label={this.labels.HubLabelInProgress} sx={{
                backgroundColor: 'var(--ui-mid-tone)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "No Show")
        {
            return <IbssChip label={this.labels.HubLabelNoShow} sx={{
                backgroundColor: 'var(ui-error-pastel)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Auto Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelAutoCancelledStatus} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Completed")
        {
            return <IbssChip label={this.labels.HubLabelCompleted} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(ui-text)",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }

    private renderActionButton(row: BookingView, disabled: boolean, hanldeFunction: () => Promise<void>, label: string): JSX.Element
    {
        return (
            <>
                <IbssButtonRedo tabIndex={0} disabled={!disabled} onClick={() => hanldeFunction()} className="text-link">{label}</IbssButtonRedo>
                {this.props.isUpdateRight ?
                    <IbssSvgIcon tabIndex={0} className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.props.isMyBookings ? this.props.onCreateBookingForMe(row.Booking_Id, row.Space_Id, row.Node_Id) : this.props.onUpdateBooking(row.Booking_Id, row.Space_Id)}>
                        <CreateIcon />
                    </IbssSvgIcon> : null
                }
            </>
        )
    }

    private renderActionButtonForOthers(row: BookingView, disabled: boolean, hanldeFunction: () => Promise<void>, label: string): JSX.Element
    {
        return (
            <>
                <IbssButtonRedo disabled={!disabled} onClick={() => hanldeFunction()} className="text-link">{label}</IbssButtonRedo>
                {this.props.isUpdateRight ?
                    <IbssSvgIcon className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.props.onCreateBookingForOther(row.Booking_Id, row.Space_Id, row.Node_Id)}>
                        <CreateIcon />
                    </IbssSvgIcon> : <p>-</p>
                }
            </>
        )
    }

    private renderActionButtonFromBooking(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        const bookingApproval = row.Booking_IsApproved;
        const bookingCheckedIn = row.Booking_IsCheckedIn == 1;
        const bookingIsActive = row.Booking_IsActive == 1;
        const bookingIsCancelled = row.Booking_IsCancelled == 1;
        const bookingIsComplete = row.Booking_CompletedTime?.isValid;
        const bookingTypeIsMain = row.Parent_Booking_Relation_Type === "BookingRelationTypeMain"

        let isUserInApprovalGroup: boolean;
        if (this.props.isPrimariesBookings)
        {
            isUserInApprovalGroup = true;
        }
        else
        {
            try
            {
                const approvalGroups = JSON.parse(row.approvalGroups);
                isUserInApprovalGroup = approvalGroups.some((group: string) => this.props.currentUsersRoleIds.includes(group));
            }
            catch
            {
                isUserInApprovalGroup = false;
            }
        }

        if (bookingCheckedIn && bookingIsActive && !bookingIsCancelled && !bookingIsComplete)
        {
            return this.renderActionButton(row, this.props.isCheckOut, () => this.checkOutOneClicked(row), this.labels.HubButtonCheckOut)
        }
        else if (bookingTypeIsMain && (bookingStatus === 'New' && bookingApproval === 0) || (bookingStatus === "Amended" && bookingApproval === 0))
        {
            if (this.props.isApproveRight && isUserInApprovalGroup)
            {
                return this.renderActionButton(row, this.props.isApproveRight, () => this.approveOneClicked(row), this.labels.HubLabelApprove)
            }
            else if (this.props.isDenyRight && isUserInApprovalGroup)
            {
                return this.renderActionButton(row, this.props.isDenyRight, () => this.rejectOneClicked(row), this.labels.HubLabelReject)
            }
        }
        else if ((bookingStatus === 'Amended' && bookingApproval === 0) || (bookingStatus === "Amended" && bookingApproval === 3) ||
            (bookingStatus === "New" && bookingApproval === 3) || (bookingStatus === "New" && bookingApproval === 4))
        {
            return this.renderActionButton(row, this.props.isCancel, () => this.cancelOneClicked(row), this.labels.HubButtonCancel)
        }
        else
        {
            return (
                <>
                    {/* <IbssButtonRedo className="text-link">-</IbssButtonRedo> */}
                    {this.props.isUpdateRight ?
                        <IbssIconButton aria-label={bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? "View Booking" : "Edit Booking"} className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.props.onUpdateBooking(row.Booking_Id, row.Space_Id)}>
                            {bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? <RemoveRedEyeIcon /> : <CreateIcon />}
                        </IbssIconButton> : null
                    }
                </>
            )
        }
    }

    private renderCheckInCheckOutActionButton(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        const bookingApproval = row.Booking_IsApproved;
        const bookingCheckedIn = row.Booking_IsCheckedIn == 1;

        if (bookingCheckedIn && (bookingStatus == "Amended" || bookingStatus == "Checked In"))
        {
            return this.renderActionButton(row, this.props.isCheckOut, () => this.checkOutOneClicked(row), this.labels.HubButtonCheckOut)
        } else if ((bookingStatus === 'Amended' && bookingApproval === 0) || (bookingStatus === "Amended" && bookingApproval === 3))
        {
            return this.renderActionButton(row, this.props.isCancel, () => this.cancelOneClicked(row), this.labels.HubButtonCancel)
        }
        else
        {
            return (
                <>
                    {this.props.isUpdateRight ?
                        <IbssIconButton aria-label={bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? "View Booking" : "Edit Booking"} className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.props.onCreateBookingForMe(row.Booking_Id, row.Space_Id, row.Node_Id)}>
                            {bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? <RemoveRedEyeIcon /> : <CreateIcon />}
                        </IbssIconButton> : null
                    }
                </>
            )
        }
    }

    private renderCancelAllActionButton(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        if ((bookingStatus === 'New') || (bookingStatus === "Amended"))
        {
            return this.renderActionButtonForOthers(row, this.props.isCancel, () => this.cancelOneClicked(row), this.labels.HubButtonCancel)
        }
        if ((bookingStatus === 'In Progress') || (bookingStatus === "Early Check In"))
        {
            return this.renderActionButtonForOthers(row, this.props.isCheckOut, () => this.checkOutOneClicked(row), this.labels.HubButtonCheckOut)
        }
        else
        {
            return (
                <>
                    {this.props.isUpdateRight ?
                        <IbssIconButton aria-label={bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? "View Booking" : "Edit Booking"} className="pointer" onClick={() => row.BookingSeriesId?.length > 0 ? this.seriesBookingSelected(row) : this.props.onCreateBookingForOther(row.Booking_Id, row.Space_Id, row.Node_Id)}>
                            {bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? <RemoveRedEyeIcon /> : <CreateIcon />}
                        </IbssIconButton> : null
                    }
                </>
            )
        }
    }
}

export interface IProps
{
    pageSize: number;
    buildingid: number;
    searchTerm: string;
    bookingStartDate: Date;
    bookingEndDate: Date;
    isMyBookings: boolean;
    isMyBookingsForOthers: boolean;
    isPrimariesBookings: boolean;
    bookingStatuses: string[];
    selectedUserBookedByObjectEmail: string;
    selectedSpaceOptionValue?: string;
    selectedPrimary: string;
    selectedUserBookedForObjectEmail: string;
    relationshipStatuses: string[];
    filterQuery: string | null;
    selectedFilterId: string;
    selectedBookingZone: string;
    currentUsersRoleIds: string[];
    isCancel: boolean;
    isCheckIn: boolean;
    isCheckOut: boolean;
    isApproveRight: boolean;
    isDenyRight: boolean;
    isUpdateRight: boolean;
    rowSelectionModel: BookingView[];
    pages: IPages<BookingView>;

    onLoadingChange: (value: boolean) => void;
    onPagesChange: (pages: IPages<BookingView>) => Promise<void>;
    onRowSelectionChange: (value: BookingView[]) => void;
    onCanCancelChange: (value: boolean) => void;
    onCanCancelAllChange: (value: boolean) => void;
    onCanAproveChange: (value: boolean) => void;
    onCanRejectChange: (value: boolean) => void;
    onCanCheckInChange: (value: boolean) => void;
    onCanCheckOutChange: (value: boolean) => void;
    onCancelBooking: () => void;
    onCreateBookingForMe: (bookingId: string, spaceId: string, nodeId: number) => void;
    onCreateBookingForOther: (bookingId: string, spaceId: string, nodeId: number) => void;
    onUpdateBooking: (bookingId: string, spaceId: string) => void;
    onSeriesBookingSelected: (row: BookingView) => void;
}

export interface IState
{
    page: PagedResponse<GetV2BookingsEndpoint.Booking[]> | PagedResponse<GetMyV2BookingsForOthersEndpoint.Booking[]> | PagedResponse<MyBooking[]> | PagedResponse<PrimariesBooking[]>;
}

export class BookingView
{
    public id = "";
    public Building_Name = "";
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Start = "";
    public Booking_Early_Checkin = "";
    public Booking_End = "";
    public Booking_Status = "";
    public Booking_IsActive = 0;
    public Booking_IsApproved = 0;
    public Booking_IsCheckedIn?= 0;
    public Booking_IsCancelled?= 0;
    public Booking_CompletedTime?= DateHelper.null();
    //todo: public Booking_Participant_Email?= "";
    public Booking_Owner_Email = "";
    public Booking_Owner_Name = "";
    public _CreatedAt = "";
    public _CreatedBy = "";
    public BookingSeriesId = "";
    public Parent_Booking_Relation_Type = "";
    public locationZone = "";
    public approvalGroups = "";

    public static fromMyBookingsResponse(booking: MyBooking, bookingOwnerEmail: string, defaultBookingOwnerName: string): BookingView
    {
        return {
            id: booking.Booking_Id,
            Building_Name: getBuildingNameUsingFloorNodeId(booking.Node_Id),
            Node_Id: booking.Node_Id,
            Space_Id: booking.Space_Id,
            Space_Name: booking.Space_Name,
            Booking_Id: booking.Booking_Id,
            Booking_Name: booking.Booking_Name,
            Booking_Start: booking.Booking_Start.toISO(),
            Booking_Early_Checkin: typeof (booking.Booking_Early_Checkin) === 'string' ? booking.Booking_Early_Checkin : booking.Booking_Early_Checkin.toISO(),
            Booking_End: booking.Booking_End.toISO(),
            Booking_Status: booking.Booking_Status,
            Booking_IsActive: 1,
            Booking_IsApproved: 4,
            Booking_IsCheckedIn: booking.Booking_Participant_CheckedIn,
            Booking_Owner_Email: bookingOwnerEmail,
            Booking_Owner_Name: (booking.Booking_Owner_Name ? booking.Booking_Owner_Name : defaultBookingOwnerName),
            _CreatedAt: typeof (booking._CreatedAt) === 'string' ? booking._CreatedAt : booking._CreatedAt.toISO(),
            _CreatedBy: booking.CreatedBy ? booking.CreatedBy : booking.Booking_Participant_Email,
            Parent_Booking_Relation_Type: booking.Parent_Booking_Relation_Type,
            BookingSeriesId: booking.Booking_Series_Id,
            locationZone: '',
            approvalGroups: '',
        };
    }

    public static fromMyBookingsForOthersResponse(booking: GetMyV2BookingsForOthersEndpoint.Booking, bookingOwnerEmail: string): BookingView
    {
        return {
            id: booking.Booking_Id,
            Building_Name: getBuildingNameUsingFloorNodeId(booking.Node_Id),
            Node_Id: booking.Node_Id,
            Space_Id: booking.Space_Id,
            Space_Name: booking.Space_Name,
            Booking_Id: booking.Booking_Id,
            Booking_Name: booking.Booking_Name,
            Booking_Start: booking.Booking_Start.toISO(),
            Booking_Early_Checkin: booking.Booking_Early_Checkin.toISO(),
            Booking_End: booking.Booking_End.toISO(),
            Booking_Status: booking.Booking_Status,
            Booking_IsActive: 1,
            Booking_IsApproved: 4,
            Booking_IsCheckedIn: booking.Booking_IsCheckedIn,
            Booking_Owner_Email: bookingOwnerEmail,
            Booking_Owner_Name: booking.Booking_Owner_Name,
            _CreatedAt: booking._CreatedAt.toISO(),
            _CreatedBy: booking._CreatedBy,
            Parent_Booking_Relation_Type: booking.Parent_Booking_Relation_Type,
            BookingSeriesId: booking.Booking_Series_Id,
            locationZone: '',
            approvalGroups: '',
        };
    }

    public static fromBookingsResponse(booking: Booking): BookingView
    {
        return {
            id: booking.Booking_Id,
            Building_Name: getBuildingNameUsingFloorNodeId(booking.Node_Id),
            Node_Id: booking.Node_Id,
            Space_Id: booking.Space_Id,
            Space_Name: booking.Space_Name,
            Booking_Id: booking.Booking_Id,
            Booking_Name: booking.Booking_Name,
            Booking_Start: booking.Booking_Start.toISO(),
            Booking_Early_Checkin: DateHelper.fromIsoByNode(booking.Booking_Early_Checkin as unknown as string, booking.Node_Id).toISO(),
            Booking_End: booking.Booking_End.toISO(),
            Booking_Status: booking.Booking_Status,
            Booking_IsActive: booking.Booking_IsActive,
            Booking_IsCancelled: booking.Booking_IsCancelled,
            Booking_CompletedTime: DateHelper.fromIsoByNode(booking.Booking_Completed_Time as unknown as string, booking.Node_Id),
            Booking_IsApproved: booking.Booking_IsApproved,
            Booking_IsCheckedIn: booking.Booking_IsCheckedIn,
            Booking_Owner_Email: booking.Booking_Owner_Email,
            Booking_Owner_Name: booking.Booking_Owner_Name,
            _CreatedAt: DateHelper.fromIsoByNode(booking._CreatedAt as unknown as string, booking.Node_Id).toISO(),
            _CreatedBy: booking._CreatedBy,
            Parent_Booking_Relation_Type: booking.Parent_Booking_Relation_Type,
            BookingSeriesId: booking.Booking_Series_Id,
            locationZone: booking.Meta_Loc_Zone,
            approvalGroups: booking.Approval_Groups,
        };
    }
}
